import CustomButton from "components/Common/CustomButton/CustomButton";
import InputFields from "components/Common/Inputfileds/InputFields";
import Loader from "components/Common/Loader/Loader";
import TopBar from "components/Common/TopBar/TopBar";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaPhone, FaRegUser } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  signUpRequest,
  signUpReset,
  clearSignUpFailRequest,
  updateLoader,
} from "redux/reducers/duck/authDuck";
import styles from "style/signUp.module.css";
import { toast, useToast } from "react-toastify";
import PatientApi from "api/PatientApi";
import { getPhoneNumber } from "utils/utils";
import { updatePatientID } from "redux/reducers/duck/patientDuck";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { patientID } = useSelector(({ patient }) => ({
    patientID: patient?.patientID
  }))

  const { loading, signUpData, signUpFailData, signUpRecordNotFoundData } =
    useSelector(({ auth }) => ({
      loading: auth?.loading,
      signUpData: auth?.signUpData,
      signUpFailData: auth?.signUpFailData,
      signUpRecordNotFoundData: auth?.signUpRecordNotFoundData,
    }));

  const { handleSubmit, control, formState, setValue, getValues,trigger } = useForm({
    mode: "onChange", // Trigger validation on change
  });
  const { errors } = formState;

  useEffect(() => {
    getRecordIfId()
    
  }, [])
  const getRecordIfId = async () => {
    if(patientID) {
      try {
        dispatch(updateLoader(true));
        const response = await PatientApi.getEmployeebyphone({ phone_number: patientID, searchBy: "id", });
        dispatch(updateLoader(false));
        setValue("dob", response.data[0].dob);
        setValue("firstName", response.data[0].firstName);
        setValue("lastName", response.data[0].lastName);
        setValue("email", response.data[0].email);
        setValue("phone", getPhoneNumber(response.data[0]));
      } catch(error) {
        dispatch(updateLoader(false));
        console.log("🚀 ~ getRecordIfId ~ error:", error)
        
      }
    }
  }

  const onSubmit = async (data) => {
    try {
      await dispatch(signUpRequest(data));
    } catch (error) {
      console.error("Error creating patient:", error);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(signUpReset()); // Replace resetSignUpData with the appropriate action to reset signUpData
    };
  }, [dispatch]);

  useEffect(() => {
    if (signUpData) {
      navigate("/login");
      if(patientID) {
        dispatch(updatePatientID(null))
      }
      toast.success("Your account has been created successfully.");
    } else if (signUpRecordNotFoundData) {
      navigate("/signup-status");
      dispatch(clearSignUpFailRequest());
    } else {
      console.log("nothing actions");
    }
  }, [signUpData, signUpFailData, signUpRecordNotFoundData, navigate]);

  console.log("not found page =>", signUpRecordNotFoundData);

  return (
    <>
      <TopBar IconItemName="New Account Info" LoginBtn="Login" />
      {loading && <Loader />}
      <div className={styles.mainBodyBg}>
        <div className={styles.mainWrapper}>
          <div className={styles.createAccountWrapper}>
            <div className={`${styles.LoginWrapper}`}>
              <div className="row flex-column-reverse flex-sm-row">
                <div className="col-lg-12 mb-3 mb-sm-0">
                  <CustomButton
                    variant="darkGray"
                    additionalClass={`ms-auto mt-0 ${styles.backButton}`}
                    handleClick={() => navigate("/welcome")}
                    label="Back"
                  />
                </div>
                <div className="col-lg-12">
                  <div className={`${styles.pageTitle}`}>
                    <span>Create Your Account</span>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-8">
                    <div className={`${styles.formFields}`}>
                      {/* First Line */}
                      <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-6 mb-3">
                          <Controller
                            name="firstName" // Set the name attribute to match the key in your data object
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "First Name is required",
                              maxLength: {
                                value: 100,
                                message:
                                  "First Name cannot exceed 100 characters",
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <InputFields
                                  type="text"
                                  CustomIcon={<FaRegUser />}
                                  inputStyle={styles.inputLogin}
                                  labelStyle={styles.labelFileds}
                                  value={field.value}
                                  label="First Name"
                                  index="firstName"
                                  maxLength={100}
                                  placeholder="First Name"
                                  handleChange={(e) => {
                                    field.onChange(e);
                                  }}
                                  required
                                  roundedSm
                                />
                                {errors.firstName && (
                                  <div
                                    className={`${styles.validationmessage} validation-message`}
                                  >
                                    {errors.firstName.message}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-6 mb-3">
                          <Controller
                            name="lastName" // Set the name attribute to match the key in your data object
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Last Name is required",
                              maxLength: {
                                value: 100,
                                message:
                                  "Last Name cannot exceed 100 characters",
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <InputFields
                                  type="text"
                                  CustomIcon={<FaRegUser />}
                                  inputStyle={styles.inputLogin}
                                  labelStyle={styles.labelFileds}
                                  value={field.value}
                                  label="Last Name"
                                  index="lastName"
                                  maxLength={100}
                                  placeholder="Last Name"
                                  handleChange={(e) => {
                                    field.onChange(e);
                                  }}
                                  required
                                  roundedSm
                                />
                                {errors.lastName && (
                                  <div
                                    className={`${styles.validationmessage} validation-message`}
                                  >
                                    {errors.lastName.message}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-6 mb-3  patientDatePicker">
                          <Controller
                            name="dob"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Date of Birth is required",
                            }}
                            render={({ field }) => (
                              <>
                                <InputFields
                                  label="Birthday"
                                  type="datePicker"
                                  BgColorDtae="bg-white"
                                  placeholder="Date of Birth"
                                  roundedSm
                                  shadowSm
                                  cssClass
                                  value={field.value}
                                  index="dob"
                                  handleChange={(e) => {
                                    const formattedDate = moment(e).isValid()
                                      ? moment(e).format("YYYY-MM-DD")
                                      : "";
                                    setValue("dob", formattedDate);
                                    field.onChange(formattedDate);
                                  }}
                                  minDate={new Date("1900-01-01")}
                                  maxDate={new Date()}
                                  required
                                />

                                {errors.dob && (
                                  <div
                                    className={`${styles.validationmessage} validation-message`}
                                  >
                                    {errors.dob.message}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-6 mb-3 patient-phone-account patient-phone-input">
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Phone Number is required",
                              pattern: {
                                value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                                message:
                                  "Please enter a valid phone number (+x xxx xxx xxxx).",
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <InputFields
                                  label="Phone"
                                  CustomIcon={<FaPhone />}
                                  type="phone"
                                  placeholder="+1 625 999 3488"
                                  value={field.value}
                                  index="phone"
                                  maxLength={65}
                                  handleChange={(e) => {
                                    setValue("phone", e);
                                    field.onChange(e);
                                  }}
                                  required
                                  roundedSm
                                  shadowSm
                                />
                                {errors.phone && (
                                  <div
                                    className={`${styles.validationmessage} validation-message`}
                                  >
                                    {errors.phone.message}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-6 mb-3">
                          <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Email is required",
                              pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Please enter a valid email address.",
                              },
                            }}
                            render={({ field }) => (
                              <>
                               {/* <input type="text" style={{ display: 'none' }} /> Dummy field */}
                                <InputFields
                                  label="Email"
                                  CustomIcon={<HiOutlineMail />}
                                  type="email"
                                  roundedSm
                                  placeholder="m.carmen1952@gmail.com"
                                  value={field.value}
                                  index="email"
                                  autocomplete="new-email"
                                  maxLength={25}
                                  handleChange={(e) => {
                                    setValue("email", e);
                                    field.onChange(e);
                                  }}
                                  required
                                  shadowSm
                                />
                                {errors.email && (
                                  <div
                                    className={`${styles.validationmessage} validation-message`}
                                  >
                                    {errors.email.message}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-xl-4">
                    <div className={`${styles.formFieldsRight} border-end-0`}>
                      <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12 mb-3">
                          <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long.",
                              },
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!=+@#$%^&*[\]()~';`_\-,.?":{}|<>])[A-Za-z\d!=+@#$%^&*(),.?":{}|<>]/,
                                message:
                                  "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <InputFields
                                  label="Password"
                                  type="password"
                                  placeholder="***********"
                                  value={field.value}
                                  index="password"
                                  maxLength={100}
                                  autocomplete="new-password"
                                  handleChange={(e) => {
                                    setValue("password", e);
                                    field.onChange(e);
                                    trigger("confirmPassword");
                                  }}
                                  required
                                  roundedSm
                                  password
                                  shadowSm
                                />
                                {errors.password && (
                                  <div
                                    className={`${styles.validationmessage} validation-message`}
                                  >
                                    {errors.password.message}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12 mb-3">
                          <Controller
                            name="confirmPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Confirm password is required",
                              validate: {
                                matchesNewPassword: (value) =>
                                  value === getValues("password") ||
                                  "Passwords do not match",
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <InputFields
                                  label="Confirm Password"
                                  type="password"
                                  placeholder="Confirm Password"
                                  value={field.value}
                                  handleChange={(e) => {
                                    field.onChange(e);
                                  }}
                                  required
                                  roundedSm
                                  password
                                  shadowSm
                                />
                                {errors.confirmPassword && (
                                  <div
                                    className={`${styles.validationmessage} validation-message`}
                                  >
                                    {errors.confirmPassword.message}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="col-md-12 mt-4">
                          <CustomButton
                            label="Create Account"
                            additionalClass={`${styles.customBtn} text-uppercase`}
                            disabled={!formState.isValid}
                            type="submit"
                            variant="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
