import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Initial State
export const INITIAL_STATE = {
  tests: [],
  loading: false,
  error: null,
  filteredTests: [],
  testResults: {},
  selectedTest: {},
};

export const getTestsSlice = createSlice({
  name: "getTests",
  initialState: INITIAL_STATE,
  reducers: {
    // tests request reducers
    testsListingRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
    },
    // tests response reducers
    testsListingSuccess(state, { payload }) {
      state.tests = payload;
      state.loading = false;
      state.filteredTests = payload.data.rows;
    },

    testsListingFailure(state, { payload }) {
      state.error = payload;
      state.loading = false;
      toast.error(payload);
    },
    // testResults request reducers
    testResultsRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
    },
    // Employee response reducers
    testResultsSuccess(state, { payload }) {
      // state.testResults = payload;
      state.loading = false;
      state.selectedTest = payload.data.Item;
    },

    testResultsFailure(state, { payload }) {
      state.error = payload;
      state.loading = false;
      toast.error(payload);
    },
  },
});

export const {
  testsListingRequest,
  testsListingSuccess,
  testsListingFailure,
  testResultsRequest,
  testResultsSuccess,
  testResultsFailure,
} = getTestsSlice.actions;

export default getTestsSlice.reducer;
