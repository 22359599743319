import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import logo from "assets/images/3eaf991b-b876-4897-8e39-47aef2247dcd.png";
import phoneIcon from "assets/images/phone-svgrepo-com.png";
import envelopeIcon from "assets/images/envelope-mail-mobile-ui-svgrepo-com.png";
import globeIcon from "assets/images/globe-alt-svgrepo-com.png";
import addressIcon from "assets/images/marker-pin-01-svgrepo-com.png";
import { formatPhone, getDemoGraphics, getAge, formatDateOfBirthDOB, formatOrderDate } from "utils/utils";
import moment from "moment";
import { formatTestForLabReport, formatTestNewDiscovery, customIsJsonString } from "utils/utils";
import { TYPE_OF_TEST } from "utils/helper/Constants";
// import { customIsJsonString } from "util/customLodash";

const LabReportSisterPirerrePdf = ({ test, testsLab, site }) => {
  const { resultDetails, test_type, employee_demographics, testType, lab } = test;

  const { clia_number } = lab;

  let demos = getDemoGraphics(test);

  console.log("🚀 ~ LabReportSisterPirerrePdf ~ resultDetails:", resultDetails)
  const sampleDetail = formatTestForLabReport(resultDetails || []);
  console.log("🚀 ~ LabReportSisterPirerrePdf ~ sampleDetail:", sampleDetail)

  const parseSrNoObjectRecviedDate = (test) => {
    try {
      const arr = JSON.parse(test.sr_no);
      let dateOfReceive = arr[0]?.date;
      return dateOfReceive;
    } catch (error) {
      return moment(test.createdAt).toISOString();
    }
  };

  const newDiscoveryPathogen = formatTestNewDiscovery({
    results: resultDetails,
    type: testType?.type,
    code: testType?.code,
  });

  const showTestResult = (testResult) => {
    if (customIsJsonString(testResult)) {
      return JSON.parse(testResult).value;
    }
    return testResult;
  };

  return (
    <Document title={`${demos.firstName} ${demos.lastName}`}>
      <Page size="A4" style={styles.body}>
        <View style={[styles.header]}>
          <View style={styles.headerTop}>
            <Image src={logo} style={styles.logo} />
            <View style={styles.contactInfo}>
              <View style={[styles.flexVerticalenter, { marginBottom: 2 }]}>
                <Image src={phoneIcon} style={styles.contactInfoIcons} />
                <Text>{formatPhone(test?.lab?.phone_number)}</Text>
              </View>
              <View style={[styles.flexVerticalenter, { marginBottom: 2 }]}>
                <Image src={envelopeIcon} style={styles.contactInfoIcons} />
                <Text>{test?.lab?.contact_email}</Text>
              </View>
              <View style={[styles.flexVerticalenter]}>
                <Image src={globeIcon} style={styles.contactInfoIcons} />
                <Text>www.newdiscoverylabs.com</Text>
              </View>
            </View>
            <View style={[styles.formTitle, { fontFamily: "Helvetica-Bold" }]}>
              <Text>LABORATORY REPORT</Text>
            </View>
          </View>
          <View style={styles.headerBottom}>
            <View style={styles.address}>
              <Image src={addressIcon} style={styles.contactInfoIcons} />
              <Text>{`${test?.lab?.street || ""}, ${test?.lab?.city_state_zip || ""}`}</Text>
            </View>
            <View style={[styles.flex, styles.itemsCenter, styles.flex1]}>
              <View style={styles.licInfo}>
                <Text style={styles.mx8}>|</Text>
                <Text>Laboratory Director: {test?.site?.setting?.lab_director || test?.lab?.lab_director || "-"}</Text>
              </View>
              <View style={styles.licInfo}>
                <Text>
                  {" "}
                  <Text style={styles.mx8}>|</Text> CLIA ID: {clia_number || ""}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.px5}>
          <View style={{ ...styles.headingBg, ...styles.mb15 }}>
            <Text style={styles.cardContent}>PATIENT INFORMATION</Text>
          </View>
          <View style={[styles.flex, { alignItems: "stretch" }]}>
            <View style={[styles.flex1, styles.mr10]}>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>patient name:</Text>
                <View style={styles.inputField}>
                  <Text>{`${demos.firstName} ${demos.lastName}`}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>dob:</Text>
                <View style={styles.inputField}>
                  <Text>{formatDateOfBirthDOB(demos.dob)}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>gender:</Text>
                <View style={styles.inputField}>
                  <Text>{demos?.sex}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>provider:</Text>
                <View style={styles.inputField}>
                  <Text>{test?.order?.provider?.ref_name || ""}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>provider NPi:</Text>
                <View style={styles.inputField}>
                  <Text>{test?.order?.provider?.ref_npi || ""}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>account:</Text>
                <View style={styles.inputField}>
                  <Text>{test?.site?.name || ""}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup, , styles.borderBottomBlack]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>status:</Text>
                <View style={styles.inputField}>
                  <Text>&nbsp;</Text>
                </View>
              </View>
            </View>
            <View style={[styles.w50]}>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>Coder code:</Text>
                <View style={styles.inputField}>
                  <Text>&nbsp;</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>Accession:</Text>
                <View style={styles.inputField}>
                  <Text>{test?.barcode}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>date collected:</Text>
                <View style={styles.inputField}>
                  <Text>{formatOrderDate(test.createdAt)}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>date recieved:</Text>
                <View style={styles.inputField}>
                  <Text>{formatOrderDate(parseSrNoObjectRecviedDate(test))}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>date reported:</Text>
                <View style={styles.inputField}>
                  <Text>{formatOrderDate(test?.resultDateTime)}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup]}>
                <Text style={[styles.textUppercase, styles.inputLabel]}>Sample type:</Text>
                <View style={styles.inputField}>
                  <Text>{demos?.swabtype}</Text>
                </View>
              </View>
              <View style={[styles.inputGroup, styles.borderBottomBlack]}>
                {/* <Text style={[styles.textUppercase, styles.inputLabel]}></Text> */}
                <View style={styles.inputField}>
                  <Text></Text>
                </View>
              </View>
            </View>
          </View>

          {newDiscoveryPathogen && (
            <View style={[styles.section, styles.formWrapper, { marginTop: 15 }]}>
              <View style={{ ...styles.headingBg }}>
                <Text style={styles.cardContent}>MOLECULAR TEST RESULTS</Text>
              </View>

              <View>
                <View style={[styles.flex, styles.borderBottomBlack, , styles.borderTop1]}>
                  <View style={[styles.flex1]}>
                    <View style={[styles.inputGroup, styles.borderNone, styles.borderRight1, styles.borderBottomBlack]}>
                      <Text style={[styles.textUppercase, styles.inputLabel, styles.bgTransparent]}>Test name:</Text>
                      <View style={styles.inputField}>
                        <Text style={{ fontSize: 8 }}>{newDiscoveryPathogen.testName}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.w50]}>
                    <View style={[styles.inputGroup, styles.borderNone, styles.borderBottomBlack]}>
                      <Text style={[styles.textUppercase, styles.inputLabel, styles.bgTransparent]}>Code:</Text>
                      <View style={styles.inputField}>
                        <Text style={{ fontSize: 8 }}>{newDiscoveryPathogen?.code || ""}</Text>
                      </View>
                    </View>
                  </View>
                </View>

                {newDiscoveryPathogen.pathogenisTests.length > 0 && (
                  <>
                    <View style={[styles.flex, styles.headingBgSubtest, styles.borderBottom]}>
                      <View style={[styles.flex1]}>
                        <View>
                          <Text style={[styles.textUppercase]}>PATHOGENS TESTED</Text>
                        </View>
                      </View>

                      <View style={[styles.w50]}>
                        <View>
                          <Text style={[styles.textUppercase]}>QUALITATIVE RESULTS</Text>
                        </View>
                      </View>
                    </View>
                    {newDiscoveryPathogen.pathogenisTests.map((item, index) => (
                      <View style={[styles.flex]}>
                        <View style={[styles.flex1]}>
                          <View style={styles.testField}>
                            <Text style={[styles.textUppercase]}>{item.name}</Text>
                          </View>
                        </View>

                        <View style={[styles.w50]}>
                          <View style={styles.testField}>
                            <Text style={[styles.textUppercase]}>{item.parsedResult}</Text>
                          </View>
                        </View>
                      </View>
                    ))}
                  </>
                )}

                {newDiscoveryPathogen.antibioticTests.length > 0 && (
                  <>
                    <View style={[styles.flex, styles.headingBgSubtest, styles.borderBottom, styles.borderTop]}>
                      <View style={[styles.flex1]}>
                        <View>
                          <Text style={[styles.textUppercase]}>ANTIBIOTIC-RESISTANT GENES</Text>
                        </View>
                      </View>

                      <View style={[styles.w50]}>
                        <View>
                          <Text style={[styles.textUppercase]}>QUALITATIVE RESULTS</Text>
                        </View>
                      </View>
                    </View>

                    {newDiscoveryPathogen.antibioticTests.map((item, index) => (
                      <View style={[styles.flex]}>
                        <View style={[styles.flex1]}>
                          <View style={styles.testField}>
                            <Text style={[styles.textUppercase]}>{item.name}</Text>
                          </View>
                        </View>

                        <View style={[styles.w50]}>
                          <View style={styles.testField}>
                            <Text style={[styles.textUppercase]}>{item.parsedResult}</Text>
                          </View>
                        </View>
                      </View>
                    ))}
                  </>
                )}
              </View>
            </View>
          )}

          {!newDiscoveryPathogen && (
            <>
              <View style={[styles.section, styles.formWrapper, { marginTop: 15 }]}>
                <View style={{ ...styles.headingBg }}>
                  <Text style={styles.cardContent}>TEST RESULTS</Text>
                </View>

                {sampleDetail &&
                  test?.testType?.type === "panel" &&
                  sampleDetail.map((item) => {
                    return (
                      <View>
                        <View style={[styles.flex, styles.borderBottomBlack, , styles.borderTop1]}>
                          <View style={[styles.flex1]}>
                            <View
                              style={[
                                styles.inputGroup,
                                styles.borderNone,
                                styles.borderRight1,
                                styles.borderBottomBlack,
                              ]}
                            >
                              <Text style={[styles.textUppercase, styles.inputLabel, styles.bgTransparent]}>
                                Test name:
                              </Text>
                              <View style={styles.inputField}>
                                <Text style={{ fontSize: 8 }}>{item.testName}</Text>
                              </View>
                            </View>
                          </View>
                          <View style={[styles.w50]}>
                            <View style={[styles.inputGroup, styles.borderNone, styles.borderBottomBlack]}>
                              <Text style={[styles.textUppercase, styles.inputLabel, styles.bgTransparent]}>Code:</Text>
                              <View style={styles.inputField}>
                                <Text style={{ fontSize: 8 }}>&nbsp;</Text>
                              </View>
                            </View>
                          </View>
                        </View>

                        <View style={[styles.flex]}>
                          <View style={[styles.flex1, styles.bgDarkGray]}>
                            <View style={[styles.borderBottomBlack]}>
                              <View
                                style={[
                                  styles.inputGroup,
                                  styles.borderNone,
                                  styles.borderRight1,
                                  styles.borderBottomBlack,
                                ]}
                              >
                                <Text
                                  style={[
                                    styles.textUppercase,
                                    styles.inputLabel,
                                    styles.bgTransparent,
                                    { fontSize: 10, fontFamily: "Helvetica-Bold" },
                                  ]}
                                >
                                  TARGET/ANALYTE
                                </Text>
                              </View>
                            </View>
                            <View style={[styles.formWrapper, styles.px5, styles.py5]}>
                              {item.targetRange.map((trItem) => {
                                return <Text style={{ fontSize: 8 }}>{trItem?.name || ""}</Text>;
                              })}
                            </View>
                          </View>
                          <View style={[styles.w50, styles.bgDarkGray]}>
                            <View style={[styles.borderBottomBlack]}>
                              <View style={[styles.inputGroup, styles.borderNone, styles.borderBottomBlack]}>
                                <Text
                                  style={[
                                    styles.textUppercase,
                                    styles.inputLabel,
                                    styles.bgTransparent,
                                    { fontSize: 10, fontFamily: "Helvetica-Bold" },
                                  ]}
                                >
                                  RESULTS
                                </Text>
                              </View>
                            </View>
                            <View style={[styles.formWrapper, styles.px5, styles.py5]}>
                              {/* <Text style={{ fontSize: 8 }}>{}</Text>
                               */}
                              {item.targetRange.map((trItem) => {
                                return <Text style={{ fontSize: 8 }}>{showTestResult(trItem.testResult)}</Text>;
                              })}
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}

                {(resultDetails && (JSON.parse(resultDetails) || []).length > 0) &&
                  test?.testType?.type !== "panel" &&
                  (JSON.parse(resultDetails) || [])?.map((item) => {
                    return (
                      <View>
                        <View style={[styles.flex, styles.borderBottomBlack, , styles.borderTop1]}>
                          <View style={[styles.flex1]}>
                            <View
                              style={[
                                styles.inputGroup,
                                styles.borderNone,
                                styles.borderRight1,
                                styles.borderBottomBlack,
                              ]}
                            >
                              <Text style={[styles.textUppercase, styles.inputLabel, styles.bgTransparent]}>
                                Test name:
                              </Text>
                              <View style={styles.inputField}>
                                <Text style={{ fontSize: 8 }}>{item.name}</Text>
                              </View>
                            </View>
                          </View>
                          <View style={[styles.w50]}>
                            <View style={[styles.inputGroup, styles.borderNone, styles.borderBottomBlack]}>
                              <Text style={[styles.textUppercase, styles.inputLabel, styles.bgTransparent]}>Code:</Text>
                              <View style={styles.inputField}>
                                <Text style={{ fontSize: 8 }}>&nbsp;</Text>
                              </View>
                            </View>
                          </View>
                        </View>

                        <View style={[styles.flex]}>
                          <View style={[styles.flex1, styles.bgDarkGray]}>
                            <View style={[styles.borderBottomBlack]}>
                              <View
                                style={[
                                  styles.inputGroup,
                                  styles.borderNone,
                                  styles.borderRight1,
                                  styles.borderBottomBlack,
                                ]}
                              >
                                <Text
                                  style={[
                                    styles.textUppercase,
                                    styles.inputLabel,
                                    styles.bgTransparent,
                                    { fontSize: 10, fontFamily: "Helvetica-Bold" },
                                  ]}
                                >
                                  TARGET/ANALYTE
                                </Text>
                              </View>
                            </View>
                            <View style={[styles.formWrapper, styles.px5, styles.py5]}>
                              <Text style={{ fontSize: 8 }}>{item?.name || ""}</Text>
                            </View>
                          </View>
                          <View style={[styles.w50, styles.bgDarkGray]}>
                            <View style={[styles.borderBottomBlack]}>
                              <View style={[styles.inputGroup, styles.borderNone, styles.borderBottomBlack]}>
                                <Text
                                  style={[
                                    styles.textUppercase,
                                    styles.inputLabel,
                                    styles.bgTransparent,
                                    { fontSize: 10, fontFamily: "Helvetica-Bold" },
                                  ]}
                                >
                                  RESULTS
                                </Text>
                              </View>
                            </View>
                            <View style={[styles.formWrapper, styles.px5, styles.py5]}>
                              <Text style={{ fontSize: 8 }}>{showTestResult(item.testResult)}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}

                {!resultDetails && (
                  <View>
                    <View style={[styles.flex, styles.borderBottomBlack, , styles.borderTop1]}>
                      <View style={[styles.flex1]}>
                        <View
                          style={[styles.inputGroup, styles.borderNone, styles.borderRight1, styles.borderBottomBlack]}
                        >
                          <Text style={[styles.textUppercase, styles.inputLabel, styles.bgTransparent]}>
                            Test name:
                          </Text>
                          <View style={styles.inputField}>
                            <Text style={{ fontSize: 8 }}>{test?.testType?.name || ""}</Text>
                          </View>
                        </View>
                      </View>
                      <View style={[styles.w50]}>
                        <View style={[styles.inputGroup, styles.borderNone, styles.borderBottomBlack]}>
                          <Text style={[styles.textUppercase, styles.inputLabel, styles.bgTransparent]}>Code:</Text>
                          <View style={styles.inputField}>
                            <Text style={{ fontSize: 8 }}>&nbsp;</Text>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={[styles.flex]}>
                      <View style={[styles.flex1, styles.bgDarkGray]}>
                        <View style={[styles.borderBottomBlack]}>
                          <View
                            style={[
                              styles.inputGroup,
                              styles.borderNone,
                              styles.borderRight1,
                              styles.borderBottomBlack,
                            ]}
                          >
                            <Text
                              style={[
                                styles.textUppercase,
                                styles.inputLabel,
                                styles.bgTransparent,
                                { fontSize: 10, fontFamily: "Helvetica-Bold" },
                              ]}
                            >
                              TARGET/ANALYTE
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.formWrapper, styles.px5, styles.py5]}>
                          {<Text style={{ fontSize: 8 }}>{test?.test_type || ""}</Text>}
                        </View>
                      </View>
                      <View style={[styles.w50, styles.bgDarkGray]}>
                        <View style={[styles.borderBottomBlack]}>
                          <View style={[styles.inputGroup, styles.borderNone, styles.borderBottomBlack]}>
                            <Text
                              style={[
                                styles.textUppercase,
                                styles.inputLabel,
                                styles.bgTransparent,
                                { fontSize: 10, fontFamily: "Helvetica-Bold" },
                              ]}
                            >
                              RESULTS
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.formWrapper, styles.px5, styles.py5]}>
                          {/* <Text style={{ fontSize: 8 }}>{}</Text>
                           */}
                          <Text style={{ fontSize: 8 }}>{test?.result || ""}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </View>
            </>
          )}

          <View>
            <View style={[styles.flex, styles.headingBgSubtest, styles.commentSection]}>
              <View style={[styles.flex1]}>
                <View>
                  <Text style={[styles.textUppercase]}>Comments</Text>
                </View>
              </View>
            </View>

            <View style={[styles.flex, styles.minHeight100]}>
              <View style={[styles.flex1]}>
                <View style={styles.testField}>
                  <Text style={[styles.textUppercase]}> {employee_demographics?.comments || ""}</Text>
                </View>
              </View>
            </View>
          </View>

          {newDiscoveryPathogen && (
            <View style={{ flexDirection: "column", marginTop: 10, marginBottom: 40 }}>
              <Text>Test results must be evaluated with clinical symptoms to diagnose disease.</Text>
              <Text>
                Methodology statement: Real-time PCR assays are designed to detect pathogens with clinical significance
                and analytical sensitivity and specificity greater than 99%
              </Text>
              <Text>
                Limitations: While these assays are very sensitive and specific, theoretically these assays could detect
                pathogens not listed, resulting in a false positive. In addition, while these assays are very specific,
                there may be target pathogen sequences with unknown sequence variability which may not be detected,
                resulting in a false negative result.
              </Text>
              <Text style={{ fontSize: 8, marginTop: "10px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 10 }}>Disclaimer:</Text>
                Laboratory Developed Test (LTD). This test was developed and its performance characteristics determined
                by New Discovery Laboratories. It has not been cleared or approved by the U.S. Food and Drug
                Administration. The FDA has determined that such clearance or approval is not necessary. This test is
                used for clinical purposes. It should not be regarded as investigational or for research. This
                laboratory is certified under the Clinical Laboratory Improvement Amendments of 1988 (CLIA-88) as
                qualified to perform high complexity clinical laboratory testing.{" "}
              </Text>
            </View>
          )}

          {!newDiscoveryPathogen && (
            <View style={{ flexDirection: "column", marginTop: 15, marginBottom: 40 }}>
              <Text style={{ fontSize: 8 }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>Disclaimer:</Text>
                Test results must be evaluated with clinical symptoms to diagnose disease
              </Text>
              <Text style={{ fontSize: 8 }}>
                All assays are FDA approved and/or cleared, unless specified otherwise.
              </Text>
            </View>
          )}

          <View style={styles.flex}>
            <View style={[styles.flex1, styles.mr10]}>
              <View
                style={[
                  styles.bgDarkGray,
                  styles.borderBottomBlack,
                  { paddingHorizontal: "5px", paddingVertical: "10px" },
                ]}
              >
                <Text style={{ fontSize: 8 }}>{`${demos?.firstName} ${demos?.lastName}`}</Text>
              </View>
              <Text style={[styles.textUppercase, { fontSize: 8, paddingTop: 3 }]}>patient name:</Text>
            </View>
            <View style={[styles.w50]}>
              <View
                style={[
                  styles.bgDarkGray,
                  styles.borderBottomBlack,
                  { paddingHorizontal: "5px", paddingVertical: "10px" },
                ]}
              >
                <Text style={{ fontSize: 8 }}>{test?.barcode}</Text>
              </View>
              <Text style={[styles.textUppercase, { fontSize: 8, paddingTop: 3 }]}>accession:</Text>
            </View>
          </View>

          <View style={{ paddingTop: 5 }}>
            <View style={[styles.flex, styles.justifyBetween]}>
              <Text style={{ fontSize: 6, color: "#141958" }}>© New Discovery Laboratories</Text>
              <Text style={{ fontSize: 6, color: "#141958" }}>VERSION 1.0.3</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    // padding: 10,
    margin: 0,
    padding: 0,
    fontSize: 10,
    lineHeight: 1.3,
  },

  // reusable style
  borderNone: {
    border: 0,
  },
  borderBottomBlack: {
    borderBottom: 1,
    borderBottomColor: "#333",
    borderBottomStyle: "solid",
  },

  borderRight1: {
    borderRight: 1,
    borderRightColor: "#333",
    borderRightStyle: "solid",
  },
  borderTop1: {
    borderTop: 1,
    borderTopColor: "#333",
    borderTopStyle: "solid",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  flexVerticalenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  itemsStart: {
    alignItems: "flex-start",
  },
  itemsCenter: {
    alignItems: "center",
  },
  itemsEnd: {
    alignItems: "flex-end",
  },
  justifyStart: {
    justifyContent: "flex-start",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  flex1: {
    flex: 1,
  },
  flex40: {
    flex: "0 0 40%",
  },
  px5: {
    paddingHorizontal: 5,
  },
  py5: {
    paddingVertical: 5,
  },
  w50: {
    width: "50%",
  },
  w30: {
    width: "30%",
  },
  w40: {
    width: "40%",
  },
  w20: {
    width: "20%",
  },
  w80: {
    width: "80%",
  },
  w100: {
    width: "100%",
  },
  mb3: {
    marginBottom: 3,
  },
  mb4: {
    marginBottom: 4,
  },
  mb10: {
    marginBottom: 10,
  },
  mb15: {
    marginBottom: 15,
  },
  mb44: {
    marginBottom: 34,
  },
  mb50: {
    marginBottom: 50,
  },
  pt8: {
    paddingTop: 8,
  },
  mr5: {
    marginRight: 5,
  },
  mr10: {
    marginRight: 10,
  },
  p5: {
    padding: 5,
  },
  mx8: {
    marginHorizontal: 8,
  },
  text10: {
    fontSize: 10,
  },
  text12: {
    fontSize: 12,
  },
  textPrimary: {
    color: "#02247b",
  },
  bgWhite: {
    backgroundColor: "#fff",
  },
  bgBlue600: {
    backgroundColor: "#e6f9ff",
  },
  bgTransparent: {
    backgroundColor: "transparent",
  },

  textUppercase: {
    textTransform: "uppercase",
  },
  bgDarkGray: {
    backgroundColor: "#f1f3ff",
  },
  // other styles
  header: {
    padding: 10,
    textAlign: "center",
  },
  headerTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: 160,
  },
  contactInfo: {
    textAlign: "left",
    fontSize: 10,
    color: "#02247b",
    lineHeight: 1,
    marginBottom: 0,
  },
  contactInfoIcons: {
    width: 12,
    objectFit: "contain",
    marginRight: 5,
    marginBottom: 0,
  },
  formTitle: {
    margin: 0,
    color: "#141958",
    fontSize: 18,
    fontWeight: "bold",
  },
  headerBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingTop: 10,
  },
  address: {
    width: 245,
    fontSize: 10,
    fontWeight: "700",
    color: "#141958",
    letterSpacing: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: 8,
  },
  licInfo: {
    fontSize: 10,
    color: "#141958",
    fontWeight: "700",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  formWrapper: {
    backgroundColor: "#e6f8fd",
  },

  section: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#333",
  },

  headingBg: {
    backgroundColor: "#02247b",
    paddingVertical: 4,
    paddingHorizontal: 10,
    color: "#fff",
    width: "100%",
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  headingBgSubtest: {
    backgroundColor: "#f1f3ff",
    paddingVertical: 4,
    paddingHorizontal: 10,
    color: "#000000",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },

  minHeight100: {
    minHeight: "100px",
    backgroundColor: "#e6f8fd",
    border: "1px solid black",
  },

  commentSection: {
    borderLeft: "1px solid Black",
    borderRight: "1px solid Black",
  },

  borderBottom: {
    borderBottom: "1px solid black",
  },
  borderTop: {
    borderTop: "1px solid black",
  },

  cardContent: {
    fontSize: 10,
    fontWeight: "700",
    textAlign: "center",
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    borderStyle: "solid",
    borderColor: "#333",
    borderWidth: 1,
    boxSizing: "border-box",
    borderBottom: 0,
  },
  inputLabel: {
    fontSize: 8,
    padding: "6px 5px",
    color: "#141958",
    fontWeight: "bold",
    backgroundColor: "#fff",
    width: "auto",
  },

  inputField: {
    width: "100%",
    fontSize: 8,
    padding: "6px 5px",
    backgroundColor: "transparent",
    overflow: "hidden",
    flexGrow: 1,
  },

  testField: {
    width: "100%",
    fontSize: 8,
    padding: "3px 5px",
    backgroundColor: "transparent",
    overflow: "hidden",
    flexGrow: 1,
  },

  labelSpan: {
    fontSize: 6,
    fontWeight: "normal",
  },
});

export default LabReportSisterPirerrePdf;
