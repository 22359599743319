import { IMAGES } from "../../../utils/helper/Images";
import Autocomplete from "react-google-autocomplete";
import styles from "style/GooglePlacesAutoComplete.module.css";

const GooglePlacesAutoComplete = ({ setLat, setLng }) => {
  return (
    <div className={`${styles.inputWrapper} position-relative`}>
      <Autocomplete
        apiKey={process.env.GOOGLE_MAPS_API_KEY}
        onPlaceSelected={(place) => {
          if (place && place.geometry && place.geometry.location) {
            const location = place.geometry.location;
            const lat = location.lat();
            const lng = location.lng();
            setLat(lat);
            setLng(lng);
          } else {
            console.error("Invalid place object:", place);
          }
        }}
        options={{
          types: ["establishment"],
          // componentRestrictions: { country: "pk" },
        }}
        placeholder="Please Enter the Address"
        style={{ width: "100%" }}
      />
      <img
        src={IMAGES.Litelocationicon}
        className={`${styles.locationIcon}`}
        alt="location"
      />
      <img
        src={IMAGES.successstatusinactiveicon}
        alt="Success Icon"
        className={`${styles.successIcon}`}
      />
    </div>
  );
};

export default GooglePlacesAutoComplete;
