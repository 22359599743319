import React, { useState, useEffect } from "react";
import Approutes from "./Routes";
import { RESET_ALL_STATES } from "./redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";

const App = () => {
  const dispatch = useDispatch();
  const [loginTime, setLoginTime] = useState(null);
  const { verifyOtpData } = useSelector(({ auth }) => ({
    verifyOtpData: auth?.verifyOtpData,
  }));

  // handle user-login
  const handleLogin = () => {
    setLoginTime(new Date());
  };

  useEffect(() => {
    if (verifyOtpData) {
      handleLogin();
    }
  }, [verifyOtpData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (loginTime) {
        const currentTime = new Date();

        if (currentTime.getDate() !== loginTime.getDate()) {
          dispatch({ type: RESET_ALL_STATES });
          localStorage.removeItem("reduxState");
          localStorage.clear();
          sessionStorage.clear();
          localStorage.removeItem("persist:root");
          window.location.reload("/");
          setLoginTime(null);
        }
      }
    }, 1000 * 60);

    // Cleanup-function
    return () => clearInterval(intervalId);
  }, [loginTime, dispatch]);

  return (
    <>
      <Approutes />
    </>
  );
};

export default App;
