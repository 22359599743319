// PaymentDetails.jsx

import CustomButton from "components/Common/CustomButton/CustomButton";
import InputFields from "components/Common/Inputfileds/InputFields";
import TopBar from "components/Common/TopBar/TopBar";
import { useState } from "react";
import { FaRegUser, FaUsers } from "react-icons/fa";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styles from "style/PaymentDetails.module.css";
import { IMAGES } from "utils/helper/Images";

const PaymentDetails = () => {
  const history = useNavigate();

  const navigateTo = (route) => {
    history(route);
  };
  const [activeStep, setActiveStep] = useState(1);

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={`${styles.mainPage} px-0 container-fluid`}>
      <TopBar IconItemName="Bill Info" navItemName="Payment Details" />
      <div className={`${styles.paymentDeatilsSection}`}>
        <div className={`${styles.LoginWrapper}`}>
          <div className={`${styles.pageTitle}`}>
            <span>Pay as Guest</span>
          </div>

          <div className={`${styles.formFields}`}>
            {/* First Line */}
            <div className="d-block d-lg-flex gap-2">
              <div className="col-lg mb-3 mb-lg-0">
                <InputFields
                  bgGray
                  shadowMd
                  CustomIcon={<FaRegUser />}
                  label="First Name"
                  type="text"
                  placeholder="First Name"
                  labelStyle={styles.customlable}
                />
              </div>
              <div className="col-lg mb-3 mb-lg-0">
                <InputFields
                  CustomIcon={<FaRegUser />}
                  bgGray
                  shadowMd
                  label="Last Name"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
              <div className="col-lg mb-3 mb-lg-0">
                <InputFields
                  type="datePicker"
                  bgGray
                  shadowMd
                  cssClass="patientDatePicker"
                  BgColorDtae="bg-shado-ate"
                  label="DOB"
                  value=""
                  required
                />
                {/* <InputFields CustomIcon={<FaUsers />} bgGray shadowMd label="DOB*"  hideCalendarIcon={true} type="date" placeholder="" /> */}
              </div>
              <div className="col-lg mb-3 mb-lg-0">
                <InputFields
                  CustomIcon={<FaUsers />}
                  bgGray
                  shadowMd
                  label="Customer ID"
                  type="text"
                  placeholder="xxxx-xxxx"
                />
              </div>
              <div className="col-lg mb-3 mb-lg-0">
                <InputFields
                  CustomIcon={<MdOutlineAccountBalanceWallet />}
                  bgGray
                  shadowMd
                  label="Accession Number"
                  type="text"
                  placeholder="xx-xxx-xxxx"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.LoginWrapperr} d-lg-flex d-block`}>
          <div className={`${styles.formFields} col-lg-8 col-md-12`}>
            <div className={`${styles.LeftSide}`}>
              {/* Visual Appearance */}
              <div className={`${styles.visualAppearance}`}>
                <div className={styles.visualAppearanceInner}>
                  <div
                    className={`${styles.step} ${
                      activeStep === 1 ? styles.activeStep : ""
                    }`}
                    onClick={() => handleStepClick(1)}
                  >
                    <img src={IMAGES.radiobuttonactive} alt="radio" /> Bill
                    Information
                  </div>
                  <div className={`${styles.separator}`} />
                  <div
                    className={`${styles.step} ${
                      activeStep === 2 ? styles.activeStep : ""
                    }`}
                    onClick={() => handleStepClick(2)}
                  >
                    <img src={IMAGES.radiobuttoninactive} alt="radio" /> Payment
                    Information
                  </div>
                </div>

                <div className={styles.dueAmount}>
                  <div className={styles.dueLabel}>
                    Due <br></br> Amount
                  </div>
                  <div className={styles.amountWrapper}>
                    <input
                      type="text"
                      className={styles.amountField}
                      value="$438.00"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              {/* Form Fields */}
              <div className="row">
                <div className="col-lg-6 col-md-12 mb-3">
                  <InputFields
                    CustomIcon={<MdOutlineAccountBalanceWallet />}
                    label="Account Number*"
                    type="text"
                    shadowSm
                    placeholder="xx-xxx-xxxx"
                    labelStyle={styles.customlable}
                  />
                </div>
                <div className="col-lg-6 col-md-12 mb-3">
                  <InputFields
                    CustomIcon={<MdOutlineAccountBalanceWallet />}
                    shadowSm
                    label="Verify Account Number"
                    type="text"
                    placeholder="xxxx-xxx-xxxxxxx"
                  />
                </div>
                <div className="col-lg-6 col-md-12 mb-3">
                  <InputFields
                    // CustomIcon={<FaDollarSign />}
                    shadowSm
                    label="Payment Amount"
                    type="text"
                    placeholder="$438.00"
                    value="$438.00"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.rightSide} col-lg-4 col-md-12`}>
            <div className={`${styles.contentBox} `}>
              <a
                href={IMAGES.pdfTh}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.imgBox} d-block position-relative`}
              >
                <img src={IMAGES.pdfTh} alt="pdf" />
                <img
                  src={IMAGES.openFUllImage}
                  alt="openImage"
                  className={styles.openFullImage}
                />
              </a>
            </div>
          </div>
        </div>

        <div
          className={`${styles.bottomBtn} d-block d-sm-flex align-items-center justify-content-between mt-4`}
        >
          <div className={`${styles.leftSide} `}>
            <CustomButton
              label="Back"
              handleClick={() => navigateTo("/welcome")}
              additionalClass={`${styles.backButton} ${styles.buttonCommon}`}
              variant="darkGray"
            />
          </div>
          <div className={`${styles.rightSide} d-sm-flex d-block `}>
            <CustomButton
              label="Next"
              handleClick={() => navigateTo("/payment-info")}
              additionalClass={`${styles.nextBtn} ${styles.buttonCommon} ms-sm-3`}
              variant="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
