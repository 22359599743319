import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";

const PdfViewer = ({ url }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleDownload = async () => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        setPdfUrl(URL.createObjectURL(blob));
        // setLoading(false);
      } catch (error) {
        console.error("Error downloading PDF:", error);
        setLoading(false);
      }
    };

    handleDownload();

    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [url]);

  useEffect(() => {
    const checkLoading = () => {
      if (pdfUrl) {
        setLoading(false);
      }
    };

    const loadingTimer = setTimeout(checkLoading, 1000); // Set a timeout to check loading status
    return () => clearTimeout(loadingTimer);
  }, [pdfUrl]);

  return (
    <div>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : pdfUrl ? (
        <embed
          src={pdfUrl}
          type="application/pdf"
          width="100%"
          height="700px"
          title="PDF Viewer"
        />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default PdfViewer;
