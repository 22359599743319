import React from "react";
import styles from "../../../style/CustomRadioButton.module.css";

const CustomRadioButton = ({
  label,
  id,
  checked,
  handleChange,
  cssClass,
  name,
  radiobtnLg,
}) => {
  // const containerClassName = `${cssClass} ${radiobtnLg ? styles.radiobtnLg : styles.customRadio }`;
  console.log("id", id, "name", name);
  return (
    <div
      className={`${
        radiobtnLg ? styles.radiobtnLg : styles.customRadio
      } ${cssClass} `}
    >
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onChange={(e) => handleChange(e)}
      />
      <label htmlFor={id} className={`${styles.labelColor || `text-black`}`}>
        {label}
      </label>
    </div>
  );
};

export default CustomRadioButton;
