import CustomButton from "components/Common/CustomButton/CustomButton";
import InputFields from "components/Common/Inputfileds/InputFields";
import TopBar from "components/Common/TopBar/TopBar";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styles from "style/forgotPassword.module.css";
import {
  forgotPasswordRequest,
  forgotPasswordReset,
} from "redux/reducers/duck/authDuck";
import { toast } from "react-toastify";
import Loader from "components/Common/Loader/Loader";

const ForgotPassword = () => {
  const forgotData = useSelector((state) => state.auth.forgotPasswordData);
  const loading = useSelector((state) => state.auth.loading);

  console.log("forgot data", forgotData);
  const history = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit, control, formState, setValue } = useForm({
    mode: "onChange",
  });

  const { errors } = formState;

  const navigateTo = (route) => {
    history(route);
  };

  const handleResetPassword = async (data) => {
    try {
      console.log("forgot phone", data.phone);
      dispatch(forgotPasswordRequest(data));
      // if (forgotData) {
      //   navigateTo("/change-password");
      //   toast.success("OTP sent Successfully")
      // }
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(forgotPasswordReset()); // Replace resetSignUpData with the appropriate action to reset signUpData
    };
  }, []);

  useEffect(() => {
    if (forgotData) {
      navigateTo("/change-password");
      toast.success("OTP sent Successfully");
    }
  }, [forgotData]);

  return (
    <>
      <TopBar IconItemName="Forgot Password" />
      <div className={styles.mainBodyBg}>
        {loading && <Loader />}
        <div className={`${styles.ForgotPassword}`}>
          <div className={styles.forgotPassInner}>
            <form
              className={`${styles.Form}`}
              onSubmit={handleSubmit(handleResetPassword)}
            >
              <h4>Forgot your Password?</h4>
              <p className={`${styles.Subtitle}`}>
              Please enter your phone number. We will send a code to your email address to reset your password.
          {/* Enter the phone number associated with your account, and we’ll send you a code to reset your password. */}
              </p>
              <div className={`${styles.FormField} mb-4 patient-phone-input `}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Phone Number is required",
                    pattern: {
                      value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                      message:
                        "Please enter a valid phone number (+x xxx xxx xxxx).",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        label="Phone"
                        type="phone"
                        placeholder="+1 625 999 3488"
                        value={field.value}
                        index="phone"
                        maxLength={65}
                        handleChange={(e) => {
                          setValue("phone", e);
                          field.onChange(e);
                        }}
                        required
                        roundedSm
                        shadowSm
                      />
                      {errors.phone && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.phone.message}
                        </div>
                      )}
                    </>
                  )}
                />
                <CustomButton
                  label="Continue"
                  additionalClass={`${styles.ResetButton} mt-4 text-uppercase`}
                  disabled={!formState.isValid}
                  type="submit"
                  variant="primary"
                />
                <p className={`${styles.SignupText} text-center `}>
                  Don't Have an Account? <Link to="/signup">Sign Up</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
