import { IMAGES } from "./Images";

import co_lab_image from "assets/images/colab_white_logo.png";
import bio_lab_inc from "assets/images/8cd1a74a-392c-4378-a8a0-15480ee37a1e.png";
import bio_lab_mobile from "assets/images/9a41b680-4f5f-4914-a8f6-2229740dca0b.png";
import new_discovery_image from "assets/images/3eaf991b-b876-4897-8e39-47aef2247dcd.png";
import alta_image from "assets/images/2ce90d8b-daa3-4b3c-a685-6bec0dfecb43.png";
import image from "assets/images/MedFlow-Alpha-LIS.png";

export const patientRoutes = {
  patient: `/`,
  welcome: `/welcome`,
  notification: `/notification`,
  appointments: `/appointments`,
  paymentHistory: `/payment-history`,
  updateInformation: `/update-information`,
  changePassword: `/change-password`,
  forgotPassword: `/forgot-password`,
  newAccountInfo: `/new-account-info`,
  patientLogin: `/patient-login`,
  paymentHistory: `/payment-history`,
  addAddress: `/add-address`,
  reviewAppointment: `/review-appointment`,
  appointmentLocation: `/appointment-location`,
  personalInsuranceDetails: `/personal-insurance-details`,
  paymentDetails: `/payment-details`,
  billingInfo: `/billing-info`,
  passwordSecurity: `/password-security`,
  InsuranceInfo: `/Insurance-info`,
  paymentInfo: `/payment-info`,
  billingFaqs: `/billing-faqs`,
  viewResult: `/results`,
  ViewInvoice: `/invoices`,
  pageNotFound: "/page-not-found",
};
export const TEST_PANEL_TYPES = {
  PANEL: "panel",
  QUALITATIVE_WITH_PATHOGEN: "qualitativeWithPathogen",
  QUALITATIVE_WITHOUT_PATHOGEN: "qualitativeWithOutPathogen",
  QUANTITATIVE_WITHOUT_PATHOGEN: "quantitativeWithOutPathogen",
  QUANTITATIVE_WITH_PATHOGEN: "quantitativeWithPathogen",
};
export const item = [
  // {
  //   name: "My Notifications",
  //   img: IMAGES.notificationIcon,
  //   link: patientRoutes.notification,
  // },

  // {
  //   name: "Payment History",
  //   img: IMAGES.paymentHistoryIcon,
  //   link: patientRoutes.paymentHistory,
  // },
  // {
  //   name: "Update Information",
  //   img: IMAGES.updateInfoIcon,
  //   link: patientRoutes.updateInformation,
  // },
  {
    name: "View Result",
    img: IMAGES.fileTestResuts,
    link: patientRoutes.viewResult,
  },
  {
    name: "View Invoice",
    img: IMAGES.fileInvoice,
    link: patientRoutes.ViewInvoice,
  },
  // {
  //   name: "Appointments",
  //   img: IMAGES.appointmentsIcon,
  //   link: patientRoutes.appointments,
  // },
];

export const relatedSidebarRoutes = new Map([
  ...item.map((path) => [path.link, path.link]),
  [patientRoutes.addAddress, patientRoutes.appointments],
  [patientRoutes.reviewAppointment, patientRoutes.appointments],
  [patientRoutes.appointmentLocation, patientRoutes.appointments],
  [patientRoutes.personalInsuranceDetails, patientRoutes.appointments],
]);

export const WHITE_LABEL = "whitelabel";

export const GENDER_TYPES_OPT = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const INSURANCE_PROVIDER_TYPES = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const TABLE_COLUMN_TYPES = {
  shortDate: "shortDate",
  dateTime: "dateTime",
  text: "text",
  upperCaseText: "upperCaseText",
  email: "email",
  phone: "phone",
  currency: "currency",
  image: "image",
  // insuranceProvider: "insuranceProvider",
  number: "number",
  percentage: "percentage",
  labelID: "labelID",
  link: "link",
};

export const TABLE_QUICK_TOOLS = {
  checkbox: "checkbox",
  edit: "edit",
  notes: "notes",
  delete: "delete",
};

export const currencyformatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const TEST_TYPE_DISCLAIMER_KEY = {
  Lucira: "lucira_notes",
  PCR: "testing_notes",
  Rapid_Antigen: "antigen_notes",
  Rapid_PCR: "other_notes",
  Molecular: "molecular_notes",
};

export const RESULTS_COLOR = {
  positive: "red",
  negative: "black",
  invalid: "red",
  inconclusive: "red",
  indeterminate: "red",
};

// const img = () => {
//   if (process.env.REACT_APP_LOGO.includes("co_lab")) {
//     return co_lab_image;
//   } else if (process.env.REACT_APP_ENV_WHITE === "whitelabel") {
//     return lis_lable_image;
//   }
//   return image;
// };
// export const logoImage = img();
const img = (labId) => {
  console.log('logo image lab id is : ', labId)
  if(labId == '8cd1a74a-392c-4378-a8a0-15480ee37a1e'){
    return bio_lab_inc
  }else if(labId == '9a41b680-4f5f-4914-a8f6-2229740dca0b'){
    return bio_lab_mobile
  }else if(labId == '3eaf991b-b876-4897-8e39-47aef2247dcd'){
    return new_discovery_image
  }else if(labId == '2ce90d8b-daa3-4b3c-a685-6bec0dfecb43'){
    return alta_image
  }
  return image;
};

export const logoImage = img;

export const COMBINE_PROFILE = "Combine Profile";
export const LOCATIONS_OPTIONS = [
  { value: "At Home", label: "At Home" },
  { value: "On Location", label: "On Set" },
];

export const CONFIG = {
  logoImage: img(),
  basename: process.env.REACT_APP_BASE_NAME,
  url: process.env.REACT_APP_URL,
  baseurl: process.env.REACT_APP_BASE_URL,
  isWhiteLabel: process.env.REACT_APP_ENV_WHITE === WHITE_LABEL,
  env: process.env.REACT_APP_ENV,
  isLive: process.env.REACT_APP_ENV === "live",
  whiteLabelID: process.env.REACT_APP_WHITELABEL_ID,
  clientID: process.env.REACT_APP_CLIENT,
  appPackage: process.env.REACT_APP_PACKAGE,
};

export const RESULT_PDF = {
  positive: "Detected",
  negative: "Not Detected",
  invalid: "Invalid",
  inconclusive: "Inconclusive",
  indeterminate: "Indeterminate",
};

export const END_POINTS_CUSTOM_ERRORS = [
  {
    "path": "/user/sendCode",
    "message": "Invalid phone number"
  },
  {
    "path": "/user/verifyCode",
    "message": "Invalid otp code"
  }
]