import { createSlice } from "@reduxjs/toolkit";

// Initial State
export const INITIAL_STATE = {
    patientID: null,
};

export const userSlice = createSlice({
    name: "patient",
    initialState: INITIAL_STATE,
    reducers: {
        updatePatientID(state, { payload }) {
            return {
                ...state,
                patientID: payload,
            };
        },
    },
});
export const { updatePatientID } = userSlice.actions;
export default userSlice.reducer;
