import CustomButton from "components/Common/CustomButton/CustomButton";
import Loader from "components/Common/Loader/Loader";
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "style/forgotPassword.module.css";
import {
  isAuthenticatedRequest,
  resendOTPRequest,
  verifyOTPClearState,
  verifyOTPRequest,
} from "../../../../redux/reducers/duck/authDuck";

const TwoFA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData, verifyOtpData, loading, is2FAenabled } = useSelector(
    ({ auth }) => ({
      loginData: auth?.loginData,
      verifyOtpData: auth?.verifyOtpData,
      loading: auth?.loading,
      is2FAenabled: auth?.is2FAenabled,
    })
  );

  const [verificationCode, setVerificationCode] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [disabledVerify, setDisabledVerify] = useState(false);
  const [countdown, setCountdown] = useState(120);

  // Function to start the countdown
  const startCountdown = () => {
    setDisabled(true);
    setCountdown(120);
  };

  // handle code-past
  useEffect(() => {
    const handlePaste = (e) => {
      e.preventDefault();
      const pastedValue = e.clipboardData.getData("text/plain");

      // Update the state with the pasted value
      setVerificationCode((prevCode) => {
        const newCode = pastedValue
          .split("")
          .slice(0, inputRefs.current.length)
          .map((code, i) => {
            return code;
          });
        return newCode.join("");
      });
    };

    // Attach paste event listener to the entire component
    document.addEventListener("paste", handlePaste);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  // Effect to update the countdown every second
  useEffect(() => {
    let intervalId;
    if (disabled) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [disabled]);

  // Effect to enable the button when countdown completes
  useEffect(() => {
    if (countdown === 0) {
      setDisabled(false);
    }
  }, [countdown]);

  // handle resend-OTP
  const handleResendOTP = () => {
    startCountdown();
    dispatch(resendOTPRequest());
    setDisabledVerify(false);
    toast.success("OTP send successfully");
  };
  const inputRefs = useRef(Array.from({ length: 6 }, () => createRef()));

  // handle input-change
  const handleChange = (index, e) => {
    const { value } = e.target;

    // Handle paste event
    const handlePaste = (pastedValue) => {
      setVerificationCode((prevCode) => {
        const newCode = [...prevCode];

        // If pasting, split the pasted value into individual characters
        const pastedCodes = pastedValue
          .split("")
          .slice(0, inputRefs.current.length);
        pastedCodes.forEach((code, i) => {
          const newIndex = index + i;
          if (newIndex < inputRefs.current.length) {
            newCode[newIndex] = code;
          }
        });

        return newCode.join("");
      });
    };

    // Prevent default behavior on paste
    const handlePasteEvent = (e) => {
      e.preventDefault();
      const pastedValue = e.clipboardData.getData("text/plain");
      handlePaste(pastedValue);
    };

    // Attach onPaste event listener
    inputRefs.current[index].current.addEventListener(
      "paste",
      handlePasteEvent
    );

    // Update the state and focus as needed
    setVerificationCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = value;
      return newCode.join("");
    });

    // If value is present and not at the last index, focus on the next input
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const verifyOTPID = process.env.REACT_APP_CLIENT_ID//loginData && loginData?.loginStatus?.clientID;

  // handle verify-OTP
  const handleVerify = (event) => {
    event.preventDefault();
    dispatch(
      verifyOTPRequest({
        code: verificationCode,
        clientID: verifyOTPID,
        payloadPhone: is2FAenabled?.userAttributes?.phone_number,
      })
    );
    setDisabledVerify(true);
    // console.log("handle verify");
  };

  // Effect to change routes
  useEffect(() => {
    if (verifyOtpData?.verified === true) {
      dispatch(isAuthenticatedRequest());
      navigate("/results");
    } else {
      // toast.info("Please enter valid OTP code");
      setDisabledVerify(false);
      dispatch(verifyOTPClearState());
    }
  }, [dispatch, navigate, verifyOtpData]);

  // Check if all inputs are filled
  const allInputsFilled = verificationCode.length === inputRefs.current.length;

  // console.log("verifyOtpData ==>", verifyOtpData);
  // console.log("disabledVerify ==>", disabledVerify);

  return (
    <div className={styles.twoFaWrapper}>
      <div>
        <div className={`${styles.Form}`}>
          {loading && <Loader />}

          <div className="registered-successful">
            <h3 className="w-100 text-center mt-2">
              <b>Authenticate Your Account</b>
            </h3>
            <p style={{ fontSize: "17px" }}>
              Please confirm your account by entering the authorization code
              sent to your associated email address.
            </p>
            <div className="custom-radio-btn-wrapper">
              {inputRefs.current.map((inputRef, index) => (
                <input
                  key={index}
                  ref={inputRef} // Assign the ref from the array
                  type="text"
                  maxLength="1"
                  className={styles.codeBox}
                  value={verificationCode[index] || ""} // Set value from state
                  onChange={(e) => handleChange(index, e)}
                />
              ))}
            </div>
            <div className="mt-4 d-block d-sm-flex column-gap-3 justify-content-center">
              <CustomButton
                variant="primary"
                type="submit"
                additionalClass={`rounded-2 text-capitalize btn ${styles.verifyBtn}`}
                label="Verify"
                handleClick={handleVerify}
                disabled={!allInputsFilled || disabledVerify}
              />
              <button
                className={`${styles.resendOtpButton} btn`}
                onClick={handleResendOTP}
                disabled={disabled}
              >
                {disabled ? `Please wait (${countdown}s)` : "Resend OTP"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFA;
