import PatientApi from "api/PatientApi";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../reducers/duck/authDuck";

// signup saga
export function* signUpSaga(payload) {
  try {
    const response = yield call(PatientApi.signUpApi, payload);
    if (response === "Your record is not found") {
      yield put(actions.signUpRecordNotFound(response));
    } else if (typeof response === "object") {
      yield put(actions.signUpSuccess(response));
    } else {
      yield put(actions.signUpFailure(response));
    }
  } catch (error) {
    yield put(actions.signUpFailure(error.message));
  }
}

// loginSaga
export function* loginSaga(payload) {
  try {
    const response = yield call(PatientApi.loginApi, payload);
    if (
      response &&
      response.loginStatus !== undefined &&
      response.userAttributes
    ) {
      console.log('loginSuccess invoked ')
      yield put(actions.loginSuccess(response));
      yield put(actions.is2FAenabled(response));
    } else if (
      response &&
      typeof response === "object" &&
      response.loginStatus === undefined
    ) {
      console.log('loginFailure invoked ')
      yield put(actions.loginFailure(response));
    } else {
      console.log('loginFailureUserNotExist invoked ')
      yield put(actions.loginFailureUserNotExist(response));
    }
  } catch (error) {
    yield put(actions.loginFailureUserNotExist(error));
  }
}

// forgot-password saga
export function* forgotPasswordSaga(params) {
  try {
    let combinedResponse = {};
    const payload = params.payload;
    const response = yield call(PatientApi.forgotpasswordApi, payload);
    if (typeof response === "string") {
      yield put(actions.forgotPasswordFailure(response));
    } else if (typeof response === "object") {
      combinedResponse = { ...response, ...payload };
      yield put(actions.forgotPasswordSuccess(combinedResponse));
    } else {
      yield put(
        actions.forgotPasswordFailure(
          "Invalid response format. Please try again."
        )
      );
    }
  } catch (error) {
    yield put(actions.forgotPasswordFailure(error.message));
  }
}

// change-passaword saga
export function* changePasswordSaga(params) {
  try {
    const payload = params.payload;
    const response = yield call(PatientApi.changepasswordApi, payload);
    if (response === "Password reset successful") {
      yield put(actions.changePasswordSuccess(response));
    } else {
      yield put(actions.changePasswordFailure(response));
    }
  } catch (error) {
    yield put(actions.forgotPasswordFailure(error.message));
  }
}

// logout saga
export function* logoutSaga() {
  try {
    const response = yield call(PatientApi.logoutApi);
    yield put(actions.logoutSuccess());
  } catch (error) {
    yield put(actions.logoutFailure(error.message));
  }
}

// verify-OTP Saga
export function* verifyOtpSaga(payload) {
  try {
    const response = yield call(PatientApi.verifyOTPApi, payload);

    if (response && typeof response === "object") {
      const loginDataRes = {
        loginStatus: response?.loginStatus,
        userAttributes: response?.userAttributes,
      };
      yield put(actions.verifyOTPResponse(response?.otpResponse));
      yield put(actions.loginSuccess(loginDataRes));
    } else if (!response) {
      yield put(actions.loginFailure());
    } else {
      yield put(actions.verifyOTPFailure());
    }
  } catch (error) {
    yield put(actions.verifyOTPFailure());
  }
}

// resend-OTP Saga
export function* resendOtpSaga() {
  try {
    const response = yield call(PatientApi.resendOTPApi);
    if (response) {
      yield put(actions.resendOTPResponse(response));
    } else {
      console.log("verify OTP failed");
    }
  } catch (error) {
    console.log("verify OTP failed");
  }
}

export function* watchAuthSagas() {
  yield takeLatest(actions.signUpRequest.type, signUpSaga);
  yield takeLatest(actions.loginRequest.type, loginSaga);
  yield takeLatest(actions.logoutRequest.type, logoutSaga);
  yield takeLatest(actions.forgotPasswordRequest.type, forgotPasswordSaga);
  yield takeLatest(actions.changePasswordRequest.type, changePasswordSaga);
  yield takeLatest(actions.verifyOTPRequest.type, verifyOtpSaga);
  yield takeLatest(actions.resendOTPRequest.type, resendOtpSaga);
}
