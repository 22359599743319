import { PDFViewer } from "@react-pdf/renderer";
import CustomModal from "components/Common/CustomModal/CustomModal";
import InputFields from "components/Common/Inputfileds/InputFields";
import Loader from "components/Common/Loader/Loader";
import Navbar from "components/Common/Navbar/Navbar";
import BiolabPdf from "components/Common/PDF/BiolabPdf";
import MainTable from "components/Common/Table/MainTable";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "style/ViewResults.module.css";
import { CONFIG, TABLE_COLUMN_TYPES } from "utils/helper/Constants";
import { IMAGES } from "utils/helper/Images";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  testsListingRequest,
  testResultsRequest,
} from "redux/reducers/duck/getTestsDuck";
import { parseTestResultForPDF } from "utils/utils";
import { isObjectEmpty } from "utils/utils";
import LabReportSisterPirerrePdf from "components/Common/PDF/LabReportSisterPierrePdf";
import { getUrl } from 'aws-amplify/storage';
import { Storage } from '@aws-amplify/storage';
import PdfViewer from "components/Common/PDF/PdfViewer";
import PatientApi from "api/PatientApi";


const ViewResults = () => {
  const dispatch = useDispatch();

  const originalData = useSelector((state) => state.getTests.filteredTests);
  const isLoading = useSelector((state) => state.getTests.loading);
  const selectedTest = useSelector((state) => state.getTests.selectedTest);

  const [allTests, setAllTests] = useState([]);
  const [searchedItem, setSearchedItem] = useState("");
  const [isShowPDF, setIsShowPDF] = useState(false);
  const [pdfPath, setPdfPath] = useState();

  const getUser = useSelector((state) => state.auth.loginData);
  const getEmployeeId = getUser?.loginStatus?.id;
  const clientIdRes = getUser.loginStatus?.clientID;
  

  // console.log("results res =>", getEmployeeId, clientIdRes);
  // console.log("getEmployeeId for results", getEmployeeId);

  const tableColumns = [
    {
      itemKey: "createdAt",
      title: "Collected Date",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 1,
      width: "100%",
      type: TABLE_COLUMN_TYPES.shortDate,
    },
    {
      itemKey: "resultDateTime",
      title: "Processed Date",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 2,
      width: "100%",
      type: TABLE_COLUMN_TYPES.shortDate,
      optionalValue: "N/A",
      // customCellRenderer: true,
    },

    {
      itemKey: "test_type",
      title: "Test Type",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 3,
      width: "100%",
      type: "text",
      cssClass: "text-capitalize",
    },
    {
      itemKey: "result",
      title: "Result",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 4,
      width: "100%",
      type: "text",
      customCellRenderer: true,
    },
    {
      itemKey: "barcode",
      title: "Accession Number",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 5,
      width: "100%",
      type: "text",

      //   customCellRenderer: true,
    },
    {
      itemKey: "status",
      title: "Status",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 6,
      width: "100%",
      type: "text",

      customCellRenderer: true,
    },
    {
      itemKey: "view",
      title: "View",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 7,
      width: "100%",
      type: "text",
      customCellRenderer: true,
    },
  ];
  // console.log("slectedListoftest", selectedTest);


  useEffect(()=> {


    async function fetchData() {
      // You can await here
      if(!isObjectEmpty(selectedTest)){
        console.log('here is the selected Test : ', selectedTest)
        let test = selectedTest
        try {
          const path = `results/${process.env.REACT_APP_VARIABLE_SET_ENV}/${test?.clientID}/${test?.barcode}.pdf`;
          const response = await PatientApi.getFilePath({ path: path, clientID: test.clientID });
          console.log("response is  :",response )
          setIsShowPDF(true)
          setPdfPath(response.url);
        } catch (error) {
          console.log("error", error);
        }
      }
       
      //   try {
          
      
         
      //     console.log('here is the pdf file path : ', url)
      //     // const result = await getUrl({
      //     //   path: url,
      //     //   options: {
      //     //     // specify a target bucket using name assigned in Amplify Backend
      //     //     bucket: 'employee-external-tests',
      //     //   }
      //     // });
      //     const result = await Storage.get(url, {
      //       download: false,
      //       contentDisposition: "inline",
      //       bucket: "employee-external-tests",
      //       contentType: "application/pdf",
      //     });
      // console.log('result is this  : ', result)
      //     setPdfPath(result)
      //     setIsShowPDF(true);
      //   } catch (error) {
      //     if (error.response && error.response.status === 404) {
      //       console.log("File does not exist");
           
      //     } else {
      //       console.error("Error occurred while checking file existence:", error);
      //     }
      //   }
      // }
      
    }
    fetchData();

  }, [selectedTest])


  useEffect(() => {
    dispatch(
      testsListingRequest({
        params: {
          employeeID: getEmployeeId,
          clientID: process.env.REACT_APP_CLIENT_ID,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setAllTests(originalData);
  }, [originalData]);

  const handleSearch = (e) => {
    const searchedValue = e.target.value.toLowerCase();
    if (searchedValue === "") {
      setSearchedItem("");
      setAllTests(originalData);
      return;
    }
    const filteredTests = originalData.filter((employee) => {
      return (
        (employee.test_type &&
          employee.test_type.toLowerCase().includes(searchedValue)) ||
        (employee.result &&
          employee.result.toLowerCase().includes(searchedValue)) ||
        (employee.barcode &&
          employee.barcode.toLowerCase().includes(searchedValue))
      );
    });
    setSearchedItem(searchedValue); // Update searched item in state
    setAllTests(filteredTests);
  };

  const handleShowPdf = async (id) => {
    // setIsShowPDF(!isShowPDF);
    console.log("handleShowPdf : ", id)

    dispatch(
      testResultsRequest({
        id: id,
        name: "test",
      })
    );
  };

  const customColumnCellRenderer = (column, row) => {
    if (column.itemKey === "view") {
      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: column.textAlign,
            textOverflow: column.textOverflow,
          }}
        >
          <div
            className={
              row.status.toLowerCase() === "processed"
                  ? (row.result && row.result != '' || row.resultDetails) ? "cursor-pointer"
                  : "pointer-events-none" : "pointer-events-none"
              // row.status.toLowerCase() === "processed"
              //   ?  "cursor-pointer"
              //   : "pointer-events-none"
            }
            onClick={() => handleShowPdf(row.id)}
          >
            <img
              style={{
                width: "25px",
              }}
              src={
                row.status.toLowerCase() === "processed"
                  ? (row.result && row.result != '' || row.resultDetails) ? IMAGES.eyeIcon
                  : IMAGES.inActiveEyeIcon : IMAGES.inActiveEyeIcon
              }
              alt="bills"
            />
          </div>
        </td>
      );
    }

    if (column.itemKey === "result") {
      console.log("here is the result: ", row.result)
      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: column.textAlign,
            textOverflow: column.textOverflow,
          }}
        >
          {(row.resultDetails && typeof row.resultDetails === 'object' )  ? (
            <span
            className="cursor-pointer"
            style={{ color: "red" }}
            onClick={()=> {
              handleShowPdf(row.id)
            }}
            // className={"View Detail"}
          >
            {"View Detail"}
          </span>
          ) : 
   
            <label
            className={
              row.result.toLowerCase() === "not detected"
                ? "text-success"
                : row.result.toLowerCase() === "detected"
                ? "text-danger"
                : "text-black"
            }
          >
            {row.result || "N/A"}
          </label>
          }
          

          {/* <label
            className={
              row.result.toLowerCase() === "negative"
                ? "text-success"
                : row.result.toLowerCase() === "positive"
                ? "text-danger"
                : "text-black"
            }
          >
            {row.result || "N/A"}
          </label> */}
        </td>
      );
    }
    if (column.itemKey === "status") {
      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: column.textAlign,
            textOverflow: column.textOverflow,
          }}
        >
          <label
            className={
              row.status.toLowerCase() === "pending"
                ? "text-warning"
                : "text-black"
            }
          >
            {row.status || "N/A"}
          </label>
        </td>
      );
    }
    // Handle other columns if needed
  };
  const customCssClass = "Patient-history-table";

  return (
    <div className={`w-100 ${styles.mainWrapper}`}>
      <Navbar
        navlinkText="Results"
        navlinkText1="View Result"
        navlinkIcon={
          <img
            className={styles.HomeImg}
            src={IMAGES.DarkHomeIcon}
            alt="home"
          />
        }
      />
      <div className={`px-3 ${styles.pageWrapper}`}>
        <div
          className={`${styles.searchPanelWrapper} d-block d-sm-flex justify-content-between align-items-center`}
        >
          <h2 className={`${styles.pageTitle} mb-2 mb-sm-2`}>View Result</h2>
          <div className="d-flex align-items-center">
            <InputFields
              type="text"
              placeholder="Search"
              handleChange={(e) => handleSearch(e)}
              value={searchedItem}
              customClass="w-100"
            />
          </div>
        </div>
        <div className="mt-3">
          {allTests.length === 0 ? (
            <p className="text-center fs-4">No data found</p>
          ) : (
            <MainTable
              columns={tableColumns}
              rows={allTests}
              cssClass={customCssClass}
              customColumnCellRenderer={customColumnCellRenderer}
            />
          )}
          {isShowPDF && !isObjectEmpty(selectedTest) && (
            <div>
              <CustomModal
                showModal={isShowPDF}
                handleClose={() => setIsShowPDF(false)}
                title="Test Results"
                label="Submit"
                size="xl"
                modalBodyClass
              >
                {" "}
                <Container fluid className="px-0">
                  <Row>
                    <Col md="12">
                      <Card className="strpied-tabled-with-hover">
                        <Card.Body className="pdfContainer table-full-width table-responsive p-0">
                        <PdfViewer url={pdfPath} >
                          {/* <PDFViewer className="pdfView"> */}
                            {/* <BiolabPdf
                              test={selectedTest}
                              testsLab={selectedTest.lab}
                              clientInfo={selectedTest.client}
                              parseTestResultForPDF={(e) =>
                                parseTestResultForPDF(e)
                              }
                            /> */}
                            <LabReportSisterPirerrePdf
                              test={selectedTest}
                              testsLab={selectedTest.lab}
                              clientInfo={selectedTest.client}
                              parseTestResultForPDF={(e) =>
                                parseTestResultForPDF(e)
                              }
                            />
                          {/* </PDFViewer> */}
                          </PdfViewer>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </CustomModal>
            </div>
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default ViewResults;
