import CustomButton from "components/Common/CustomButton/CustomButton";
import CustomRadioButton from "components/Common/CustomRadioButton/CustomRadioButton";
import InputFields from "components/Common/Inputfileds/InputFields";
import Navbar from "components/Common/Navbar/Navbar";
import RightSideBar from "components/Common/RightSideBar/RightSideBar";
import { IMAGES } from "utils/helper/Images";
import moment from "moment";
import { useState } from "react";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getValidDep } from "utils/utils";
import styles from "style/PersonalInsuranceDetails.module.css";
import { patientRoutes } from "utils/helper/Constants";
import {
  GENDER_TYPES_OPT,
  INSURANCE_PROVIDER_TYPES,
} from "utils/helper/Constants";

const PersonalInsuranceDetails = () => {
  const navigate = useNavigate();

  const [PersonalInsuranceData, setPersonalInsuranceData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    phone: "",
    email: "",
    gender: "",
    insuranceProviderName: "",
    insuranceNumber: "",
    groupId: "",
  });

  const handleInputChange = (fieldName, value) => {
    if (fieldName === "gender" || "insuranceProviderName") {
      setPersonalInsuranceData({
        ...PersonalInsuranceData,
        [fieldName]: value.value,
      });
    } else {
      setPersonalInsuranceData({
        ...PersonalInsuranceData,
        [fieldName]: getValidDep(value),
      });
    }
  };

  const handlePersonalInsuranceDetails = () => {
    navigate("/review-appointment");
  };

  return (
    <div className={`d-flex justify-content-start ${styles.mainWrapperr}`}>
      <div className={`${styles.rightmainWrapper} `}>
        <Navbar
          color
          navlinkText="Appointments"
          navlinkText1="New Appointment"
          navlinkIcon={
            <img
              className={styles.HomeImg}
              src={IMAGES.DarkHomeIcon}
              alt="home"
            />
          }
        />

        <div
          className={`${styles.pageWrapper} row flex-column-reverse flex-md-row mb-4 mb-md-0`}
        >
          <div className="col-md-8 col-lg-8 col-xl-9 pe-2">
            <div className={styles.bgWhite}>
              <div className={`${styles.title}`}>
                Please Enter Personal and Insurance Details
              </div>
              <div className={`${styles.detailsWrapper}`}>
                {/* Personal details wrapper */}
                <div className={`${styles.subtitle}`}>Personal Details</div>
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <InputFields
                      roundedSm
                      label="First Name"
                      customClass="mb-3 mt-0 mt-sm-3"
                      type="text"
                      placeholder="Enter First Name"
                      value={PersonalInsuranceData.firstName}
                      index="firstName"
                      maxLength={100}
                      handleChange={(e) =>
                        handleInputChange("firstName", e.target.value)
                      }
                      required
                      CustomIcon={<FaRegUser />}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <InputFields
                      roundedSm
                      label="Last Name"
                      customClass="mb-3 mt-0 mt-sm-3"
                      type="text"
                      placeholder="Enter Last Name"
                      value={PersonalInsuranceData.lastName}
                      index="lastName"
                      maxLength={100}
                      handleChange={(e) =>
                        handleInputChange("lastName", e.target.value)
                      }
                      required
                      CustomIcon={<FaRegUser />}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 patient-phone-input ">
                    <InputFields
                      roundedSm
                      label="Phone"
                      customClass="mb-3 mt-0 mt-sm-3"
                      type="phone"
                      placeholder="Enter your Phone Number"
                      value={PersonalInsuranceData.phone}
                      index="phone"
                      maxLength={100}
                      handleChange={(e) => handleInputChange("phone", e)}
                      required
                    />
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <InputFields
                      roundedSm
                      label="Email"
                      customClass="mb-3 mt-0 mt-sm-3"
                      type="email"
                      placeholder="Enter your Email"
                      value={PersonalInsuranceData.email}
                      index="email"
                      maxLength={100}
                      handleChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      required
                      CustomIcon={<HiOutlineMail />}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 patientDatePicker">
                    <InputFields
                      roundedSm
                      label="Birthday"
                      customClass="mb-3 mt-0 mt-sm-3"
                      type="datePicker"
                      placeholder="dd/mm/yyyy"
                      cssClass="patientDatePicker"
                      value={
                        PersonalInsuranceData.dob &&
                        new Date(PersonalInsuranceData.dob) !== "Invalid Date"
                          ? moment(
                              PersonalInsuranceData.dob,
                              "YYYY-MM-DD"
                            ).toDate()
                          : null
                      }
                      index="dob"
                      maxLength={100}
                      handleChange={(e) =>
                        handleInputChange(
                          "dob",
                          moment(e).format("YYYY-MM-DD") !== "Invalid date"
                            ? moment(e).format("YYYY-MM-DD")
                            : ""
                        )
                      }
                      minDate={new Date("1900-01-01")}
                      maxDate={new Date()}
                      required
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 patient-dropDown-wrapper">
                    <InputFields
                      roundedSm
                      label="Gender"
                      type="dropDown"
                      options={GENDER_TYPES_OPT}
                      value={GENDER_TYPES_OPT.find(
                        (option) =>
                          option.value === PersonalInsuranceData.gender
                      )}
                      index="gender"
                      handleChange={(e) => handleInputChange("gender", e)}
                      required
                      customClass="mb-3 mt-0 mt-sm-3"
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.insuranceDetailsWrapper}`}>
                {/* Insurance details wrapper */}
                <div className={`${styles.subtitle}`}>
                  Primary Insurance Details
                </div>
                <div className="row">
                  {/* <div className="col-md-6">
                    <InputFields
                      roundedSm
                      label="Insurance Provider"
                      customClass="mb-3 mt-0 mt-sm-3"
                      type="text"
                      placeholder="Enter Insurance Provider Name"
                      value={PersonalInsuranceData.insuranceProviderName}
                      index="insuranceProviderName"
                      maxLength={100}
                      handleChange={(e) => handleInputChange("insuranceProviderName", e.target.value)}
                      onFocus={() => handleInputFocus("insuranceProviderName")}
                      required
                      CustomIcon={<MdOutlineHealthAndSafety />}
                    />
                    {isFieldFocused("insuranceProviderName") && validationErrors.insuranceProviderName && (
                      <div className={`${styles.validationmessage} validation-message`}>
                        {validationErrors.insuranceProviderName}
                      </div>
                    )}
                  </div> */}
                  <div className="col-md-6 patient-dropDown-wrapper">
                    <InputFields
                      roundedSm
                      label="Insurance Provider"
                      type="dropDown"
                      options={INSURANCE_PROVIDER_TYPES}
                      value={INSURANCE_PROVIDER_TYPES.find(
                        (option) =>
                          option.value ===
                          PersonalInsuranceData.insuranceProviderName
                      )}
                      index="insuranceProviderName"
                      handleChange={(e) =>
                        handleInputChange("insuranceProviderName", e)
                      }
                      required
                      customClass="mt-0 mt-sm-3"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputFields
                      roundedSm
                      label="Insurance No"
                      customClass="mt-0 mt-sm-3"
                      type="text"
                      placeholder="Enter Insurance No"
                      value={PersonalInsuranceData.insuranceNumber}
                      index="insuranceNumber"
                      maxLength={100}
                      handleChange={(e) =>
                        handleInputChange("insuranceNumber", e.target.value)
                      }
                      required
                      CustomIcon={<MdOutlineConfirmationNumber />}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputFields
                      roundedSm
                      label="Group Id"
                      customClass="mt-0 mt-sm-3"
                      type="text"
                      placeholder="Enter Group ID"
                      value={PersonalInsuranceData.groupId}
                      index="groupId"
                      maxLength={100}
                      handleChange={(e) =>
                        handleInputChange("groupId", e.target.value)
                      }
                      required
                      CustomIcon={<AiOutlineUsergroupAdd />}
                    />
                  </div>
                  <div className={`${styles.questionWrapper} col-md-6`}>
                    <div className={`${styles.questionsubtitle} text-black`}>
                      Who is the primary insurance holder?
                    </div>
                    <div className={`${styles.checkboxGroup}`}>
                      <CustomRadioButton
                        label="Carmen"
                        id="carmen"
                        checked
                        cssClass="ms-0 ms-lg-5"
                        handleChange={(e) => console.log(e)}
                        name="carmen"
                      />
                      <CustomRadioButton
                        cssClass="ms-lg-5 ms-sm-3 ms-3 ms-md-0"
                        label="Someone Else"
                        id="someOneElse"
                        handleChange={(e) => console.log(e)}
                        name="carmen"
                      />
                    </div>
                  </div>
                  <div className={`${styles.questionWrapper} col-md-6`}>
                    <div className={`${styles.questionsubtitle} text-black`}>
                      Would you like to use secondary insurance?
                    </div>
                    <div className={`${styles.checkboxGroup}`}>
                      <CustomRadioButton
                        label="Yes"
                        id="secondaryInsurance01"
                        cssClass="ms-lg-4 ms-0"
                        handleChange={(e) => console.log(e)}
                        name="secondaryInsurance00"
                      />
                      <CustomRadioButton
                        cssClass="ms-lg-4 ms-sm-3 ms-3 ms-md-0"
                        checked
                        label="No"
                        id="secondaryInsurance02"
                        handleChange={(e) => console.log(e)}
                        name="secondaryInsurance00"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-3">
            <RightSideBar
              progressBar
              currentStep={4}
              phoneNumber="(909) 718-4592"
              faxNumber="(909) 469-6718"
              email="billing@biolabaratory.net"
              time="8:00 AM - 5:00 PM PST-M-F"
            />
            {/* <AppointmentRightSide /> */}
          </div>
        </div>

        <div className="d-block d-sm-flex align-items-center justify-content-between pb-2 pe-0 pe-lg-4">
          <div className={`${styles.leftSide} `}>
            <CustomButton
              handleChange={() => navigate("/appointment-location")}
              label="Back"
              additionalClass={styles.BtnDraft}
              variant="darkGray"
            />
          </div>
          <div className={`${styles.rightSide} d-flex`}>
            <CustomButton
              label="Next"
              // disabled={isButtonDisabled}
              handleClick={handlePersonalInsuranceDetails}
              additionalClass={`${styles.nextBtn} ${styles.BtnDraft}`}
              variant="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInsuranceDetails;
