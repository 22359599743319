import { createSlice } from "@reduxjs/toolkit";

// Initial State
export const INITIAL_STATE = {
  userData: null,
};

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState: INITIAL_STATE,
  reducers: {
    // request reducers
    userRequest: (state) => state,
    // response reducers
    userResponse(state, { payload }) {
      return {
        ...state,
        userData: payload.response,
      };
    },
    // other reducers
  },
});
export const { userRequest, userResponse } = appointmentSlice.actions;
export default appointmentSlice.reducer;
