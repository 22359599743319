// import api from "api";
import DropdownMenu from "./DropDownMenu";
// import Notes from "components/Notes";
import debounce from "debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { TABLE_COLUMN_TYPES, TABLE_QUICK_TOOLS } from "utils/helper/Constants";
import useResizeObserver from "utils/hooks/useResizeObserver";
import {
  CapsFirstLetter,
  calculateTdWidth,
  convertToUpper,
  draggablePersonalizationLocalStorage,
  formatDate,
  formatDateMDY,
  formatDateTime,
  // insuranceProviderFormatter,
  tdEmail,
  tdPhone,
} from "utils/utils";
// import EditIcon from "../../assets/img/edit-icon.png";
// import TrashIcon from "../../assets/img/trash-icon.png";
// import { useSelector } from "react-redux";
import HeaderItem from "./HeaderItem";
// import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
// import { employeesSelector } from "store/features/employees/employeesSelectors";
import {
  formatCurrency,
  formatLabelID,
  formatNumber,
  formatPercentage,
} from "utils/utils";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";

const MainTable = (props) => {
  const {
    columns,
    rows,
    individualRowCssClass,
    handleSaveDragAndResize,
    personalisationKey,
    draggable,
    resizable,
    flipSort,
    sortBy,
    sortDescending,
    widthToSkip,
    height,
    tools = [],
    dropDownOptions,
    customDropDownOptsForRow,
    handleDropDownClick,
    selectedRows = [],
    handleCellClick = null,
    cssClass,
    customColumnCellRenderer,
    // parentKey,
    styled,
  } = props;

  const [columnToBeSwapped, setColumnToBeSwapped] = useState(null);
  const [reset, setReset] = useState(false);
  const [width, setWidth] = useState(0);
  const resizeableTable = useRef();
  const tableWrapperRef = useRef();
  // const loginUser = useSelector(selectLoginUser);
  // const { employees } = useSelector(employeesSelector);

  //Screen Resolution Change
  useEffect(() => {
    handleScreenWidthChange();
  }, [tableWrapperRef]);

  useEffect(() => {
    window.addEventListener("resize", handleScreenWidthChange);
    return () => {
      window.removeEventListener("resize", handleScreenWidthChange);
    };
  });

  const handleScreenWidthChange = () => {
    if (tableWrapperRef.current) setWidth(tableWrapperRef.current.offsetWidth);
  };

  const tdWidth = calculateTdWidth(
    width - (widthToSkip || 0),
    columns.length > 0 ? columns.filter((p) => p.isCheck).length : 2
  );

  const handlePersonalization = async (data) => {
    if (!personalisationKey) return;
    handleSaveDragAndResize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(
      data,
      personalisationKey
    );
    draggablePersonalizationLocalStorage.save(
      JSON.stringify(personalisationData)
    );
    // await api.udpatePersonalization(
    //   loginUser.phone_number,
    //   personalisationData
    // );
  };

  useEffect(() => {
    if (reset) {
      setTimeout(() => setReset(false), 100);
    }
  }, [reset]);

  // Column Resizing
  let dimensions = null;
  // eslint-disable-next-line
  if (resizable) dimensions = useResizeObserver(resizeableTable);

  useEffect(() => {
    handleScreenWidthChange();
    if (dimensions) {
      const props = { columns, whiteSpaceVisible: width - dimensions.width };
      handleColumnResizerDelay(props);
    }
  }, [dimensions]);

  const handleColumnResizerDelay = useCallback(
    debounce((props) => {
      handleColumnResizer(props);
    }, 200),
    []
  );

  const handleColumnResizer = (props) => {
    const { columns, whiteSpaceVisible } = props;
    if (!resizeableTable || !resizeableTable.current?.childNodes?.length > 0)
      return;
    if (!columns || columns.length === 0) return;

    const headerCells =
      resizeableTable.current?.childNodes[0]?.childNodes[0]?.childNodes;
    if (!headerCells || !headerCells?.length > 2) return;

    let eachColumnAdditionalWidth = 0;
    if (Math.floor(whiteSpaceVisible) > 4) {
      eachColumnAdditionalWidth = Math.floor(
        whiteSpaceVisible / (headerCells.length - 2)
      );
    }
    let personalizeToSave = [...columns];
    for (let i = 1; i < headerCells.length - 1; i++) {
      const colIndex = personalizeToSave.findIndex(
        (column) =>
          column.title?.toLowerCase() ===
          headerCells[i].childNodes[0].innerText.toLowerCase()
      );

      if (colIndex === -1) continue;

      const obj = {
        ...personalizeToSave[colIndex],
        width:
          headerCells[i].childNodes[0].offsetWidth + eachColumnAdditionalWidth,
      };
      personalizeToSave.splice(colIndex, 1, { ...obj });
    }
    setReset(true);
    handlePersonalization(personalizeToSave);
  };

  // Draggable Column
  const handleDragStart = (e) => {
    const targetObj =
      columns.find(
        (col) =>
          col.isCheck &&
          col.title.toLowerCase() === e.target.innerText.toLowerCase()
      ) || null;
    setColumnToBeSwapped(targetObj);
  };

  const handleColumnChange = (e) => {
    const targetDestinationObj = columns.find(
      (col) =>
        col.isCheck &&
        col.title.toLowerCase() === e.target.innerText.toLowerCase()
    );

    if (!columnToBeSwapped || !targetDestinationObj) return;
    if (columnToBeSwapped.id === targetDestinationObj.id) return;

    let personalisationData = [...columns];
    const subjectIndex = personalisationData.findIndex(
      (col) => col.id === columnToBeSwapped.id
    );
    const targetIndex = personalisationData.findIndex(
      (col) => col.id === targetDestinationObj.id
    );

    const temp = personalisationData.splice(subjectIndex, 1)[0];
    personalisationData.splice(targetIndex, 0, temp);
    handlePersonalization(personalisationData);
  };

  const handleClickCall = (item, row) => {
    handleCellClick && handleCellClick(item.itemKey, row);
  };

  const headerAlign = (item) => (item === "center" ? "centered" : undefined);

  const tdFormat = (item, row) => {
    const value = row[item.itemKey] || "";
    const optionalValue = item.optionalValue || "";
    switch (item.type) {
      case TABLE_COLUMN_TYPES.shortDate:
        return value ? formatDateMDY(value) : optionalValue;

      case TABLE_COLUMN_TYPES.longDate:
        return value ? formatDate(value) : optionalValue;

      case TABLE_COLUMN_TYPES.dateTime:
        return value ? formatDateTime(value) : optionalValue;

      case TABLE_COLUMN_TYPES.currency:
        return formatCurrency(value || 0);

      // case TABLE_COLUMN_TYPES.insuranceProvider:
      //   return value ? insuranceProviderFormatter(value) : optionalValue;

      case TABLE_COLUMN_TYPES.upperCaseText:
        return convertToUpper(value || "");

      case TABLE_COLUMN_TYPES.number:
        return value ? formatNumber(value) : optionalValue;

      case TABLE_COLUMN_TYPES.percentage:
        return value ? formatPercentage(value) : optionalValue;

      case TABLE_COLUMN_TYPES.labelID:
        return value ? formatLabelID(value) : optionalValue;

      default:
        return value ? CapsFirstLetter(value.toString()) : "";
    }
  };

  const tdImage = (item, row) => (
    <td
      key={item.id}
      className={`table-column ellipsis linkedImage ${item.cssClass ?? ""}`}
      onClick={() => handleClickCall(item, row)}
    >
      {row[item.itemKey] && <i className="fas fa-image" />}
    </td>
  );

  const renderTd = (item, row) => {
    if (item.customCellRenderer) return customColumnCellRenderer(item, row);
    switch (item.type) {
      case TABLE_COLUMN_TYPES.email:
        return tdEmail(row[item.itemKey]);

      case TABLE_COLUMN_TYPES.phone:
        return tdPhone(row[item.itemKey], item.textAlign);

      case TABLE_COLUMN_TYPES.image:
        return tdImage(item, row);

      default:
        return (
          <td
            key={item.id}
            className={`ellipsis ${item.cssClass ?? ""}`}
            style={{
              textAlign: item.textAlign,
              textOverflow: item.textOverflow,
              color: `${item.colorObj ? item.colorObj[row[item.itemKey]] : ""}`,
              ...item.style,
            }}
            onClick={() => handleClickCall(item, row)}
            title={tdFormat(item, row)}
          >
            {tdFormat(item, row)}
          </td>
        );
    }
  };

  const TableRow = ({ row, index }) => {
    return (
      <tr
        className={`${
          individualRowCssClass ? individualRowCssClass(row) : ""
        } patientRowHover`}
      >
        <td
          className={`ellipsis tools-td text-center ${
            tools.length > 0 ? "tools-available" : "tools-unavailable"
          }`}
        >
          <div
            className={`d-flex align-items-center w-100 column-gap-10 ${
              styled && "justify-content-center"
            }`}
          >
            {tools.includes(TABLE_QUICK_TOOLS.checkbox) && (
              <>
                <CustomCheckbox
                  type="styled"
                  id="customTable"
                  onChange={() => console.log()}
                />
                <td
                  className="ellipsis text-center"
                  style={{
                    textAlign: row.textAlign,
                    textOverflow: row.textOverflow,
                    minWidth: "20px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  {/* Switch button */}
                  {/* <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label> */}
                </td>
              </>
            )}
            {tools.includes(TABLE_QUICK_TOOLS.edit) && (
              <img
                // src={EditIcon}
                alt="edit icon"
                width="18"
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                id={row.id}
                onClick={(event) =>
                  handleCellClick &&
                  handleCellClick(TABLE_QUICK_TOOLS.edit, row, event)
                }
              />
            )}
            {/* {tools.includes("provider") && (
              <img
                src={EditIcon}
                alt="edit icon"
                width="18"
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                id={row.id}
                onClick={(event) => handleCellClick && handleCellClick("provider", row, event)}
              />
            )} */}
            {/* {tools.includes(TABLE_QUICK_TOOLS.notes) && (
              <Notes
                note={
                  typeof row.note === "object" ? row.note?.message : row.note
                }
                empNotes={
                  row.employeeID
                    ? employees.find((f) => f.id === row.employeeID)?.note
                    : ""
                }
                handleClick={() =>
                  handleCellClick &&
                  handleCellClick(TABLE_QUICK_TOOLS.notes, row)
                }
              />
            )} */}
          </div>
        </td>

        {columns.map((col) => col.isCheck && renderTd(col, row))}

        <td
          className={`icon tools-td ${
            rows.length === 1 ? "single-row-dropdown-menu" : ""
          } ${
            tools.includes(TABLE_QUICK_TOOLS.delete) ||
            customDropDownOptsForRow ||
            dropDownOptions
              ? "tools-available"
              : "tools-unavailable"
          }`}
        >
          {((customDropDownOptsForRow && customDropDownOptsForRow.length > 0) ||
            (dropDownOptions && dropDownOptions.length > 0)) && (
            <DropdownMenu
              options={
                customDropDownOptsForRow
                  ? customDropDownOptsForRow(row)
                  : dropDownOptions
              }
              handleDropDownOptions={(type) => handleDropDownClick(type, row)}
            />
          )}

          {/* {tools.includes(TABLE_QUICK_TOOLS.delete) && (
            <img
              src={TrashIcon}
              alt="trash icon"
              width="15"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={row.id}
              onClick={(event) =>
                handleCellClick &&
                handleCellClick(TABLE_QUICK_TOOLS.delete, row, event)
              }
            />
          )} */}
        </td>
      </tr>
    );
  };

  return (
    <>
      <div
        ref={tableWrapperRef}
        className={`table-responsive pendingReleaseTable ${
          resizable ? "empClaimTable" : ""
        } ${cssClass || ""}`}
        style={{ height: `${height}px` }}
      >
        <Table
          ref={resizeableTable}
          className={`user-table employeeTableWrapper ${
            styled && "patientTableWrapper"
          }`}
        >
          <thead>
            <tr>
              <th
                className={`tools-td ${
                  tools.length > 0 ? "tools-available" : "tools-unavailable"
                }`}
              />
              {columns.map(
                (item, i) =>
                  item.isCheck && (
                    <HeaderItem
                      key={i}
                      draggable={draggable}
                      width={!resizable && tdWidth}
                      // minWidth={resizable && tdWidth}
                      innerDivWidth={
                        resizable && (reset ? `${item.width}px` : item.width)
                      }
                      handleDrop={handleColumnChange}
                      handleDragStart={handleDragStart}
                      ItemKey={item.itemKey}
                      title={item.title}
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned={headerAlign(item.textAlign)}
                      cssClass={resizable ? "claimTableTH" : ""}
                    />
                  )
              )}
              <th
                className={`tools-td ${
                  tools.includes(TABLE_QUICK_TOOLS.delete) ||
                  customDropDownOptsForRow ||
                  dropDownOptions
                    ? "tools-available"
                    : "tools-unavailable"
                }`}
              />
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <TableRow key={row.id} row={row} index={index} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default MainTable;
