import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Initial State
export const INITIAL_STATE = {
  invoices: [],
  loading: false,
  error: null,
  filteredTests: [],
  testResults: {},
  selectedTest: {},
};

export const getInvoicesSlice = createSlice({
  name: "invoices",
  initialState: INITIAL_STATE,
  reducers: {
    // invoices request reducers
    invoicesListingRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
    },
    // invoices response reducers
    invoicesListingSuccess(state, { payload }) {
      state.invoices = payload;
      state.loading = false;
      state.filteredTests = payload.rows;
    },

    invoicesListingFailure(state, { payload }) {
      state.error = payload;
      state.loading = false;
      toast.error(payload);
    },

    invoicePathRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
    },
    invoicePathSuccess(state, { payload }) {
      // state.invoicePath = payload;
      state.loading = false;
      state.selectedTest = payload.data.Item;
    },

    invoicePathFailure(state, { payload }) {
      state.error = payload;
      state.loading = false;
      toast.error(payload);
    },
  },
});

export const {
  invoicesListingRequest,
  invoicesListingSuccess,
  invoicesListingFailure,
  // invoicePathRequest,
  // invoicePathSuccess,
  // invoicePathFailure,
} = getInvoicesSlice.actions;

export default getInvoicesSlice.reducer;
