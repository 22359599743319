import CustomButton from "components/Common/CustomButton/CustomButton";
import CustomModal from "components/Common/CustomModal/CustomModal";
import CustomRadioButton from "components/Common/CustomRadioButton/CustomRadioButton";
import DateCalenderSlider from "components/Common/DateCalender/DateCalenderSlider";
import InputFields from "components/Common/Inputfileds/InputFields";
import Navbar from "components/Common/Navbar/Navbar";
import RightSideBar from "components/Common/RightSideBar/RightSideBar";
import { useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import styles from "style/AppointmentLocation.module.css";
import { IMAGES } from "utils/helper/Images";

const AppointmentLocationTime = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useNavigate();

  const navigateTo = (route) => {
    history(route);
  };

  const timeSlots = [
    {
      id: "1",
      label: "08-09 AM",
      //   checked: false,
    },
    {
      id: "2",
      label: "10-11 AM",
      //   checked: true,
    },
    {
      id: "3",
      label: "11-12 AM",
      //   checked: false,
    },
    {
      id: "4",
      label: "12-01 PM",
      //   checked: false,
    },
    {
      id: "5",
      label: "1:30-02 PM",
      //   checked: false,
    },
    {
      id: "6",
      label: "02-03 PM",
      //   checked: false,
    },
    {
      id: "7",
      label: "03-04 PM",
      //   checked: false,
    },
    {
      id: "8",
      label: "05-05:30 PM",
      //   checked: false,
    },
    {
      id: "9",
      label: "06-07 PM",
      //   checked: false,
    },
    {
      id: "10",
      label: "07-7:30 PM",
      //   checked: false,
    },
  ];
  const handleChangeRadio = () => {
    console.log("radio button");
  };
  return (
    <>
      <div className={` ${styles.mainWrapper}`}>
        <div className={` ${styles.rightmainWrapper} `}>
          <Navbar
            color
            navlinkText="Appointments"
            navlinkText1="New Order"
            navlinkIcon={
              <img
                className={styles.HomeImg}
                src={IMAGES.DarkHomeIcon}
                alt="home"
              />
            }
          />

          <div
            className={`${styles.pageWrapper} row flex-column-reverse flex-md-row mb-4 mb-md-0`}
          >
            <div className="col-md-8 col-lg-8 col-xl-9 pe-2">
              <div className={`${styles.alertsWrpper}`}>
                <p className={`${styles.topText} mb-0`}>
                  Now, let's find a location and time that works for you.
                </p>
                <div
                  className={`${styles.AddLocationWrapper} d-block d-sm-flex align-items-center justify-content-between`}
                >
                  <div className={`${styles.inputWrapper} mt-3`}>
                    <input
                      type="text"
                      placeholder="Altadena, CA, 910832, USA"
                      className={`${styles.inputField}`}
                    />
                    <img
                      src={IMAGES.LocationIcon}
                      className={`${styles.locationIcon}`}
                      alt="location"
                    />
                    <img
                      src={IMAGES.success}
                      alt="Success Icon"
                      className={`${styles.successIcon}`}
                    />
                  </div>
                  <div className="mt-3">
                    <CustomButton
                      variant="primary"
                      label="At Home"
                      additionalClass={styles.customBtn}
                    />
                  </div>
                </div>
                <div className={`${styles.SelectDateWrapper} row`}>
                  <div className="col-md-3 pe-md-0">
                    <div
                      className={`${styles.SelectDateLeft} mb-3 mb-md-0 appointment-location-field pateintt-date-dropdown patient-dropDown-wrapper`}
                    >
                      <span className={`${styles.selectDateText} dat-label`}>
                        Selected Month
                      </span>
                      <InputFields
                        type="dropDown"
                        placeholder="September"
                        componentOptions={{
                          DropdownIndicator: (props) => (
                            <img
                              {...props}
                              className={styles.customDropdownIcon}
                              src={IMAGES.downArrowIcon}
                              alt="drop"
                            />
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className={`${styles.SelectDateRight} `}>
                      <DateCalenderSlider />
                    </div>
                  </div>
                </div>
                <div className={`${styles.LocationTimeWrapper}`}>
                  <div className={` row`}>
                    <div className={`col-lg-12 col-xl-8 col-md-12`}>
                      <div className={`${styles.Lefside}`}>
                        <div className={`${styles.LocationTimeLeft}`}>
                          <div
                            className={`${styles.TopMap} d-flex justify-content-between`}
                          >
                            <p className={`${styles.MilesText}`}>
                              <FaLocationDot /> 7.16 miles{" "}
                            </p>
                            <Link
                              className={`${styles.locationText}`}
                              to={"/add-address"}
                            >
                              View location details
                            </Link>
                          </div>
                          <div className={`${styles.clinicAddressswrapper}`}>
                            <h4 className={`${styles.subHeading}`}>
                              Quest Diagnostics - Agoura Hills Canwoo - Employer
                              Drug Testing Not Offered
                            </h4>

                            <div className={`${styles.ContentBox}`}>
                              <p className={`${styles.SubText}`}>
                                2039 Glenrose Ave
                              </p>
                              <p className={`${styles.SubText}`}>Ste 101</p>
                              <p className={`${styles.SubText}`}>
                                Altadena, CA 91301-9876
                              </p>
                            </div>
                            <div className={`${styles.PhoneFax} `}>
                              <p className={`${styles.SubText}`}>
                                <img
                                  src={IMAGES.PhoneIcon}
                                  alt="Fax"
                                  className={`${styles.PhoneFaxIcon} pe-1`}
                                />{" "}
                                (909) 469-6718
                              </p>
                              <p className={`${styles.SubText}`}>
                                <img
                                  src={IMAGES.FaxIcon}
                                  className={`${styles.PhoneFaxIcon} pe-1`}
                                  alt="Fax"
                                />{" "}
                                Fax: (818) 123-9876
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={` col-lg-12 col-xl-4 col-md-12 ps-0`}>
                      <div className={`${styles.rightside}`}>
                        <div className={`${styles.TimeRightwrapper}`}>
                          <p className={`${styles.selectTimeText}`}>
                            Select Available Time Slot
                          </p>
                          <div className={`${styles.TimeSlot} `}>
                            <div className="p-1">
                              <CustomRadioButton
                                label="09-09 AM"
                                checked
                                name="advantagePlan"
                                id="09-09"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                            <div className="p-1">
                              <CustomRadioButton
                                label="10-11 AM"
                                checked
                                name="advantagePlan"
                                id="10-11"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                            <div className="p-1">
                              <CustomRadioButton
                                label="02-03 PM"
                                checked
                                name="advantagePlan"
                                id="02-03"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                            <div className="p-1">
                              <CustomRadioButton
                                label="10-14 AM"
                                checked
                                name="advantagePlan"
                                id="10-14"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                            <div className="p-1">
                              <CustomRadioButton
                                label="5:40-6:40 PM"
                                checked
                                name="advantagePlan"
                                id="5:40-6:40"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`${styles.LocationTimeWrapper}`}>
                  <div className={`row`}>
                    <div className={` col-md-12 col-lg-12 col-xl-8`}>
                      <div className={`${styles.Lefside}`}>
                        <div className={`${styles.LocationTimeLeft}`}>
                          <div
                            className={`${styles.TopMap} d-flex justify-content-between`}
                          >
                            <p className={`${styles.MilesText}`}>
                              <FaLocationDot /> 7.16 miles{" "}
                            </p>
                            <Link
                              className={`${styles.locationText}`}
                              to={"/add-address"}
                            >
                              View location details
                            </Link>
                          </div>
                          <div className={`${styles.clinicAddressswrapper}`}>
                            <h4 className={`${styles.subHeading}`}>
                              Quest Diagnostics - Agoura Hills Canwoo - Employer
                              <br></br>
                              Drug Testing Not Offered
                            </h4>

                            <div className={`${styles.ContentBox}`}>
                              <p className={`${styles.SubText}`}>
                                2039 Glenrose Ave
                              </p>
                              <p className={`${styles.SubText}`}>Ste 101</p>
                              <p className={`${styles.SubText}`}>
                                Altadena, CA 91301-9876
                              </p>
                            </div>
                            <div className={`${styles.PhoneFax} `}>
                              <p className={`${styles.SubText}`}>
                                <img
                                  src={IMAGES.PhoneIcon}
                                  alt="Fax"
                                  className={`${styles.PhoneFaxIcon} pe-1`}
                                />{" "}
                                (909) 469-6718
                              </p>
                              <p className={`${styles.SubText}`}>
                                <img
                                  src={IMAGES.FaxIcon}
                                  className={`${styles.PhoneFaxIcon} pe-1`}
                                  alt="Fax"
                                />{" "}
                                Fax: (818) 123-9876
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={` col-md-12 col-lg-12 col-xl-4 ps-0`}>
                      <div className={`${styles.rightside}`}>
                        <div className={`${styles.TimeRightwrapper}`}>
                          <p className={`${styles.selectTimeText}`}>
                            Select Available Time Slot
                          </p>
                          <div className={`${styles.TimeSlot} `}>
                            <div className="p-1">
                              <CustomRadioButton
                                label="09-09 AM"
                                checked
                                name="advantagePlans"
                                id="09-9"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                            <div className="p-1">
                              <CustomRadioButton
                                label="10-11 AM"
                                checked
                                name="advantagePlans"
                                id="10-1"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                            <div className="p-1">
                              <CustomRadioButton
                                label="02-03 PM"
                                checked
                                name="advantagePlans"
                                id="02-0"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                            <div className="p-1">
                              <CustomRadioButton
                                label="10-14 AM"
                                checked
                                name="advantagePlans"
                                id="10-188"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                            <div className="p-1">
                              <CustomRadioButton
                                label="5:40-6:40 PM"
                                checked
                                name="advantagePlans"
                                id="5:40:40"
                                handleChange={(e) => handleChangeRadio(e)}
                                radiobtnLg
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-3">
              <RightSideBar
                progressBar
                currentStep={3}
                value="162.85"
                phoneNumber="(909) 718-4592"
                faxNumber="(909) 469-6718"
                email="billing@biolabaratory.net"
                time="8:00 AM - 5:00 PM PST-M-F"
              />
            </div>
          </div>
          <div className="d-block d-sm-flex align-items-center justify-content-between pb-2 pt-4 pe-0 pe-sm-4">
            <CustomButton
              label="Back"
              handleClick={() => navigateTo("/add-address")}
              additionalClass={styles.BtnDraft}
              variant="darkGray"
            />
            <div className="d-block d-sm-flex align-items-center justify-content-end column-gap-2 w-100 w-sm-75">
              <CustomButton
                label="Next"
                handleClick={() => setShowModal(true)}
                additionalClass={styles.btnNext}
                variant="primary"
              />
            </div>
          </div>
        </div>

        {/* <div>PaymentHistory</div> */}
      </div>
      {showModal && (
        <>
          <CustomModal
            showModal={showModal}
            handleClose={() => setShowModal(false)} // Close modal
            title="Book an At Home Appointment"
            label="Submit"
            size="xl"
          >
            <div className={styles.modalBodyContent}>
              <h5>Enter Date and time that works for you</h5>
              <div
                className={`${styles.SelectDateWrapper} selectDatePickerModal row`}
              >
                <div className="col-md-3 pe-md-0">
                  <div
                    className={`${styles.SelectDateLeft} mb-3 mb-md-0 pateintt-date-dropdown patient-dropDown-wrapper`}
                  >
                    <span className={`${styles.selectDateText} dat-label`}>
                      Selected Month
                    </span>
                    <InputFields
                      type="dropDown"
                      placeholder="September"
                      componentOptions={{
                        DropdownIndicator: (props) => (
                          <img
                            {...props}
                            className={styles.customDropdownIcon}
                            src={IMAGES.downArrowIcon}
                            alt="drop"
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className={`${styles.SelectDateRight} `}>
                    <DateCalenderSlider slideToShow="modal" />
                  </div>
                </div>
              </div>
              <div className={styles.timeSlotsBgColor}>
                <h6 className="mb-4">Select Available Time Slot</h6>
                <div
                  className={`d-flex flex-wrap ${styles.dateTimeSlotsWrapper}`}
                >
                  {timeSlots.map((time, index) => (
                    <div key={index}>
                      <CustomRadioButton
                        label={time.label}
                        name="timeSlot"
                        id={time.id}
                        // checked={time.checked}
                        handleChange={(index) => console.log(index)}
                        radiobtnLg
                      />
                    </div>
                  ))}
                </div>
              </div>
              <CustomButton
                additionalClass={styles.submitBtn}
                label="Submit"
                roundedSm
                variant="Primary"
                handleClick={() => {
                  setShowModal(false); // Close modal
                  history("/personal-insurance-details"); // Navigate to the desired route
                }}
              />
            </div>
          </CustomModal>
        </>
      )}
    </>
  );
};

export default AppointmentLocationTime;
