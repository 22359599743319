import editicon from "assets/images/Edit-icon.png";
import filterIcon from "assets/images/Filter-icon.svg";
import Loginscreen from "assets/images/Login-page.png";
import Logo from "assets/images/Logo.png";
import visa from "assets/images/VISA-icon.png";
import addicon from "assets/images/add-icon.png";
import appointmentsIcon from "assets/images/appointments.svg";
import eyeIcon from "assets/images/aye-table.png";
import CalenderIcon from "assets/images/calendar-icon.svg";
import clickIcon from "assets/images/click.png";
import companyicon from "assets/images/company-icon.png";
import cvcIcon from "assets/images/cvc-icon.png";
import DarkHomeIcon from "assets/images/dark-home.png";
import dotsIcon from "assets/images/dots.png";
import downArrowIcon from "assets/images/down-arrow.svg";
import statusEye from "assets/images/eye-icon.svg";
import FaxIcon from "assets/images/fax.png";
import fileInvoice from "assets/images/file-invoice.svg";
import HomeIcon from "assets/images/home-icon.png";
import applepayicon from "assets/images/icon-apple-pay.png";
import banktransfericonactive from "assets/images/icon-bank-transfer-active.png";
import banktransfericoninactive from "assets/images/icon-bank-transfer.png";
import gpayiconinactive from "assets/images/icon-g-pay.png";
import gpayiconactive from "assets/images/icon-gpay-active.png";
import paypaliconactive from "assets/images/icon-paypal-active.png";
import paypaliconinactive from "assets/images/icon-paypal.png";
import venmoicononactive from "assets/images/icon-venmo-active.png";
import venmoiconinactive from "assets/images/icon-venmo.png";
import visaiconinactive from "assets/images/icon-visa-inactive.png";
import zelleiconactive from "assets/images/icon-zelle-active.png";
import zelleiconinactive from "assets/images/icon-zelle.png";
import ideaIcon from "assets/images/idea-icon.png";
import ImgPlaceHolder from "assets/images/image-placeholder.svg";
import inActiveEyeIcon from "assets/images/in-active-eye-icon.svg";
import infoIcon from "assets/images/info-icon.svg";
import language from "assets/images/language.png";
import LeftArrowSlider from "assets/images/left-arrow-slide.svg";
import Litelocationicon from "assets/images/location-icon-lite.png";
import locationicon from "assets/images/location-logo.png";
import LocationIcon from "assets/images/location.png";
import logoFIlled from "assets/images/medflow-logo.svg";
import notificationIcon from "assets/images/notifications.svg";
import openFUllImage from "assets/images/open-full-image.png";
import paymentHistoryIcon from "assets/images/payment-history.svg";
import pdfTh from "assets/images/pdf-ht.png";
import PhoneIcon from "assets/images/phone-icon.png";
import printIcon from "assets/images/print-icon.svg";
import ProfileImage from "assets/images/profileImage.png";
import radiobuttonactive from "assets/images/radiobuttonactive.png";
import radiobuttoninactive from "assets/images/radiobuttoninactive.png";
import rateIcon from "assets/images/rate-table.png";
import RightArrowSlider from "assets/images/right-arrow-slide.svg";
import searchbarIcon from "assets/images/search-bar-icon.png";
import settingIcon from "assets/images/settings-icon.png";
import successstatusactiveicon from "assets/images/success-status-icon-new.png";
import success from "assets/images/success-status-icon.png";
import fileTestResuts from "assets/images/test-results-file.svg";
import {
  default as successstatusinactiveicon,
  default as tickIcon,
} from "assets/images/tick-circle.png";
import upArrowIcon from "assets/images/up-arrow.svg";
import updateInfoIcon from "assets/images/update-information.svg";
import UploadedImg from "assets/images/uploaded-image.png";
import userPlaceholder from "assets/images/userPlaceholder.png";

export const IMAGES = {
  Loginscreen: Loginscreen,
  Logo: Logo,
  logoFIlled,
  PhoneIcon,
  paymentHistoryIcon,
  notificationIcon,
  appointmentsIcon,
  updateInfoIcon,
  language,
  settingIcon,
  eyeIcon,
  rateIcon,
  clickIcon,
  radiobuttonactive,
  radiobuttoninactive,
  pdfTh,
  visa,
  editicon,
  success,
  addicon,
  companyicon,
  tickIcon,
  zelleiconinactive,
  banktransfericoninactive,
  gpayiconinactive,
  venmoiconinactive,
  paypaliconinactive,
  applepayicon,
  zelleiconactive,
  banktransfericonactive,
  gpayiconactive,
  venmoicononactive,
  paypaliconactive,
  visaiconinactive,
  ideaIcon,
  locationicon,
  successstatusinactiveicon,
  successstatusactiveicon,
  ProfileImage,
  searchbarIcon,
  filterIcon,
  infoIcon,
  printIcon,
  LocationIcon,
  RightArrowSlider,
  LeftArrowSlider,
  upArrowIcon,
  downArrowIcon,
  ImgPlaceHolder,
  UploadedImg,
  FaxIcon,
  Litelocationicon,
  HomeIcon,
  CalenderIcon,
  openFUllImage,
  cvcIcon,
  DarkHomeIcon,
  dotsIcon,
  statusEye,
  fileInvoice,
  fileTestResuts,
  inActiveEyeIcon,
  userPlaceholder,
};
