import { combineReducers } from "@reduxjs/toolkit";
import user from "./duck/userDuck";
import patient from "./duck/patientDuck";
import auth from "./duck/authDuck";
import getTests from "./duck/getTestsDuck";
import getAllInvoices from "./duck/invoicesDuck";
import appointment from "./duck/appointmentDuck";

export const RESET_ALL_STATES = "RESET_ALL_STATES";

const rootReducers = combineReducers({
  user,
  auth,
  getTests,
  getAllInvoices,
  patient,
  appointment,
});

export const rootResetReducer = (state, action) => {
  if (action.type === RESET_ALL_STATES) {
    return rootReducers(undefined, action);
  }
  return rootReducers(state, action);
};

export default rootReducers;
