import {
  autoSignIn,
  confirmResetPassword,
  fetchAuthSession,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  signUp,
  deleteUser,
  fetchUserAttributes,
} from "@aws-amplify/auth";

import {
  API_METHODS,
  callAmplifyAPI,
  callAmplifyPublicAPI,
} from "api/defaultApi"
import {
  changePasswordApi,
  forgotPasswordApi,
} from "api/auth";
import { generateClient } from "aws-amplify/api";
import { createEmployee, updateEmployee } from "graphql/mutations";
import * as queries from "graphql/queries";
import { getCountryCodeAndPhone } from "utils/utils";
import { faPersonWalkingDashedLineArrowRight } from "@fortawesome/free-solid-svg-icons";
import { store } from "redux/store";
import { CONFIG } from "utils/helper/Constants";
// import { graphqlOperation } from "aws-amplify";
const client = generateClient();

// signup api
const signUpApi = async (data) => {
  const payloadData = data.payload;

  console.log("payloadData =>", payloadData);

  try {
    const { patientID } = store.getState().patient;
    // Prepare attribute payload for user sign-up
    const attributePayload = {
      preferred_username: payloadData.phone,
      email: payloadData.email,
      "custom:dob": payloadData.dob,
      "custom:role": "Employees",
      "custom:autoConfirm": "true",
      "custom:is2FaEnabled": "y",
      "custom:privilege": `${CONFIG.isLive?"dev":"test"}`,
      "custom:firstName": `${payloadData.firstName}`,
      "custom:lastName": `${payloadData.lastName}`,
      "custom:note": `${payloadData.firstName} ${payloadData.lastName}`,
    };

    console.log("signup Payload =>", attributePayload);

    // Sign out the current user (if any)
    await signOut();

    // Sign up the new user
    const signUpRes = await signUp({
      username: payloadData.phone,
      password: payloadData.password,
      options: {
        userAttributes: attributePayload,
        autoSignIn: true,
      },
    });

    // Automatically sign in the user
    await autoSignIn();

    // Get the current user details
    const currentUser = await getCurrentUser();
    // Parse phone number to get country code and phone
    const phone = getCountryCodeAndPhone(payloadData.phone);

    // Prepare data for new patient
    const newPatient = {
      first: payloadData.firstName,
      last: payloadData.lastName,
      dob: payloadData.dob,
      phone_number: phone,
      email: payloadData.email,
      subID: currentUser.userId,
    };

    // Search for employees based on phone number
    let response;
    if (patientID) {
      response = await getEmployeebyphone({
        phone_number: patientID,
        searchBy: "id",
      });
    } else {
      response = await getEmployeebyphone({ phone_number: phone });
    }

    // const searchEmployeeData = response && response?.data[0];

    // If response exists and there are data objects
    if (response && response.data.length > 0) {
      // Loop through each employee found
      let employee = null;
      let attributesMatch = false;
      for (const emp of response.data) {
        console.log(
          "old employee data =>",
          emp.id,
          currentUser.userId,
          emp.first,
          emp.last,
          emp.dob,
          emp.email
        );

        console.log(
          "new employee data =>",
          newPatient.first,
          newPatient.last,
          newPatient.dob,
          newPatient.email
        );

        attributesMatch =
          emp.first.trim().toLocaleLowerCase() ===
            newPatient.first.trim().toLocaleLowerCase() &&
          emp.last.trim().toLocaleLowerCase() ===
            newPatient.last.trim().toLocaleLowerCase() &&
          // emp.dob.toLowerCase() === newPatient.dob.toLowerCase() &&
          emp.email.trim().toLocaleLowerCase() ===
            newPatient.email.trim().toLocaleLowerCase();

        if (attributesMatch) {
          employee = emp;
          break;
        }
      }

      console.log("employee data =>", employee);
      // Compare attributes of the new patient with the employee

      if (attributesMatch) {
        // const updateoldPatientsub = {
        //   _version: employee.mdVersion,
        //   id: employee.id,
        //   subID: currentUser.userId,
        // };
        // Update employee if attributes match
        // console.log("updateoldPatientsub : ", updateoldPatientsub);

        const updatedSearchEmployeeData = {
          ...newPatient,
          ...payloadData,
          ...employee,
          id: employee.id,
          subID: currentUser.userId,
        };

        const proxyRes = await proxyContentAPI({
          employee: updatedSearchEmployeeData,
        });

        console.log("proxy response =>", proxyRes);
        await signOut();
        const finalResponse = attributesMatch ? proxyRes : false;

        return proxyRes;
      } else {
        return "Your record is not found";
      }
    } else {
      return "Your record is not found";
    }
  } catch (err) {
    return err.message;
  }
};

// login api
const loginApi = async (params) => {
  const payloadData = params.payload;

  try {
    if (payloadData?.accountNo) {
      try {
        const accNumSearchRes = await getEmployeebyAccountNum({
          phone_number: payloadData?.accountNo,
          searchBy: "accNo",
        });

        const searchResult = accNumSearchRes && accNumSearchRes?.data[0];
        console.log("searchResult =>", searchResult);
        const { phone_number, countryCode } = searchResult;
        const userPhone = countryCode
          ? countryCode + phone_number
          : "+1" + phone_number;

        await signOut();
        const loginresponse = await signIn({
          username: userPhone,
          password: payloadData.password,
        });

        const userAttributes = await fetchUserAttributes();
        const loginResStatus = !!loginresponse?.isSignedIn
          ? searchResult
          : null;
        const resultObject = {
          loginStatus: loginResStatus,
          userAttributes: userAttributes,
        };
        return resultObject;
      } catch (error) {
        console.log("error signing in", error);
        return error.message;
      }
    }
    if (payloadData?.phone) {
      await signOut();

      try {
        const loginresponse = await signIn({
          username: payloadData.phone,
          password: payloadData.password,
        });

        const userAttributes = await fetchUserAttributes();

        let loginResStatus = null;
        let resultObject = null;

        if (userAttributes?.["custom:is2FaEnabled"] === "n") {
          // search by-subID
          const currentUser = await getCurrentUser();
          const subID = currentUser && currentUser?.userId;
          const subIDSearchRes = await getEmployeebyphone({
            phone_number: subID,
            clientID: process.env.REACT_APP_CLIENT_ID,
            searchBy: "subID",
          });

          const emplyeeByPhone = subIDSearchRes && subIDSearchRes?.data[0];
          let searchedEmployees = [];

          // search by-phone
          if (emplyeeByPhone) {
            searchedEmployees = [emplyeeByPhone];
            console.log("search subID emplyee =>", searchedEmployees);
          } else {
            const phone = getCountryCodeAndPhone(payloadData.phone);
            const phoneNumSearchRes = await getEmployeebyphone({
              phone_number: phone,
            });
            searchedEmployees = phoneNumSearchRes && phoneNumSearchRes?.data;
            console.log("search phone emplyee =>", searchedEmployees);
          }

          // // fetch user attributes
          const currentUserData = {
            first: userAttributes?.["custom:firstName"],
            last: userAttributes?.["custom:lastName"],
            dob: userAttributes?.["custom:dob"],
            phone: userAttributes?.phone_number,
            email: userAttributes?.email,
            subID: userAttributes?.sub,
          };

          // matching the records
          let proxyRes = null;
          for (const item of searchedEmployees) {
            // const attributesMatch =
            //   item?.first.toLowerCase() ===
            //     currentUserData?.first.toLowerCase() &&
            //   item?.last.toLowerCase() ===
            //     currentUserData?.last.toLowerCase() &&
            //   item?.dob.toLowerCase() === currentUserData?.dob.toLowerCase() &&
            //   item?.email.toLowerCase() ===
            //     currentUserData?.email.toLowerCase();

            const attributesMatch =
              item?.first.trim().toLowerCase() ===
                currentUserData?.first?.trim().toLowerCase() &&
              item?.last.trim().toLowerCase() ===
                currentUserData?.last?.trim().toLowerCase() &&
              // item?.dob.trim().toLowerCase() ===
              //   currentUserData?.dob?.trim().toLowerCase() &&
              item?.email.trim().toLowerCase() ===
                currentUserData?.email?.trim().toLowerCase();

            console.log("attributesMatch ==>", attributesMatch);

            if (attributesMatch) {
              const updatedSearchEmployeeData = {
                ...item,
                id: item?.id,
                subID: currentUserData?.subID,
              };

              proxyRes = await proxyContentAPI({
                employee: updatedSearchEmployeeData,
              });

              console.log("proxy response =>", proxyRes);
              // await signOut();
              break;
            }
          }
          loginResStatus =
            !!loginresponse?.isSignedIn && emplyeeByPhone
              ? emplyeeByPhone
              : proxyRes?.data;
        }

        // return final-response

        resultObject = {
          loginStatus: loginResStatus,
          userAttributes: userAttributes,
        };

        console.log("resultObject =>", resultObject);

        return resultObject ? resultObject : null;

        // return userAttributes ? userAttributes : null;
      } catch (error) {
        console.log("api error ==>", error.message);
        return error.message;
      }
    }
  } catch (err) {
    return err.message;
  }
};

// forgot-password api
const forgotpasswordApi = async (params) => {
  const payloadData = params;
  try {
    // Reset the password
    // const response = await resetPassword({ username: payloadData.phone }); // Pass the username/email of the user
    const response = await forgotPasswordApi({ phone: payloadData.phone });
    console.log("response", response);
    // If successful, return a success message or handle it as needed
    return response;
  } catch (err) {
    console.error("Error resetting password:", err.message);
    return err.message;
  }
};

// change-password api
const changepasswordApi = async (params) => {
  const payloadData = params;
  try {
    // Reset the password
    // const response = await confirmResetPassword({
    //   username: params.forgotData,
    //   confirmationCode: params.otp,
    //   newPassword: params.password,
    // }); // Pass the username/email of the user
    await changePasswordApi({ phone: payloadData.forgotData, code: payloadData.otp, password: payloadData.password });
    // If successful, return a success message or handle it as needed
    return "Password reset successful";
  } catch (err) {
    console.error("Error resetting password:", err.message);
    return err.message;
  }
};

// logout api
const logoutApi = async () => {
  try {
    await signOut();
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("persist:root");
    return "Logout successful";
  } catch (err) {
    console.error("Error logout", err.message);
    return err.message;
  }
};

// verify-OTP api
const verifyOTPApi = async (params) => {
  console.log("api params =>", params);
  const { payloadPhone, ...OTP } = params.payload;
  console.log("OTP =>", OTP);
  // const phoneNum = params?.payload?.payloadPhone;

  try {
    const response = await verifyOTPNumber(OTP);
    console.log("response otp =>", response);

    const userAttributes = await fetchUserAttributes();

    console.log("userAttributes otp ==>", userAttributes);

    let loginResStatus = null;
    let resultObject = null;

    if (userAttributes?.["custom:is2FaEnabled"] === "y") {
      // search by-subID
      const currentUser = await getCurrentUser();
      const subID = currentUser && currentUser?.userId;
      const subIDSearchRes = await getEmployeebyphone({
        phone_number: subID,
        searchBy: "subID",
      });

      const emplyeeByPhone = subIDSearchRes && subIDSearchRes?.data[0];
      let searchedEmployees = [];

      // search by-phone
      if (emplyeeByPhone) {
        searchedEmployees = [emplyeeByPhone];
        console.log("search subID emplyee =>", searchedEmployees);
      } else {
        const phone = getCountryCodeAndPhone(payloadPhone);
        const phoneNumSearchRes = await getEmployeebyphone({
          phone_number: phone,
        });
        searchedEmployees = phoneNumSearchRes && phoneNumSearchRes?.data;
        console.log("search phone emplyee =>", searchedEmployees);
      }

      // // fetch user attributes
      const currentUserData = {
        first: userAttributes?.["custom:firstName"],
        last: userAttributes?.["custom:lastName"],
        dob: userAttributes?.["custom:dob"],
        phone: userAttributes?.phone_number,
        email: userAttributes?.email,
        subID: userAttributes?.sub,
      };

      console.log("current user =>", currentUserData);

      // matching the records
      let proxyRes = null;
      for (const item of searchedEmployees) {
        console.log("matching the records ==>", searchedEmployees);

        console.log("dob matching =>", item?.dob, currentUserData?.dob);

        const attributesMatch =
          item?.first.trim().toLowerCase() ===
            currentUserData?.first?.trim().toLowerCase() &&
          item?.last.trim().toLowerCase() ===
            currentUserData?.last?.trim().toLowerCase() &&
          // item?.dob.trim().toLowerCase() ===
          //   currentUserData?.dob?.trim().toLowerCase() &&
          item?.email.trim().toLowerCase() ===
            currentUserData?.email?.trim().toLowerCase();

        console.log("attributesMatch ==>", attributesMatch);

        if (attributesMatch) {
          const updatedSearchEmployeeData = {
            ...item,
            id: item?.id,
            subID: currentUserData?.subID,
          };

          proxyRes = await proxyContentAPI({
            employee: updatedSearchEmployeeData,
          });

          console.log("proxy response =>", proxyRes);
          // await signOut();
          break;
        }
      }

      loginResStatus = emplyeeByPhone ? emplyeeByPhone : proxyRes?.data;
    }

    resultObject = {
      loginStatus: loginResStatus,
      userAttributes: userAttributes,
      otpResponse: response,
    };

    console.log("resultObject =>", resultObject);
    if (loginResStatus && userAttributes && response) {
      return resultObject;
    } else {
      return null;
    }
  } catch (err) {
    console.log("otp error ===>", err.message);
    return err.message;
  }
};

// resend OTP
const resendOTPApi = async () => {
  try {
    const response = await resendOTPNumber();
    return response;
  } catch (err) {
    return err.message;
  }
};

// restfull api calls
const verifyOTPNumber = async (params) => {
  const models = await callAmplifyAPI("/verifyOtp", params, API_METHODS.post);
  return models;
};
// const addEmployeeRoles = async (params) => {
//   const models = await callAmplifyAPI(
//     "/addEmployeeRoles",
//     params,
//     API_METHODS.post
//   );
//   return models;
// };
const resendOTPNumber = async (params) => {
  const models = await callAmplifyAPI("/reSendOtp", params, API_METHODS.post);
  return models;
};
const getEmployeebyphone = async (params) => {
  console.log("🚀 ~ getEmployeebyphone ~ params:", params);
  const models = await callAmplifyPublicAPI(
    "/patient/search",
    params,
    API_METHODS.post
  );
  return models;
};
const getEmployeebyAccountNum = async (params) => {
  const models = await callAmplifyPublicAPI(
    "/patient/search",
    params,
    API_METHODS.post
  );
  return models;
};
const proxyContentAPI = async (params) => {
  const models = await callAmplifyAPI(
    "/proxyContant",
    params,
    API_METHODS.post
  );
  return models;
};
const getTestByEmployeeAPI = async (params) => {
  const models = await callAmplifyAPI(
    "/getTestFromPG",
    params,
    API_METHODS.post
  );
  return models;
};
const getTestRecordByID = async (params) => {
  const models = await callAmplifyAPI(
    "/getRecordById",
    params,
    API_METHODS.post
  );
  return models;
};

// this is the way to call graphql quiries
const getInvoicesList = async (params) => {
  const models = await callAmplifyAPI(
    "/patient/invoices/list",
    params,
    API_METHODS.post
  );
  return models;
};
const getFilePath = async (params) => {
  const models = await callAmplifyAPI("/getURL", params, API_METHODS.post);
  return models;
};
// const getClientById = async (id) => {
//   const client = await DataStore.query(Client, (client) => client.id("eq", id));
//   return client[0];
// };

export default {
  forgotpasswordApi,
  changepasswordApi,
  signUpApi,
  loginApi,
  getTestByEmployeeAPI,
  getTestRecordByID,
  getInvoicesList,
  getFilePath,
  logoutApi,
  verifyOTPApi,
  resendOTPApi,
  getEmployeebyphone,
  // getClientById,
};
