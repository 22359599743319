import PanelLayout from "components/layout";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { authRoutes } from "./routesPath/authRoutes";
import { patientRoutes } from "./routesPath/patientRoutes";
import WelcomeScreen from "modules/patientsPortal/welcomeScreen/WelcomeScreen";

const Approutes = () => {
  const { authenticated, loginData } = useSelector(({ auth }) => ({
    authenticated: auth?.authenticated,
    loginData: auth?.loginData,
  }));

  // console.log("authenticated ==>", authenticated, loginData);

  return (
    <Router>
      <Routes>
        {/* Redirect to result page if authenticated */}
        {authenticated && (
          <Route path="*" element={<Navigate to="/results" replace />} />
        )}

        {/* Render patient routes only if authenticated */}
        {authenticated &&
          patientRoutes.map((route, i) => (
            <Route
              key={i}
              exact
              path={route.path}
              element={
                <PanelLayout>
                  <route.component />
                </PanelLayout>
              }
            />
          ))}

        {/* Render auth routes only if not authenticated */}
        {!authenticated &&
          authRoutes.map((route, i) => (
            <Route
              key={i}
              exact
              path={route.path}
              element={<route.component />}
            />
          ))}

        {/* Redirect to login if not authenticated */}
        {!authenticated && (
          <>
            <Route path="/:id" element={<WelcomeScreen />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        )}

        {/* Define the result page route */}
        <Route path="/result" />
      </Routes>
    </Router>
  );
};
export default Approutes;
