import PatientApi from "api/PatientApi";
import CustomModal from "components/Common/CustomModal/CustomModal";
import InputFields from "components/Common/Inputfileds/InputFields";
import Loader from "components/Common/Loader/Loader";
import Navbar from "components/Common/Navbar/Navbar";
import PdfViewer from "components/Common/PDF/PdfViewer";
import MainTable from "components/Common/Table/MainTable";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { invoicesListingRequest } from "redux/reducers/duck/invoicesDuck";
import styles from "style/ViewResults.module.css";
import { TABLE_COLUMN_TYPES } from "utils/helper/Constants";
import { IMAGES } from "utils/helper/Images";

const ViewInvoice = () => {
  const [allInvoices, setAllInvoices] = useState([]);
  const [searchedItem, setSearchedItem] = useState("");
  const [pdfPath, setPdfPath] = useState();
  const originalData = useSelector(
    (state) => state.getAllInvoices.filteredTests
  );

  const isLoading = useSelector((state) => state.getAllInvoices.loading);
  const selectedTest = useSelector(
    (state) => state.getAllInvoices.selectedTest
  );

  const [isShowPDF, setIsShowPDF] = useState(false);

  const dispatch = useDispatch();

  const getUser = useSelector((state) => state.auth.loginData);
  const getEmployeeId = getUser.loginStatus.id;

  // const getClientId = getUser.clientID;

  // const test_env = process.env.REACT_APP_VARIABLE_TEST;
  // const live_env = process.env.REACT_APP_VARIABLE_LIVE;

  const tableColumns = [
    {
      itemKey: "invoiceDate",
      title: "Date",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 1,
      width: "100%",
      type: TABLE_COLUMN_TYPES.shortDate,
      optionalValue: "N/A",
    },
    {
      itemKey: "invoiceNo",
      title: "Invoice Number",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 2,
      width: "100%",
      type: "text",
    },

    {
      itemKey: "invoiceAmount",
      title: "Amount",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 3,
      width: "100%",
      type: TABLE_COLUMN_TYPES.currency,
    },
    {
      itemKey: "discount",
      title: "Discount",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 4,
      width: "100%",
      cssClass: "text-uppercase",
      type: TABLE_COLUMN_TYPES.currency,
      // customCellRenderer: true,
    },

    {
      itemKey: "status",
      title: "Status",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 5,
      width: "100%",
      type: "text",
      customCellRenderer: true,
    },
    {
      itemKey: "view",
      title: "View Invoice",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 6,
      width: "100%",
      type: "text",
      customCellRenderer: true,
    },
  ];

  useEffect(() => {
    dispatch(
      invoicesListingRequest({
        // employeeID: "d8144932-80d4-401f-a0e6-6dabf837a826",
        employeeID: getEmployeeId,
        clientID: process.env.REACT_APP_CLIENT_ID,
      })
    );
  }, []);

  useEffect(() => {
    setAllInvoices(originalData);
  }, [originalData]);

  const handleSearch = (e) => {
    const searchedValue = e.target.value.toLowerCase();
    setSearchedItem(searchedValue);
    if (searchedValue === "") {
      setSearchedItem("");
      setAllInvoices(originalData);
      return;
    }
    const filteredInvoices = originalData.filter((item) => {
      return (
        (item.invoiceNo && item.invoiceNo.toString().includes(searchedValue)) ||
        (item.invoiceAmount &&
          item.invoiceAmount.toString().includes(searchedValue)) ||
        (item.discount && item.discount.toString().includes(searchedValue)) ||
        (item.status && item.status.includes(searchedValue))
      );
    });
    setAllInvoices(filteredInvoices);
  };

  const handleShowPdf = async (item) => {
    console.log("here is the invoice item : ", item)
    setIsShowPDF(!isShowPDF);
    try {
      const path =
        `invoices/${process.env.REACT_APP_VARIABLE_SET_ENV}/` +
        item.invoiceNo +
        ".pdf";
      const response = await PatientApi.getFilePath({ path: path, clientID: item.clientID });
      setPdfPath(response.url);
    } catch (error) {
      console.log("error", error);
    }
  };

  const customColumnCellRenderer = (column, row) => {
    if (column.itemKey === "status") {
      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: column.textAlign,
            textOverflow: column.textOverflow,
          }}
        >
          <label
            className={`text-capitalize ${
              row.status.toLowerCase() === "paid"
                ? "text-success"
                : row.status.toLowerCase() === "pending" ||
                  row.status.toLowerCase() === "sent"
                ? "text-warning"
                : "text-black"
            }`}
          >
            {`${row.status === "sent" ? "Pending" : row.status}`}
          </label>
        </td>
      );
    }

    if (column.itemKey === "view") {
      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: column.textAlign,
            textOverflow: column.textOverflow,
          }}
        >
          <div
            className={
              row.status.toLowerCase() === "pending"
                ? "pointer-events-none"
                : "cursor-pointer"
            }
            onClick={() => handleShowPdf(row)}
          >
            <img
              style={{
                width: "25px",
              }}
              src={
                row.status.toLowerCase() === "pending"
                  ? IMAGES.inActiveEyeIcon
                  : IMAGES.eyeIcon
              }
              alt="invoices"
            />
          </div>
        </td>
      );
    }
  };

  const customCssClass = "Patient-history-table";

  return (
    <div className={`w-100 ${styles.mainWrapper}`}>
      <Navbar
        navlinkText="Invoices"
        navlinkText1="View Invoice"
        navlinkIcon={
          <img
            className={styles.HomeImg}
            src={IMAGES.DarkHomeIcon}
            alt="home"
          />
        }
      />
      <div className={`px-3 ${styles.pageWrapper}`}>
        <div
          className={`${styles.searchPanelWrapper} d-block d-sm-flex justify-content-between align-items-center`}
        >
          <h2 className={`${styles.pageTitle} mb-2 mb-sm-0`}>View Invoice</h2>
          <div className="d-flex align-items-center">
            <InputFields
              type="text"
              placeholder="Search"
              handleChange={(e) => handleSearch(e)}
              value={searchedItem}
              customClass="w-100"
            />
          </div>
        </div>
        <div className="mt-3">
          {allInvoices.length === 0 ? (
            <p className="text-center fs-4">No data found</p>
          ) : (
            <MainTable
              columns={tableColumns}
              rows={allInvoices}
              cssClass={customCssClass}
              customColumnCellRenderer={customColumnCellRenderer}
            />
          )}
          {isShowPDF && (
            <div>
              <CustomModal
                showModal={isShowPDF}
                handleClose={() => setIsShowPDF(false)}
                title="Invoice"
                label="Submit"
                size="xl"
                modalBodyClass
              >
                {" "}
                <Container fluid className="px-0">
                  <Row>
                    <Col md="12">
                      <Card className="strpied-tabled-with-hover">
                        <Card.Body className="pdfInvoicesWrapper table-full-width table-responsive p-0">
                          <PdfViewer url={pdfPath} />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </CustomModal>
            </div>
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default ViewInvoice;
