import PaymentDetails from "modules/patientsPortal/PaymentDetails/PaymentDetails";
import PaymentInfo from "modules/patientsPortal/PaymentInfo/PaymentInfo";
import CookiePolicy from "modules/patientsPortal/auth/CookiePolicy/CookiePolicy";
import Login from "modules/patientsPortal/auth/Login/Login";
import SecrecyPolicy from "modules/patientsPortal/auth/SecrecyPolicy/SecrecyPolicy";
import ChangePassword from "modules/patientsPortal/auth/changePassword/changePassword";
import ForgotPassword from "modules/patientsPortal/auth/forgotPassword/forgotPassword";
import SignUp from "modules/patientsPortal/auth/signUp/signUp";
import WelcomeScreen from "modules/patientsPortal/welcomeScreen/WelcomeScreen";
import PageNotFound from "components/Common/pageNotFound/PageNotFound";
import TwoFA from "modules/patientsPortal/auth/twoFactorAuth";
import SignupStatus from "modules/patientsPortal/auth/signUp/signupStatus";

const authRoutes = [
  {
    path: "/",
    component: WelcomeScreen,
  },
  {
    path: "/welcome",
    component: WelcomeScreen,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/change-password",
    component: ChangePassword,
  },
  {
    path: "/payment-info",
    component: PaymentInfo,
  },
  {
    path: "/payment-details",
    component: PaymentDetails,
  },
  {
    path: "/cookie-policy",
    component: CookiePolicy,
  },
  {
    path: "/confidentiality-policy",
    component: SecrecyPolicy,
  },
  {
    path: "/page-not-found",
    component: PageNotFound,
  },
  {
    path: "/signup-status",
    component: SignupStatus,
  },
  {
    path: "/two-factor-auth",
    component: TwoFA,
  },
];

export { authRoutes };
