import PatientApi from "api/PatientApi";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../reducers/duck/invoicesDuck";

// invoces saga
export function* invoicesSaga({ payload }) {
  try {
    const response = yield call(PatientApi.getInvoicesList, payload);
    if (typeof response === "string") {
      yield put(actions.invoicesListingFailure(response));
    } else if (typeof response === "object") {
      yield put(actions.invoicesListingSuccess(response));
    } else {
      yield put(actions.invoicesListingFailure("Invalid response"));
    }
  } catch (error) {
    console.log("error", error);
    yield put(actions.invoicesListingFailure(error.message));
  } finally {
  }
}

export function* watchInvoicesSagas() {
  yield takeLatest(actions.invoicesListingRequest, invoicesSaga);
}
