import CustomButton from "components/Common/CustomButton/CustomButton";
import Navbar from "components/Common/Navbar/Navbar";
import RightSideBar from "components/Common/RightSideBar/RightSideBar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "style/Appointments.module.css";
import { IMAGES } from "utils/helper/Images";

const Appointments = () => {
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState(1); // State to manage active tab
  const [selectedItems, setSelectedItems] = useState([]);
  const navigateTo = (route) => {
    history(route);
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const tabs = [
    { id: 1, text: "COVID-19 or COVID-19/Flu Test" },
    { id: 2, text: "COVID-19 Antibody Test" },
    { id: 3, text: "Employer Drug or Alcohol" },
    { id: 4, text: "Employer Health and Wellness" },
    { id: 5, text: "Glucose" },
    { id: 6, text: "T-SPOT,TB Test" },
    { id: 7, text: "CA Prenetal / GDSP cfDNA" },
    { id: 8, text: "All Others Test" },
    // Add more tabs as needed
  ];

  const tabContents = {
    1: "tab 1",
    2: "22222",
    3: "3",
    4: "4",
    5: "5",
    6: "5",
    7: "5",
    8: "5",
    9: "5",
  };
  const diseasesContent = [
    { name: "Urine" },
    { name: "Urine- Federally mandated" },
    { name: "Urine - Express Results Online" },
    { name: "Oral Flud" },
    { name: "Urine - Observed" },
    { name: "Breath Alcohol" },
    { name: "Hair" },
  ];

  const handleSelectItems = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  return (
    <div className={`d-flex justify-content-start ${styles.mainWrapper}`}>
      <div className={`${styles.rightmainWrapper} `}>
        <Navbar
          navlinkText="Appointments"
          navlinkText1="New Order"
          navlinkIcon={
            <img
              className={styles.HomeImg}
              src={IMAGES.DarkHomeIcon}
              alt="home"
            />
          }
        />

        <div
          className={`${styles.pageWrapper} row flex-column-reverse flex-md-row`}
        >
          <div className="col-md-8 col-lg-8 col-xl-9">
            <div className={`${styles.alertsWrpper}`}>
              <p className={`${styles.topTextHeading} ps-4`}>
                To get started please select your reason for visiting
              </p>
              <div className="row mt-4">
                <div className="col-xl-5 col-lg-6">
                  <div className="nav flex-column nav-pills">
                    {tabs.map((tab) => (
                      <div
                        key={tab.id}
                        className={`nav-link d-inline-flex align-items-center cursor-pointer ${
                          activeTab === tab.id ? styles.activeTab : ""
                        } ${styles.TabCusBtn}`}
                        onClick={() => handleTabClick(tab.id)}
                      >
                        {/* Render your icon component and text */}
                        <img
                          className={`${styles.iconTick}`}
                          src={
                            activeTab === tab.id
                              ? IMAGES.successstatusactiveicon
                              : IMAGES.tickIcon
                          }
                          alt="tick"
                        />
                        <span className="ps-2">{tab.text}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-xl-7 ps-0 col-lg-6 pe-2">
                  <div className={`${styles.tabContent} tab-content`}>
                    <p className={`${styles.topText}`}>
                      Please select all the drug and alcohol test <br></br>{" "}
                      apply to your visit.{" "}
                    </p>
                    <div className="row">
                      {diseasesContent.map((item, index) => (
                        <div className="col-12 col-xl-6 pe-0" key={index}>
                          <div
                            className={`${
                              styles.SelectionBox
                            } d-flex align-items-center ${
                              selectedItems.includes(item.name)
                                ? styles.selected
                                : ""
                            }`}
                            onClick={() => handleSelectItems(item.name)}
                          >
                            <img
                              className={`${styles.iconTick} pe-1`}
                              src={
                                selectedItems.includes(item.name)
                                  ? IMAGES.successstatusactiveicon
                                  : IMAGES.tickIcon
                              }
                              alt="tick"
                            />
                            <p
                              className={`${styles.topText} mb-0`}
                              title={item.name}
                            >
                              {item.name}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* <p>{tabContents[activeTab]}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-3">
            <RightSideBar
              progressBar
              value="162.85"
              phoneNumber="(909) 718-4592"
              faxNumber="(909) 469-6718"
              email="billing@biolabaratory.net"
              time="8:00 AM - 5:00 PM PST-M-F"
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end pb-2 pe-4">
          <CustomButton
            label="Next"
            handleClick={() => navigateTo("/add-address")}
            additionalClass={styles.BtnNext}
            variant="primary"
            rounded
          />
        </div>
      </div>
    </div>
  );
};

export default Appointments;
