import PatientApi from "api/PatientApi";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../reducers/duck/getTestsDuck";

export function* getTestsSaga({ payload }) {
  try {
    const response = yield call(PatientApi.getTestByEmployeeAPI, payload);
    if (typeof response === "string") {
      // Dispatch an action to handle login failure
      yield put(actions.testsListingFailure(response));
    } else if (typeof response === "object") {
      // Dispatch an action to handle the successful login
      yield put(actions.testsListingSuccess(response));
      //   yield put(actions.test);
    } else {
      // Handle other types of responses if needed
      yield put(
        actions.testsListingFailure(
          "Invalid response format. Please try again."
        )
      );
    }
  } catch (error) {
    console.log("error", error);
    yield put(actions.testsListingFailure(error.message));
  } finally {
  }
}

export function* testResultsSaga({ payload }) {
  try {
    const response = yield call(PatientApi.getTestRecordByID, payload);
    if (typeof response === "string") {
      // Dispatch an action to handle login failure
      yield put(actions.testResultsFailure(response));
    } else if (typeof response === "object") {
      // Dispatch an action to handle the successful login
      yield put(actions.testResultsSuccess(response));
      //   yield put(actions.test);
    } else {
      // Handle other types of responses if needed
      yield put(
        actions.testResultsFailure("Invalid response format. Please try again.")
      );
    }
  } catch (error) {
    console.log("error", error);
    yield put(actions.testResultsFailure(error.message));
  } finally {
  }
}
export function* watchGetTestsSagas() {
  yield takeLatest(actions.testsListingRequest.type, getTestsSaga);
  yield takeLatest(actions.testResultsRequest.type, testResultsSaga);
}
