// PaymentDetails.jsx

import CustomButton from "components/Common/CustomButton/CustomButton";
import InputFields from "components/Common/Inputfileds/InputFields";
import { patientRoutes } from "utils/helper/Constants";
import React, { useState } from "react";
import { CiCreditCard1 } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "components/Common/TopBar/TopBar";
import { IMAGES } from "utils/helper/Images";
import styles from "style/PaymentInfo.module.css";
const PaymentInfo = () => {
  const history = useNavigate();

  const [activeTab, setActiveTab] = useState(1);

  const navigateTo = (route) => {
    history(route);
  };
  const [activeStep, setActiveStep] = useState(1);

  const handleStepClick = (step) => {
    setActiveStep(step); // Corrected the typo in setActiveStep
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    // Additional logic if needed when clicking tabs
  };
  function onChange(value) {
    console.log("Captcha value:", value);
  }
  const tabs = [
    {
      id: 1,
      text: "Apple Pay",
      imginactive: IMAGES.applepayicon,
      imgactive: IMAGES.applepayicon,
    },
    {
      id: 2,
      text: "Bank Transfer",
      imginactive: IMAGES.banktransfericoninactive,
      imgactive: IMAGES.banktransfericonactive,
    },
    {
      id: 3,
      text: "Credit Card",
      imginactive: IMAGES.visaiconinactive,
      imgactive: IMAGES.visa,
    },
    {
      id: 4,
      text: "Google Play",
      imginactive: IMAGES.gpayiconinactive,
      imgactive: IMAGES.gpayiconactive,
    },
    {
      id: 5,
      text: "PayPal",
      imginactive: IMAGES.paypaliconinactive,
      imgactive: IMAGES.paypaliconactive,
    },
    {
      id: 6,
      text: "Venmo",
      imginactive: IMAGES.venmoiconinactive,
      imgactive: IMAGES.venmoicononactive,
    },
    {
      id: 7,
      text: "Zelle",
      imginactive: IMAGES.zelleiconinactive,
      imgactive: IMAGES.zelleiconactive,
    },
    // Add more tabs as needed
  ];

  return (
    <div className="container-fluid px-0">
      <TopBar IconItemName="Bill Info" navItemName="Payment Details" />
      <div className={styles.mainWrapper}>
        <div className={styles.paymentInfoInner}>
          <div className={`${styles.paymentInfoSection}`}>
            <div className={`${styles.paymentinfoWrapper}`}>
              <div className={`${styles.pageTitle}`}>
                <span>Pay as Guest</span>
              </div>

              <div className={`${styles.description}`}>
                Make a one-time payment with the form provided below. If there
                are any questions on your payments, please either contact your
                medical provider or visit our{" "}
                <Link
                  target="_blank"
                  to={patientRoutes.billingFaqs}
                  className="patient-text-blue"
                >
                  Billing FAQ
                </Link>{" "}
                page
                <div className={`${styles.descriptionRow}`}>
                  To complete your payment, please fill in the details below and
                  enter a valid payment method.
                </div>
              </div>
            </div>

            <div className={`${styles.paymentmethodsWrapper}`}>
              <div className={`${styles.description}`}>
                <div className={`${styles.visualAppearance}`}>
                  <div
                    className={`${styles.step} ${
                      activeStep === 1 ? styles.activeStep : ""
                    }`}
                    onClick={() => handleStepClick(1)}
                  >
                    <img src={IMAGES.radiobuttonactive} alt="radio" /> Bill
                    Information
                  </div>
                  <div className={`${styles.separator}`} />
                  <div
                    className={`${styles.step} ${
                      activeStep === 2 ? styles.activeStep : ""
                    }`}
                    onClick={() => handleStepClick(2)}
                  >
                    <img
                      src={IMAGES.radiobuttonactive}
                      alt="radio"
                      className={`${activeTab === 2 ? styles.activeIcon : ""}`}
                    />{" "}
                    Payment Information
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 col-xl-5">
                    <div className={`${styles.pagesubTitle}`}>
                      Payment Methods
                    </div>

                    <div className="nav n nav-pills flex-wrap flex-xl-column">
                      {tabs.map((tab) => (
                        <button
                          key={tab.id}
                          className={`nav-link ${
                            activeTab === tab.id ? styles.activeTab : ""
                          } ${styles.TabCusBtn} ${styles.tabContent}`}
                          onClick={() => handleTabClick(tab.id)}
                        >
                          <div className={`${styles.iconWrapper}`}>
                            <img
                              className={`${styles.iconTick}`}
                              src={
                                activeTab === tab.id
                                  ? IMAGES.successstatusactiveicon
                                  : IMAGES.tickIcon
                              }
                              alt="tick"
                            />
                          </div>
                          <span className={`${styles.tabText}`}>
                            {tab.text}
                          </span>
                          <div className={`${styles.rightIconWrapper}`}>
                            <img
                              className={`${styles.rightIcon}`}
                              src={
                                activeTab === tab.id
                                  ? tab.imgactive
                                  : tab.imginactive
                              }
                              alt="right-icon"
                            />
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-7 mt-3 mt-md-0">
                    <div className={`${styles.pagesubTitle}`}>
                      Card Holder Information
                    </div>
                    <div>
                      <div>
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-xl-6 mb-3">
                            <InputFields
                              label="Card Number"
                              type="text"
                              placeholder="xxxx-xxxx-xxxx-xxxx"
                              labelStyle={styles.customlable}
                              groupWrapper={styles.fieldsWrapper}
                              CustomIcon={<CiCreditCard1 />}
                              required
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 col-xl-6 mb-3">
                            <InputFields
                              label="Card Holder"
                              type="text"
                              placeholder="Enter Name"
                              groupWrapper={styles.fieldsWrapper}
                              CustomIcon={<FaRegUser />}
                              required
                            />
                          </div>
                          <div className="col-md-6 col-xl-6">
                            <div className="row align-items-end">
                              <div className="col-6 col-sm-6 col-md-6 patient-dropDown-wrapper patient-info-page">
                                <InputFields
                                  roundedSm
                                  label="Expiry Date"
                                  type="dropDown"
                                  options="months"
                                  placeholder="MM"
                                  value=""
                                  index="months"
                                  handleChange=""
                                  onFocus=""
                                  required
                                />
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 patient-dropDown-wrapper patient-info-page">
                                <InputFields
                                  roundedSm
                                  label=""
                                  type="dropDown"
                                  options="months"
                                  placeholder="YY"
                                  value=""
                                  index="months"
                                  handleChange=""
                                  onFocus=""
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 col-xl-6 mt-3 mt-md-0 payment-cvc-info">
                            <InputFields
                              label="CVC"
                              type="text"
                              placeholder="Enter CVC"
                              groupWrapper={styles.fieldsWrapper}
                              CustomIcon={
                                <img
                                  className={styles.cvcIconcl}
                                  src={IMAGES.cvcIcon}
                                  alt=""
                                />
                              }
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className={`${styles.checkboxWrapper} mt-4 mb-4`}
                              >
                                <input
                                  className={`${styles.checkbox}`}
                                  type="checkbox"
                                  value=""
                                  id="saveCardCheckbox"
                                />
                                <label
                                  className={`${styles.checkboxLabel}`}
                                  htmlFor="saveCardCheckbox"
                                >
                                  Save information for future payments
                                </label>
                              </div>
                              <div className={styles.dueAmount}>
                                <div className={styles.dueLabel}>
                                  Total Amount
                                </div>
                                <div className={styles.amountWrapper}>
                                  <span className={styles.amountField}>
                                    $438.00
                                  </span>
                                </div>
                              </div>
                              <div
                                className={`${styles.checkboxWrapper} mt-3 mb-4`}
                              >
                                <input
                                  className={`${styles.checkbox} patient-text-blue`}
                                  type="checkbox"
                                  value=""
                                  id="saveCardCheckbox12"
                                />
                                <Link
                                  target="_black"
                                  to="/terms-of-use-medflow"
                                >
                                  <label
                                    className={`cursor-pointer d-inline-block ${styles.subcheckboxLabel}`}
                                    // htmlFor="saveCardCheckbox12"
                                  >
                                    I agree to the terms and authorize this
                                    payment
                                  </label>
                                </Link>
                              </div>
                            </div>
                            <div className={`${styles.detail}`}>
                              A confirmation of this message will be sent to the
                              email used on the Bill Information section For any
                              medical billing questions, <br />
                              please call the telephone number on your medical
                              billing statement for assistance
                            </div>
                            {/* <ReCAPTCHA className="mt-4" sitekey="Your client site key" onChange={onChange} /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${styles.bottomBtn} d-block d-sm-flex align-items-center justify-content-between mt-4`}
            >
              <div className={`${styles.leftSide} `}>
                <CustomButton
                  label="Back"
                  handleClick={() => navigateTo("/payment-details")}
                  additionalClass={styles.backButton}
                  variant="darkGray"
                />
              </div>
              <div className={`${styles.rightSide} `}>
                <CustomButton
                  label="Pay Now"
                  additionalClass={`${styles.backButton} ${styles.payNowButton}`}
                  variant="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;
