import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { logOut } from "store/features/patientPortal/authentication/authenticationSlice";
import styles from "style/Navbar.module.css";
import { IMAGES } from "utils/helper/Images";
import { logoutRequest } from "../../../redux/reducers/duck/authDuck";
import { RESET_ALL_STATES } from "../../../redux/reducers/rootReducer";

const Navbar = ({ navlinkText1, navlinkText, navlinkIcon, color }) => {
  const currentUser = useSelector((state) => state.auth.loginData);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();

  const loggedInUser = currentUser?.loginStatus;

  const handleLogout = () => {
    // dispatch(logoutRequest());
    dispatch({ type: RESET_ALL_STATES });
    localStorage.removeItem("reduxState");
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("persist:root");
    history.push("/login");
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // console.log("currentUser =>", currentUser);

  return (
    <nav className={`${styles.navbar} `}>
      <div className={`${styles.navbarLeft} `}>
        <Link to="/patient" className={`${styles.navlink} `}>
          {navlinkText}
        </Link>
        <Link
          to="/patient"
          className={`${styles.navlink} d-flex align-items-center ${
            color ? `${styles.TextcolorChange}` : ""
          }`}
        >
          <span className={`${styles.iconWrapper} `}>{navlinkIcon}</span>
          {navlinkText1}
        </Link>
      </div>
      <div className={`${styles.navbarRightWrapper} `}>
        <div className={`${styles.desktopScreen} `}>
          <ul className={`${styles.navbarRight} m-0`}>
            <li>
              <div className={`${styles.userInfo} `}>
                <img
                  src={
                    loggedInUser?.img ? loggedInUser.img : IMAGES.userPlaceholder
                  }
                  alt="User"
                  className={` ${styles.profileImg} `}
                />
                <div className={`${styles.UserName} `}>
                  {loggedInUser.first} {loggedInUser.last}
                  {/* <div className="text-center">{loggedInUser.accNo}</div> */}
                  <div className={`${styles.logoutBtn} d-flex`}>
                  <div className="text-center">{"Acc No: "}</div>
                  {/* <div className={`${styles.UserName} `}> */}
                  <div className= {`${styles.AccountNo} text-black`}>{loggedInUser.accNo}</div>
                </div>
                </div>
              </div>
              
               
                {/* </div> */}
               
              
            </li>
            <li>
              <Link
                to="/login"
                onClick={handleLogout}
                className={`${styles.logoutBtn} `}
              >
                Logout
              </Link>
            </li>
            <li>
              <Link className={`${styles.settingIcon} `}>
                {/* <AiFillSetting /> */}
                {/* <img src={IMAGES.settingIcon} alt="setting" /> */}
              </Link>
            </li>
          </ul>
        </div>
        <div className={`${styles.mobileScreen}`}>
          <ul className={`${styles.navbarRight} m-0`}>
            <li>
              <div
                className={`${styles.userInfo} ${
                  isDropdownOpen ? styles.active : ""
                }`}
                onClick={handleDropdownToggle}
              >
                <img
                  src={IMAGES.downArrowIcon}
                  alt="User"
                  className={`${styles.dropIcon} `}
                />
                <img
                  src={
                    loggedInUser.img ? loggedInUser.img : IMAGES.userPlaceholder
                  }
                  alt="User"
                  className={`${styles.profileImg} `}
                />
                <span className={`${styles.UserName} `}>
                  {loggedInUser.firstName} {loggedInUser.lastName}
                  <div className="text-center">{loggedInUser.accNo}</div>
                </span>

                {/* Dropdown Content */}
                {isDropdownOpen && (
                  <div className={styles.dropdownContent}>
                    <ul className={styles.dropdownContentList}>
                      <li>
                        <span>
                          {loggedInUser.firstName} {loggedInUser.lastName}
                          <div className="text-center">
                            {loggedInUser.accNo}
                          </div>
                        </span>
                      </li>
                      <li>
                        <Link
                          to="/login"
                          onClick={handleLogout}
                          className={`${styles.logoutBtn} `}
                        >
                          Logout
                        </Link>
                      </li>
                      <li>
                        <Link className={`${styles.settingIcon} `}>
                          {/* <AiFillSetting /> */}
                          {/* <img src={IMAGES.settingIcon} alt="setting" /> */}
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
