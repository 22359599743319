import TopBar from "components/Common/TopBar/TopBar";
import { Link } from "react-router-dom";

const SecrecyPolicy = () => {
  return (
    <>
      <TopBar IconItemName="Confidentiality Policy" LoginBtn="Login" />
      <div className="privacy-policy-wrapper pb-4">
        <h1 className="text-center">MedFlow, LLC Confidentiality Policy</h1>
        <strong>Updated January 3, 2024</strong>
        <br />
        <p>
          The United States Department of Health and Human Services{" "}
          <Link to="https://www.hhs.gov/">(HHS) </Link> has promulgated
          regulations pursuant to the Health Insurance Portability and
          Accountability Act of 1996 (HIPAA) designed to protect the
          confidentiality and integrity of Protected Health Information (PHI).
          PHI is defined by HIPAA as the individually identifiable health
          information that is:
          <br />
          <br />
          <ul>
            <li>
              <p>Transmitted by electronic media</p>
            </li>
            <li>
              <p>Maintained in any medium</p>
            </li>
            <li>
              <p>Transmitted or maintained in any other form or medium</p>
            </li>
          </ul>
          <br />
          As a healthcare provide who provides 
          <Link to="http://genetics.wpengine.com/client-services/directory-of-services/">
            laboratory testing 
          </Link>
          to ordering authorized healthcare providers, MedFlow, LLC (MF) is
          committed to protecting the privacy of patient personal information,
          laboratory test results, and other protected health information.
          <br />
          <br />
          MF has established a special web Outreach portal for physicians to use
          to obtain laboratory test results for their patients. Information
          accessed through this portal, including Protected Health Information
          (as defined below), is secured using administrative, physical, and
          technical safeguards. Access is password protected and each individual
          user has a unique username and password. All access is tracked at MF
          for security purposes. PHI that is available on the Outreach Portal
          may only be used or disclosed for treatment and other authorized
          purposes.
          <br />
          <br />
          MF supports clinical laboratories performing specialized diagnostic
          testing services for healthcare providers throughout the United
          States, including, physicians, hospitals, and other laboratories. The
          ordering of tests and reporting of test results are communications
          permitted under HIPAA for the purpose of treatment, payment and
          healthcare operations.
          <br />
          <br />
          Although classified as an “indirect healthcare provider,” MF is
          considered a “covered entity” for purposes of compliance with the
          HIPAA section on Standards for Privacy of Individually Identifiable
          Health Information (Privacy Rule). In the course of providing its
          diagnostic services, MF obtains, uses, and discloses PHI. PHI also
          includes the following, but is not limited to:
          <br />
          <br />
          <ul>
            <li>
              <p>
                Patient name, address, date of birth, social security number,
                and phone numbers
              </p>
            </li>
            <li>
              <p>
                Health insurance and other payment information (not necessary if
                your physician or laboratory pays us directly)
              </p>
            </li>
            <li>
              <p>Your physician’s reason for referral, including diagnosis.</p>
            </li>
            <li>
              <p>Your physician’s name and address</p>
            </li>
            <li>
              <p>
                Other physical and medical information relating to your
                condition that we may need to complete your study.
              </p>
            </li>
          </ul>
        </p>
        <br />
        <strong>
          To ensure the protection of PHI, MF has implemented policies and
          procedures:
        </strong>
        <br />
        <ul>
          <li>
            <p>
              To comply with federal, state, and local laws, and regulations
              regarding the use and disclosure of such PHI;
            </p>
          </li>
          <li>
            <p>
              To protect confidentiality and integrity of PHI we collect, create
              or exchange as part of diagnostic testing services; and
            </p>
          </li>
          <li>
            <p>
              To prevent inappropriate access to or disclosure of such
              information.
            </p>
          </li>
        </ul>
        <br />
        <p>
          MF intends for its security and privacy policies and procedures to
          comply with HIPAA including, the Health Information Technology for
          Economic and Clinical Act (HITECH Act), the Security Standards and
          other applicable federal and state laws and regulations. In the event
          there is a change in the Security Standards, other applicable federal
          and state laws or regulations, MF will amend its privacy program as
          necessary to comply with applicable law.
        </p>
        <br />
        <strong>Uses and Disclosure of PHI</strong>
        <br />
        <p>
          PHI will be used or disclosed for treatment, payment, or healthcare
          operation purposes and for other purposed permitted or required by
          law. While we cannot list every possible use or disclosure, most of
          the ways we use or disclose PHI will fall into one of the categories
          listed below. If we want to use or disclose PHI for purposes that do
          not fall into these categories, we must first obtain written
          authorization. According to law, MF does not need authorization or
          permission to use or disclose a patient’s PHI for the following
          purposes, even after your death:
        </p>
        <br />
        <ul>
          <li>
            <p>
              <strong className="d-inline-block">Treatment –</strong>
              As a healthcare provider that provides laboratory testing for
              patients and requested by physicians, MF uses PHI as part of our
              testing processes, and MF discloses PHI to physicians and other
              healthcare professionals who need access to the results in order
              to treat the patient. We may also disclose a patient’s PHI to
              another testing laboratory if we are unable to perform the testing
              ourselves and need to refer the specimen to a laboratory that
              performs the needed test.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">Payment –</strong>
              Our billing department will use and disclose PHI to certain
              insurance companies, hospitals, physicians, and health plans for
              payment purposes or to a third party to assist us in creating
              bills, claim forms, or getting paid for our services, such as a
              collection agency. There may be instances when we may have to
              contact the patient to obtain billing information or for other
              billing purposes.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">
                Healthcare Operations –
              </strong>
              We may use or disclose PHI in the course of activities required to
              support our healthcare operations, such as quality checks on our
              testing or for developing normal reference ranges for tests that
              we perform. This information will be used in an effort to improve
              the quality and effectiveness of the healthcare services we
              provide. We may also disclose health information to other
              healthcare providers or payers for their healthcare operations,
              but only if they already have a relationship with the patient and
              the purpose is for quality assurance activities, peer review
              activities, detecting fraud or for other limited purposes.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">
                Disclosures to Business Associates –
              </strong>
              MF may disclose a patient’s PHI to other companies or individuals
              who on behalf of MF, need PHI to provide specific services to us.
              These other entities, known as “business associates”, generally
              must comply with the terms of a contract designed to ensure that
              they will maintain the privacy and security of PHI in the same
              manner that we do (i.e., for designated treatment, payment, or
              healthcare operations purposes that they perform for us). For
              example, PHI may be disclosed to couriers we use to transport
              specimens to us or to private accrediting organizations that
              inspect and certify the quality of our laboratories.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">By Law –</strong>
              In order to comply with international, federal, or state laws,
              court orders, subpoenas, or governmental agency orders.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">Public Health –</strong>
              to public health authorities for preventing or controlling disease
              or reporting vital information (for example, reporting certain
              sexually transmitted diseases). We may disclose PHI to the Food
              and Drug Association for purposes related to quality safety or
              effectiveness of FDA regulated products; to prevent or control
              diseases; to report child abuse or neglect; drug reactions to
              medications; product recalls that we may be using.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">Law Enforcement –</strong>
              to law enforcement officials relating to crimes and other law
              enforcement purposes.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">
                Specialized Government Functions –
              </strong>
              to military command authorities, veteran’s administration, and
              national security and intelligence officials for activities deemed
              necessary to carry out their respective missions or to law
              enforcement officials having custody of an inmate.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">
                Worker’s Compensation –
              </strong>
              to the extent authorized by and to the extent necessary to comply
              with laws relating to worker’s compensation or similar programs
              established by law.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">Minors –</strong>
              We may disclose minor children’s PHI to the parents or legal
              guardians unless prohibited by law.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">Data Breach –</strong>
              to provide legally required notices in the event of a data breach.
            </p>
          </li>
          <li>
            <p>
              <strong className="d-inline-block">
                Coroners, Medical Examiners, Funeral Directors –
              </strong>
              in order for them to perform their duties.
            </p>
          </li>
        </ul>
        <br />
        <p>
          MF’s information technology system uses modern encryption,
          authorization/authentication technology to guard against unauthorized
          access to PHI across the MF network as well as open networks such as
          the internet. As per HIPAA guidelines, MF continually monitors
          external communications and maintains strict security standards for
          both hardware and software to protect PHI.
        </p>
        <br />
        <strong>
          Uses and Disclosures That Require Us to Give You opportunity To Object
          and Opt Out
        </strong>
        <br />
        <p>
          Unless you object in writing, we may disclose to a member of your
          family, a relative, a close friend or any other person you identify,
          your Phi that directly relates to that person’s involvement in your
          health care. If you are unable to agree or object, we may disclose
          such information as necessary if we determine it is in your best
          interest based on our professional judgment. <br />
          You may also object in writing under your HIPAA rights that your
          healthcare providers not disclose information about services received
          when you pay in full or out-of-pocket for the service and refuse to
          file a claim with your health plan.
        </p>
        <br />
        <strong>Your Rights Regarding Your PHI</strong>
        <br />
        <p>
          Patient Access to Protected Health Information €164.524 Access of
          individuals to protected health information, a patient or personal
          representative (family member, power of attorney or legal guardian)
          may request access to their protected health information (PHI).
          Genetics Associates, Inc. (MF) will require written documentation of
          the request. The patient or their legal representative shall complete
          the following document and submit it to MF by fax or mail along with a
          copy of their photo ID. No verbal requests will be accommodated.
          Genetics Associates will charge a nominal fee of $25.00 for the
          retrieval of information. Should the patient or legal representative
          request an interpretation, MF will refer the patient back to the
          requesting physician. Required Patient Information:
        </p>
        <br />
        <ul>
          <li>
            <p>Social Security Number</p>
          </li>
          <li>
            <p>Date of Birth</p>
          </li>
          <li>
            <p>Specimen Type</p>
          </li>
          <li>
            <p>Date of Service</p>
          </li>
          <li>
            <p>Patient’s Address</p>
          </li>
          <li>
            <p>Ordering Physician</p>
          </li>
          <li>
            <p>Valid photo ID, included with request</p>
            <li>
              <p>Driver’s License</p>
            </li>
            <li>
              <p>Passport</p>
            </li>
            <li>
              <p>Other Government-Issued ID</p>
            </li>
          </li>
        </ul>
        <br />
        <p>
          <strong className="d-inline-block">Right to Amend –</strong>
          If you feel that there is a mistake or missing information in our
          records of your PHI, you may ask us to make the corrections. The
          request must be in writing, and you must provide a reason that
          supports your request. We reserve the right to deny the request. Any
          denial will state the reason for the denial.
        </p>
        <br />
        <p>
          <strong className="d-inline-block">
            Right to Request Restrictions –
          </strong>
          You have the right to ask us to limit how your PHI is used or
          disclosed. The request must be made in writing, describe the
          restrictions, and to whom those restrictions apply. You have the right
          to restrict disclosures to health plans for services which you paid
          for out of pocket.
        </p>
        <br />
        <p>
          <strong className="d-inline-block">
            Right to Confidential Communication –
          </strong>
          You have the right to ask us to communicate with you in a certain way
          or at a certain address. For example, you may want your information
          sent to your workplace instead of home.
        </p>
        <br />
        <br />
      </div>
    </>
  );
};

export default SecrecyPolicy;
