import { fetchAuthSession } from "aws-amplify/auth";
import { del, get, post, put } from "aws-amplify/api";
import { END_POINTS_CUSTOM_ERRORS } from "utils/helper/Constants";

const apiName = "AdminQueries";
const publicApiName = "usersApi";

export const API_METHODS = {
  delete: "delete",
  get: "get",
  post: "post",
  put: "put",
};

// private api calls
export const callAmplifyAPI = async (
  path,
  payload,
  method = API_METHODS.post
) => {
  try {
    const token = `${(await fetchAuthSession()).tokens.accessToken.toString()}`;
    // console.log("token",token);
    // console.log("method",method);
    // console.log("payload",payload);
    
    
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: { ...payload },
    };

    let api = null;
    switch (method.toLowerCase()) {
      case "get":
        api = get({ apiName, path, options: params });
        break;
      case "post":
        api = post({ apiName, path, options: params });
        break;
      case "put":
        api = put({ apiName, path, options: params });
        break;
      case "delete":
        api = del({ apiName, path, options: params });
        break;
      default:
        throw new Error(`Unsupported method: ${method}`);
    }

    const apiRes = await api.response;
    return apiRes.body.json();
  } catch (err) {
    throw Error(err);
  }
};

// public api calls
export const callAmplifyPublicAPI = async (
  path,
  payload,
  method = API_METHODS.post
) => {
  try {
    const params = {
      headers: {
        "Content-Type": "application/json",
      },
      body: { ...payload },
    };
    let api = null;
    switch (method.toLowerCase()) {
      case "get":
        api = get({ apiName: publicApiName, path, options: params });
        break;
      case "post":
        api = post({ apiName: publicApiName, path, options: params });
        break;
      case "put":
        api = put({ apiName: publicApiName, path, options: params });
        break;
      case "delete":
        api = del({ apiName: publicApiName, path, options: params });
        break;
      default:
        throw new Error(`Unsupported method: ${method}`);
    }

    const apiRes = await api.response;
    return apiRes.body.json();
  } catch (err) {
    console.log("🚀 ~ err:", err.message)
    if (err.message === "Unknown error") {
      const messageObj = END_POINTS_CUSTOM_ERRORS.find((msgObj) => msgObj.path === path)
      throw Error(messageObj?.message || err.message);
    }
    throw Error(err);
  }
};
