import Axios from "./axios";
import { API_METHODS, callAmplifyPublicAPI } from "./defaultApi";
import urls from "./url";

// Signup Api
export const signupApi = async (data) => {
  try {
    const res = await Axios.post(urls.auth.signup, data, {
      withCredentials: true,
    });
    if (res) {
      localStorage.setItem("token", res?.data?.access_token);
      localStorage.setItem("currentRole", res?.data?.roles);
    }
    return res;
  } catch (err) {
    return err;
  }
};

// login api
export const loginApi = async (data) => {
  try {
    const res = await Axios.post(urls?.auth?.login, data, {
      withCredentials: true,
    });
    if (res) {
      localStorage.setItem("token", res?.data?.access_token);
      localStorage.setItem("currentRole", res?.data?.roles);
    }
    return res;
  } catch (err) {
    return err;
  }
};

// forgot password api
export const forgotPasswordApi = async (params) => {
  const res = await callAmplifyPublicAPI(
    "/user/sendCode",
    params,
    API_METHODS.post
  );
  return res;
};

// forgot password api
export const changePasswordApi = async (params) => {
  const res = await callAmplifyPublicAPI(
    "/user/verifyCode",
    params,
    API_METHODS.post
  );
  return res;
};
