import TopBar from "components/Common/TopBar/TopBar";
import React from "react";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  return (
    <div>
      <TopBar IconItemName="Cookie Policy" LoginBtn="Login" />
      <div className="privacy-policy-wrapper pb-4">
        <h1 className="text-center">
          Cookie Notices and Cookie Consent Explained
        </h1>
        <p>
          Cookies are integral to the way modern websites work. Most
          personalisation and social media integration on websites relies on
          cookies in one way or another.
          <br />
          <br />
          However they can be used in a way that doesn’t benefit visitors, and
          they are often used to track people across the web, and build up
          profiles that are very valuable to brands and advertisers for targeted
          marketing.
          <br />
          <br />
          This is often seen as an{" "}
          <strong className="d-inline-block">invasion of privacy</strong>, and
          because cookies are a fairly quiet technology – this can be happening
          to you without you realising it, or being able to stop it if you
          wanted to.
          <br />
          <br />
          To help you become more aware of this activity, and give you a say in
          whether you want to allow it to happen, new EU-wide privacy
          legislation was put in place in 2011. This has become known as the{" "}
          <Link to="/https://cookiepedia.co.uk/eu-cookie-law">Cookie Law</Link>.
          <br />
          <br />
          The Cookie Law requires that websites give you clear, detailed
          information about how they use cookies, and provide ways for you to
          signal whether or not you want to allow such use. The website is then
          required by law to respect your wishes. This might mean they block the
          cookies you don’t like, or they don’t let you access the site –
          however you should expect to be given the choice.
          <br />
          <br />
          Some websites will allow you to choose which types of cookies to allow
          or block, although in some cases if you do this you may not be able to
          use or see all of a website.
          <br />
          <br />
          If a website you visit is not giving you sufficient information or
          choice, and the company that owns it is based in the EU, then it may
          be breaking the law, and you can{" "}
          <Link to="https://cookiepedia.co.uk/how-to-complain-about-cookies">
            take action
          </Link>{" "}
          against the owner.
          <br />
          <br />
          This website has been created to help people understand better what
          cookies are and how they are used by different websites. You can use
          it to look up your favourite websites, and soon you will be able to
          search for information about specific cookies to understand what they
          are used for.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;
