import { Modal } from "react-bootstrap";

const CustomModal = ({
  showModal,
  handleClose,
  children,
  title,
  size,
  centered,
  modalBodyClass,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size={size}
      centered={centered}
      className="patient-modal"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${modalBodyClass && "modalBodyClass"}`}>
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
