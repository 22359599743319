import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Initial State
export const INITIAL_STATE = {
  signUpData: null,
  signUpFailData: null,
  signUpRecordNotFoundData: null,
  loginData: null,
  forgotPasswordData: null,
  forgotPasswordPayload: null,
  passwordReset: null,
  error: null,
  authenticated: false,
  loading: false,
  verifyOtpData: null,
  is2FAenabled: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    // update loader reducers
    updateLoader: (state, { payload }) => {
      state.loading = payload;
    },
    // SignUp request reducers
    signUpRequest: (state) => {
      state.error = null;
      state.loading = true;
    },
    // SignUp response reducers
    signUpSuccess(state, { payload }) {
      state.signUpData = payload;
      state.loading = false;
    },

    signUpFailure(state, { payload }) {
      state.error = payload;
      state.loading = false;
      state.signUpFailData = payload;
      toast.info(payload);
    },

    signUpRecordNotFound(state, { payload }) {
      state.error = payload;
      state.loading = false;
      state.signUpRecordNotFoundData = payload;
    },

    clearSignUpFailRequest(state, { payload }) {
      state.signUpRecordNotFoundData = null;
    },

    signUpReset: (state) => {
      state.signUpData = null;
    },
    // Login request reducers
    loginRequest: (state) => {
      state.error = null;
      state.loading = true;
    },
    // Login response reducers
    loginSuccess(state, { payload }) {
      state.loginData = payload;
      state.loading = false;
    },
    loginFailure(state, { payload }) {
      state.loginData = null;
      state.loading = false;
      toast.error(
        "Sorry, we are unable to find your record in our system, so you are currently unable to logIn"
      );
    },

    loginFailureUserNotExist(state, { payload }) {
      state.loading = false;
      toast.error(payload);
    },
    logoutRequest: (state) => {
      state.error = null;
    },
    errorRequest: (state) => {
      state.error = null;
    },
    // Logout response reducers
    logoutSuccess(state) {
      state.signUpData = null;
      state.loginData = null;
      state.error = null;
      state.authenticated = false;
    },
    logoutFailure(state, { payload }) {
      state.error = payload;
    },
    forgotPasswordRequest: (state) => {
      state.forgotPasswordData = null;
      state.forgotPasswordPayload = null;
      state.error = null;
      state.loading = true;
    },
    // Forgot password response reducers
    forgotPasswordSuccess(state, { payload }) {
      state.forgotPasswordPayload = payload.phone;
      state.forgotPasswordData = payload;
      state.loading = false;
    },
    forgotPasswordFailure(state, { payload }) {
      state.error = payload;
      state.loading = false;
      toast.error(payload);
    },

    forgotPasswordReset: (state) => {
      state.forgotPasswordData = null;
    },
    changePasswordRequest: (state) => {
      state.passwordReset = null;
      state.error = null;
      state.loading = true;
    },
    // Forgot password response reducers
    changePasswordSuccess(state, { payload }) {
      state.passwordReset = payload;
      state.forgotPasswordPayload = null;
      state.loading = false;
    },
    changePasswordFailure(state, { payload }) {
      state.error = payload;
      state.loading = false;
      toast.error(payload);
    },
    changePasswordReset: (state) => {
      state.passwordReset = null;
    },

    isAuthenticatedRequest(state, { payload }) {
      state.authenticated = true;
    },

    // verify OTP reducers
    verifyOTPRequest(state, { payload }) {
      state.loading = true;
    },
    verifyOTPResponse(state, { payload }) {
      state.verifyOtpData = payload;
    },
    verifyOTPFailure(state, { payload }) {
      state.verifyOtpData = false;
      toast.error("Please enter valid OTP code");
    },
    verifyOTPClearState(state, { payload }) {
      state.verifyOtpData = null;
      state.loading = false;
    },

    clearUserAttributeState(state, { payload }) {
      state.loginData = null;
      state.loading = false;
    },

    // resend OTP reducers
    resendOTPRequest: (state) => state,
    resendOTPResponse(state, { payload }) {},
    is2FAenabled(state, { payload }) {
      state.is2FAenabled = payload;
    },
  },
});

export const {
  signUpRequest,
  signUpSuccess,
  signUpFailure,
  signUpReset,
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordReset,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  changePasswordReset,
  errorRequest,
  clearSignUpFailRequest,
  verifyOTPRequest,
  verifyOTPResponse,
  signUpRecordNotFound,
  isAuthenticatedRequest,
  is2FAenabled,
  resendOTPRequest,
  resendOTPResponse,
  updateLoader,
  loginFailureUserNotExist,
  verifyOTPFailure,
  verifyOTPClearState,
  clearUserAttributeState,
} = authSlice.actions;

export default authSlice.reducer;
