import { all, fork } from "redux-saga/effects";
import { watchUserSagas } from "./sagas/userSaga";
import { watchAuthSagas } from "./sagas/authSaga";
import { watchGetTestsSagas } from "./sagas/getTestsSaga";
import { watchInvoicesSagas } from "./sagas/invoicesSaga";
import { watchAppointmentSagas } from "./sagas/appointmentSaga";

const rootSaga = function* () {
  yield all([fork(watchUserSagas)]);
  yield all([fork(watchAuthSagas)]);
  yield all([fork(watchGetTestsSagas)]);
  yield all([fork(watchInvoicesSagas)]);
  yield all([fork(watchAppointmentSagas)]);
};

export default rootSaga;
