import CustomButton from "components/Common/CustomButton/CustomButton";
import CustomCheckbox from "components/Common/CustomCheckbox/CustomCheckbox";
import InputFields from "components/Common/Inputfileds/InputFields";
import Loader from "components/Common/Loader/Loader";
import TopBar from "components/Common/TopBar/TopBar";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { FaPhone } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  loginRequest,
  isAuthenticatedRequest,
  clearUserAttributeState,
} from "redux/reducers/duck/authDuck";
import styles from "style/Login.module.css";
import { IMAGES } from "utils/helper/Images";
import CustomModal from "components/Common/CustomModal/CustomModal";
import { Container } from "react-bootstrap";
import TwoFA from "../../../patientsPortal/auth/twoFactorAuth";
import { RESET_ALL_STATES } from "../../../../redux/reducers/rootReducer";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData, loading } = useSelector(({ auth }) => ({
    loginData: auth?.loginData,
    loading: auth?.loading,
  }));

  const [isToastDisplayed, setIsToastDisplayed] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const [checkboxStates, setCheckboxStates] = useState({
    rememberMe: false,
    termsCondition: false,
  });
  const [loginType, setLoginType] = useState("phone");

  const { handleSubmit, control, formState, watch } = useForm({
    mode: "onChange",
  });

  const { errors, isValid } = formState;

  // handle change
  const handleCheckboxChange = (checkboxId, newState) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [checkboxId]: newState,
    }));
  };
  const phoneNumber = watch("phone");
  const accountNumber = watch("accountNo");
  const password = watch("password");

  // handle submit
  // const onSubmit = async (data) => {
  //   const { phone, accountNo } = data;

  //   if (!phone && !accountNo) {
  //     toast.error("Please enter either a phone number or an account number.");
  //     return;
  //   }
  //   try {
  //     dispatch(loginRequest(data));
  //   } catch (error) {
  //     console.error("Error logging in:", error);
  //   } finally {
  //   }
  // };

  const onSubmit = async (data) => {
    const { phone, accountNo, ...restData } = data;

    if (loginType === "phone" && !phone) {
      toast.error("Please enter a phone number.");
      return;
    } else if (loginType === "account" && !accountNo) {
      toast.error("Please enter an account number.");
      return;
    }

    const loginData = {
      ...restData,
      ...(loginType === "phone" ? { phone } : { accountNo }),
    };

    try {
      dispatch(loginRequest(loginData));
    } catch (error) {
      console.error("Error logging in:", error);
    } finally {
    }
  };
  useEffect(() => {
    if (loginData?.userAttributes?.["custom:is2FaEnabled"] === "y") {
      // navigate("/two-factor-auth");
      setIsShowModal(true);
    } else if (
      loginData &&
      loginData?.loginStatus &&
      loginData?.userAttributes
    ) {
      dispatch(isAuthenticatedRequest());
      navigate("/results");
      toast.success("Login successful");
    } else if (!loginData) {
      setIsShowModal(false);
      console.log("error");
    } else {
      console.log();
    }
  }, [loginData, navigate, dispatch]);

  ;

  // handle close-modal
  const handleCloseModal = () => {
    // dispatch({ type: RESET_ALL_STATES });
    dispatch(clearUserAttributeState());
    localStorage.removeItem("reduxState");
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("persist:root");
    setIsShowModal(false);
  };

  // console.log("loginData ==>", loginData);
  // console.log("is2FAenabled =>", is2FAenabled);
  // console.log("loginType",loginType)

  return (
    <>
      <TopBar IconItemName="Login" />
      <div className={`${styles.LoginSection} `}>
        {loading && <Loader />}
        <div className={`${styles.LoginWrapper} row`}>
          <div className="col-md-12 col-lg-6">
            <div className={`${styles.contentWrapper} `}>
              <h2 className={`${styles.headingText} `}>
                Welcome To MedFlow Portal
              </h2>
              <p>Log in to view and pay your medical bills easily.</p>
              <div className="px-3">
                {/* <h4>You can login using your Account Number</h4> */}
                <h4>You can login using your account or telephone number</h4>
                
                <p>
                  The Account Number contains numbers and letters and can be
                  found at the top right of your statement.
                </p>
              </div>
              <div className={`${styles.contentBox} `}>
                <a
                  href={IMAGES.Loginscreen}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.imgBox} d-block position-relative`}
                >
                  <img src={IMAGES.Loginscreen} alt="pdf" />
                  <img
                    src={IMAGES.openFUllImage}
                    alt="openImage"
                    className={styles.openFullImage}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className={`${styles.loginForm} `}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.formWrapper}`}>
                  <p className={`${styles.subHeading}`}>Login</p>
                  <div
                    className={`${styles.Accountfields} mb-4 patient-phone-input`}
                  >
                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="loginType"
                          checked={loginType === "phone"}
                          id="loginType1"
                          onChange={() => setLoginType("phone")}
                        />
                        <label className="form-check-label" for="loginType1">
                          Login with phone number
                        </label>
                      </div>
                      <div className="form-check mt-2 mt-sm-0">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={loginType === "account"}
                          name="loginType"
                          id="loginType2"
                          onChange={() => setLoginType("account")}
                        />
                        <label className="form-check-label" for="loginType2">
                          Login with account number
                        </label>
                      </div>
                    </div>

                    <div
                      className={loginType === "phone" ? "d-block" : "d-none"}
                    >
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                          // required: "Phone Number is required",
                          pattern: {
                            value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                            message:
                              "Please enter a valid phone number (+x xxx xxx xxxx).",
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <InputFields
                              label="Phone"
                              CustomIcon={<FaPhone />}
                              type="phone"
                              placeholder="+1 625 999 3488"
                              value={field.value}
                              index="phone"
                              maxLength={65}
                              handleChange={(e) => {
                                field.onChange(e);
                              }}
                              roundedSm
                              shadowSm
                            />
                            {errors.phone && (
                              <div
                                className={`${styles.validationmessage} validation-message`}
                              >
                                {errors.phone.message}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div
                      className={loginType === "account" ? "d-block" : "d-none"}
                    >
                      <Controller
                        name="accountNo"
                        control={control}
                        defaultValue=""
                        rules={
                          {
                            // required: "Account Number is required",
                          }
                        }
                        render={({ field }) => (
                          <>
                            <InputFields
                              label="Account Number"
                              type="text"
                              placeholder="TYU998989893"
                              value={field.value}
                              maxLength={100}
                              handleChange={(e) => {
                                field.onChange(e);
                              }}
                              roundedSm
                              shadowSm
                            />
                            {errors.password && (
                              <div
                                className={`${styles.validationmessage} validation-message`}
                              >
                                {errors.password.message}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="patientDatePicker">
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          // message:
                          //   "Password must be at least 8 characters long",
                        },
                        // pattern: {
                        //   value:
                        //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        //   message:
                        //     "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character",
                        // },
                      }}
                      render={({ field }) => (
                        <>
                          <InputFields
                            label="Password"
                            type="password"
                            placeholder="***********"
                            value={field.value}
                            maxLength={100}
                            handleChange={(e) => {
                              field.onChange(e);
                            }}
                            required
                            roundedSm
                            password
                            shadowSm
                          />
                          {errors.password && (
                            <div
                              className={`${styles.validationmessage} validation-message`}
                            >
                              {errors.password.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className={`${styles.linksWrapper} text-end mt-3`}>
                    <small>
                      <Link
                        to={"/forgot-password"}
                        className="patient-text-blue mr-3"
                      >
                        Forgot Password?
                      </Link>
                    </small>
                  </div>
                  <div className="mt-4 mb-3">
                    <CustomCheckbox
                      id="rememberMe"
                      label="Remember Me"
                      isChecked={checkboxStates.rememberMe}
                      onChange={(id, isChecked) =>
                        handleCheckboxChange(id, isChecked)
                      }
                      type="default"
                    />
                  </div>

                  <div className={`${styles.rememberfWrapper} mb-3`}>
                    <div className="d-flex align-items-cneter">
                      <CustomCheckbox
                        isChecked={checkboxStates.termsCondition}
                        onChange={handleCheckboxChange}
                        cssClass="text-dark"
                        id="termsCondition"
                        type="default"
                      />
                      <div className="">
                        <small className={styles.readTerms}>
                          I have read, understanding and agree to the &nbsp;{" "}
                          <Link
                            target="_blank"
                            to="https://medflow-web-10628d-0f541-43978e00fba7d.webflow.io/terms-of-use-medflow"
                            className="patient-text-blue"
                          >
                            Terms of Use,
                          </Link>
                          &nbsp;
                          <Link
                            target="_blank"
                            to="https://medflow-web-10628d-0f541-43978e00fba7d.webflow.io/privacy-policy-new"
                            className="patient-text-blue"
                          >
                            Privacy Policy,
                          </Link>
                          &nbsp;
                          <Link
                            target="_blank"
                            to="/cookie-policy"
                            className="patient-text-blue"
                          >
                            Cookie Policy,
                          </Link>
                          &nbsp;
                          <Link
                            target="_blank"
                            // to="/confidentiality-policy"
                            to="https://www.hhs.gov/hipaa/for-professionals/privacy/index.html"
                            className="patient-text-blue"
                          >
                            Confidentiality Notice
                          </Link>
                          {/* &nbsp; and{" "}
                          <Link
                            to="https://www.google.com/"
                            target="_blank"
                            className="patient-text-blue"
                          >
                            Consent to Electronic Communication
                          </Link> */}
                        </small>
                      </div>
                    </div>
                    <div className="mt-3 mt-xl-4">
                      <CustomButton
                        variant="primary"
                        label="SIGN IN"
                        additionalClass={styles.btnHeight}
                        type="submit"
                        disabled={
                          !isValid ||
                          !checkboxStates.termsCondition ||
                          !password ||
                          (loginType === "phone" && !phoneNumber) ||
                          (loginType === "account" && !accountNumber)
                        }
                      />
                      <small className="d-block text-center mt-3">
                        Don't have an account?{" "}
                        <Link to="/signup" className="patient-text-blue">
                          Sign Up
                        </Link>
                      </small>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* OTP modal  */}
      <div>
        <CustomModal
          showModal={isShowModal}
          handleClose={() => handleCloseModal()}
          title="Verification"
          label="Submit"
          size="lg"
          modalBodyClass
          centered
        >
          {" "}
          <div className="px-3">
            <TwoFA />
          </div>
        </CustomModal>
      </div>
    </>
  );
};

export default Login;
