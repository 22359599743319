import { parsePhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { RESULT_PDF, TEST_PANEL_TYPES } from "./helper/Constants";

export const toTitleCase = (str) => {
  if(!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str
    .toLowerCase()
    .replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase());
};
export const getValidName = (name) => {
  if(!name) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};

export const CapsFirstLetter = (str) => {
  if(!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
};
export const formatTimeFromDate = (date) =>
  date ? moment(date).format("hh:mm A") : "";

export const formatAddress = (val) => {
  if(!val || (!val.city && !val.state && !val.zip)) return "";
  let arr = [];
  if(val.city) arr.push(val.city);
  if(val.state) arr.push(val.state);
  arr = [arr.join(",")];
  if(val.zip) arr.push(val.zip);
  if(val.zipcode) arr.push(val.zipcode);
  return arr.join(" ");
};

export const calculateTdWidth = (width, numberOfCol) =>
  // eslint-disable-next-line
  screen.width >= "768" && screen.width <= "1024"
    ? 100
    : Math.ceil(width / numberOfCol);

export const getValidSpaces = (name) => {
  if(!name) return "";
  return name.replace(/  +/g, " ");
};

export const convertToUpper = (val) => {
  const v = getValidSpaces(val);
  if(val) return v.toUpperCase();
  return val;
};

export const draggablePersonalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = JSON.stringify(
      arr
        .filter((f) => f.isCheck)
        .map((f) => {
          return { id: f.id, width: f.width };
        })
    );

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if(lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = JSON.parse(objData[key]);
    } catch(err) { }

    if(!Array.isArray(arrData)) return NEW_PERSONALIZE;

    let objToReturn = arrData?.map((f) => {
      const obj = NEW_PERSONALIZE.find((obj) => obj.id == f.id) || null;
      return {
        ...obj,
        isCheck: NEW_PERSONALIZE.findIndex((obj) => obj.id == f.id) !== -1,
        width: f.width,
      };
    });

    const lsData = arrData?.map((obj) => obj.id);
    if(!lsData) return NEW_PERSONALIZE;
    const nonCheckedData = NEW_PERSONALIZE.filter(
      (obj) => !lsData.includes(obj.id)
    );
    objToReturn = [
      ...objToReturn,
      ...nonCheckedData.map((data) => {
        return { ...data, isCheck: false, width: "100%" };
      }),
    ];
    return objToReturn;
  },
  clear: () => localStorage.removeItem("personalisation"),
};

export const formatDateOfBirth = (dob) =>
  dob
    ? `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}`
    : "";

export const formatDate = (date) =>
  date ? moment(date).format("MMM DD, YYYY") : "";

export const formatDateTime = (date) =>
  date ? moment(date).format("MMM DD, YYYY - hh:mm A") : "";

export const formatDateMDY = (date) =>
  date ? moment(date).format("MM/DD/YYYY") : "";

export const formatPhoneNumber = (phoneNumber) => {
  if(!phoneNumber) return "";
  if(phoneNumber) {
    return formatPhoneNumberIntl(phoneNumber)?.replace(/\s/g, "");
  }
  return phoneNumber;
};

export const formatOrderDate = (date) => (date ? moment(date).format("MM-DD-YYYY hh:mm A") : "");

export const tdEmail = (email) => (
  <td
    key={ email }
    className=" ellipsis"
    style={ {
      textDecoration: "underline",
      color: "var(--text-danger)",
    } }
    onMouseOver={ (e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    } }
    onMouseLeave={ (e) => {
      e.target.style.textDecoration = "underline";
    } }
    onClick={ () => email && window.open(`mailto:${email}`) }
    title={ email }
  >
    { email }
  </td>
);

export const tdPhone = (phoneNumber, textAlign) => (
  <td
    key={ phoneNumber }
    className="ellipsis"
    style={ {
      textDecoration: "underline",
      color: "var(--text-danger)",
      textAlign: textAlign || "",
    } }
    onMouseOver={ (e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    } }
    onMouseLeave={ (e) => {
      e.target.style.textDecoration = "underline";
    } }
    onClick={ () =>
      phoneNumber && window.open(`tel:+${phoneNumber.replace(/\D/g, "")}`)
    }
    title={ formatPhoneNumber(phoneNumber) }
  >
    { formatPhoneNumber(phoneNumber) }
  </td>
);
export const currentDate = () => moment().format("MM/DD/YYYY");

export const formatDateOfBirthDOB = (dob) => {
  if(!dob || dob == "") return "";
  if(dob.includes("-")) {
    const index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if(index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MM/DD/YYYY");
  }
  if(!dob.includes("-")) {
    const dobDate = FormatAWSDate(dob);
    return moment(dobDate).format("MM/DD/YYYY");
  }
  return moment(dob, "MM-DD-YYYY").format("MM/DD/YYYY");
};
export const FormatAWSDate = (dobFromID) => {
  if(dobFromID && !dobFromID?.includes("-")) {
    const awsdob = [];
    awsdob.push(dobFromID.substring(4));
    awsdob.push(dobFromID.substring(0, 2));
    awsdob.push(dobFromID.substring(2, 4));
    return awsdob.join("-");
  }
  return dobFromID;
};
export const formatPDFName = (demos) => {
  let name = "";
  if(demos.lastName)
    name = `${demos.lastName.substring(0, 1)}${demos.lastName
      .substring(1)
      .toLowerCase()}`;
  if(name) name = `${name}, `;
  if(demos.firstName)
    name = `${name}${demos.firstName.substring(0, 1)}${demos.firstName
      .substring(1)
      .toLowerCase()}`;
  return name;
};

export const formatLabelID = (id) => {
  if(!id) return "";
  const splitId = id.split("-");
  return splitId[splitId.length - 1];
};

export const formatCurrency = (val) => {
  if(!val) return "$0.00";
  const obj = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return obj.format(val);
};

export const formatNumber = (num) => {
  if(!num) return 0;
  return num.toLocaleString("en-US");
};

export const formatPercentage = (val) => {
  if(!val) return "0%";

  // Multiply the value by 100 to convert it to a percentage and round it to two decimal places.
  // const percentageValue = (val * 100).toFixed(2);
  const percentageValue =
    Math.abs(val) < 1 ? (val * 100).toFixed(2) : val.toFixed(2);
  const obj = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return obj.format(percentageValue / 100);
};

export const getDemoGraphics = (test) => {
  if(!test) return;
  const demos =
    typeof test.employee_demographics === "string"
      ? JSON.parse(test.employee_demographics)
      : test.employee_demographics;
  const ins =
    typeof demos.insuranceDetails === "string"
      ? JSON.parse(demos.insuranceDetails)
      : demos.insuranceDetails;
  const insDetails = parseInsDetails(ins, demos);
  return { ...demos, insuranceDetails: insDetails };
};

export const getAge = (dob) => {
  const val = formatDateOfBirthDOB(dob);
  return dob ? moment().diff(val, "years") : "";
};

export const parseInsDetails = (insuranceDetails, user) => {
  if(!insuranceDetails) return [];
  if(Array.isArray(insuranceDetails)) {
    return insuranceDetails;
  } else {
    const primary = {
      id: "prefix_" + Math.random().toString(36).slice(2, 9),
      insuranceCompany: user.insuranceCompanyCode,
      insuranceCompanyCode: user.insurance_name,
      insuranceType: user.insuranceType,
      copay: user.copay,
      deductible: user.deductible,
      insuranceGroupId: user.insuranceGroupId,
      isMedicare: user.isMedicare,
      medicalNo: user.insurance_number,
      medicareNo: user.medicalNo,
      isActive: true,
    };
    const insDetails = [primary];
    if(insuranceDetails.insuranceCompany) {
      insDetails.push({
        ...insuranceDetails,
        isActive: true,
        id: "prefix_" + Math.random().toString(36).slice(2, 9),
      });
    }
    return insDetails;
  }
};

export const formatPhone = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value?.replace(/[^0-9+]/g, "");
    if(!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    const phone = parsePhoneNumber(phone_number);
    return phone?.formatNational() || "";
  } catch(err) {
    console.log("Error", err);
  }
  return phone_number_value;
};
export const getCountryCodeAndPhone = (phone) => {
  const phoneNumber = parsePhoneNumberFromString(phone);
  if(!phoneNumber || !phoneNumber.isValid()) {
    // Handle invalid phone number
    console.error("Invalid phone number");
    return;
  }
  const countryCode = phoneNumber.countryCallingCode;
  const nationalNumber = phoneNumber.nationalNumber;

  console.log("countryCode : ", countryCode);
  console.log("nationalNumber : ", nationalNumber);
  return nationalNumber;
};

export const getPhoneNumber = (employee) => {
  if(employee.phone_number.includes("+")) {
    return employee.phone_number;
  }
  const countryCode = employee?.countryCode || "";
  let phNo = employee.phone_number.replace(`${countryCode}`, "");
  phNo = `${countryCode}${employee.phone_number}`;
  return phNo;
};
// export const parseTestResultForPDF = (test) => {
//   return test.result && RESULT_PDF[test.result.toLowerCase()];
// }


export const parseTestResultForPDF = (test) => {
  return test.result && RESULT_PDF[test.result.toLowerCase()];
};
export const isObjectEmpty = (obj) => {
  console.log("🚀 ~ isObjectEmpty ~ obj:", obj)
  return Object.values(obj).length === 0;
};

export const parseTestResult = (testResult) => {
  const result = customIsJsonString(testResult);

  if (result) {
    const parsed = JSON.parse(testResult);

    return parsed.label;
  }
  return testResult;
};

export const parseTestUnit = (units) => {
  if (Array.isArray(units) && units.length > 0) {
    return units[0].label;
  }
  return units?.label || "";
};

export const showTestResult = (item, isQualtitave) => {
  const result = parseTestResult(item.testResult);
  if (isQualtitave && result === "Detected") {
    return `${result} :: >=10K ${item?.unit || ""}`;
  }
  return result;
};



export const infectionResultBasedUponRange = ({ resultValue, ranges }) => {
  const numValue = Number(resultValue);

  if ((numValue >= Number(ranges.LowLow) && numValue <= Number(ranges.LowHigh)) || numValue < Number(ranges.LowLow)) {
    return "Low";
  } else if (numValue >= Number(ranges.NormalLow) && numValue <= Number(ranges.NormalHigh)) {
    return "Normal";
  } else if (numValue >= Number(ranges.HighLow) && numValue <= Number(ranges.HighHigh)) {
    return "High";
  } else if (numValue >= Number(ranges.PanicLow) && numValue <= Number(ranges.PanicHigh)) {
    return "Panic";
  } else if (
    (numValue >= Number(ranges.RepeatLow) && numValue <= Number(ranges.RepeatHigh)) ||
    numValue > Number(ranges.RepeatHigh)
  ) {
    return "Repeat";
  } else {
    return "Infection Confirmed";
  }
};

const expectedOrder = [
  "NormalLow",
  "NormalHigh",
  "HighLow",
  "HighHigh",
  "PanicLow",
  "PanicHigh",
  "RepeatLow",
  "RepeatHigh",
  "LowLow",
  "LowHigh",
  "DefaultHigh",
  "DefaultLow",
];

const LABEL_COLOR = {
  Low: "blue",
  Normal: "green",
  High: "red",
  Panic: "red",
  Repeat: "red",
  Indeterminate: "blue",
  Detected: "red",
  "Not Detected": "black",
  Invalid: "blue",
};

export const customPick = (obj, keys) => {
  // Ensure the object and keys are valid
  if (!obj || typeof obj !== 'object' || !Array.isArray(keys)) {
    return {};
  }

  return keys.reduce((acc, key) => {
    // Check if the key exists in the object
    if (key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};


const getOrderedRanges = (ranges) => {
  return customPick(ranges, expectedOrder);
};

export const getRangeName = (result, ranges) => {
  const orderedRanges = getOrderedRanges(ranges);
  const rangeKeys = Object.keys(orderedRanges);
  let lowValue;
  let highValue;

  for (let i = 0; i < rangeKeys.length; i += 2) {
    const lowKey = rangeKeys[i];

    const highKey = rangeKeys[i + 1];

    lowValue = parseFloat(orderedRanges[lowKey]);

    highValue = parseFloat(orderedRanges[highKey]);

    if (!isNaN(lowValue) && !isNaN(highValue) && result >= lowValue && result <= highValue) {
      return lowKey
        .replace(/([A-Z][a-z]+)/g, "$1 ")
        .trim()
        .split(" ")[0];
    }
  }

  if (parseFloat(result) < lowValue) return "Low";

  if (parseFloat(result) > highValue) return "High";

  return null;
};

export const customIsJsonString = (str) => {
  try {
    const parsed = JSON.parse(str);
    return typeof parsed === 'object' && parsed !== null;
  } catch (e) {
    return false;
  }
};


const getRange = (rangeObject, resultValue) => {
  const result = Number(resultValue);

  const orderedRanges = [
    { name: "Repeat", low: rangeObject.RepeatLow, high: rangeObject.RepeatHigh },
    { name: "Panic", low: rangeObject.PanicLow, high: rangeObject.PanicHigh },
    { name: "Normal", low: rangeObject.NormalLow, high: rangeObject.NormalHigh },
    { name: "Low", low: rangeObject.LowLow, high: rangeObject.LowHigh },
    { name: "High", low: rangeObject.HighLow, high: rangeObject.HighHigh },
  ];

  for (const range of orderedRanges) {
    const low = Number(range.low);
    const high = Number(range.high);

    if (result >= low && result <= high) {
      return ` = ${low} - ${high}`;
    }
  }

  const repeatHigh = Number(rangeObject.RepeatHigh);
  if (result > repeatHigh) {
    return `>= ${repeatHigh}`;
  }

  console.log("range not match");
  return "";
};

export const generateTagLineQualitativeWithPanleTests = ({ panel }) => {
  const { ranges, resultValue } = panel;
  const rangeToShow = getRange(ranges[0], resultValue);
  return rangeToShow;
};

export const analyzeResultValue = (range, result, refInterval, testRange) => {
  if (!result) return { label: "", color: "black", fontWeight: "500", orginalResult: result };

  const panelRanges = testRange && testRange.length > 0 ? testRange[0] : {};
  const defaultPanelValues = { ...panelRanges, DefaultLow: range.minValue, DefaultHigh: range.maxValue };

  const labelValue = getRangeName(result, defaultPanelValues);

  if (customIsJsonString(result)) {
    const parsedJSONString = JSON.parse(result);

    if (parsedJSONString.value !== refInterval) {
      return {
        label: "",
        color: LABEL_COLOR[parsedJSONString.value],
        fontWeight: "bold",
        orginalResult: parsedJSONString.label.toUpperCase(),
        resRange: "high",
      };
    }

    return {
      label: "",
      color: "black",
      fontWeight: "bold",
      orginalResult: parsedJSONString.label.toUpperCase(),
      resRange: "normal",
    };
  }
  console.log("labelValue", labelValue);

  console.log("result", result);

  return {
    label: labelValue,
    color: LABEL_COLOR[labelValue],
    fontWeight: "bold",
    orginalResult: result,
    resRange: LABEL_COLOR[labelValue] === "red" ? "high" : "low",
  };
  // const numericResult = parseFloat(result);

  // const minValue = range.minValue || range.minValue === 0 ? parseFloat(range.minValue) : null;
  // const maxValue = range.maxValue || range.maxValue === 0 ? parseFloat(range.maxValue) : null;

  // if (minValue !== null && !isNaN(minValue) && numericResult < minValue) {
  //   return { label: labelValue, color: "blue", fontWeight: "bold", orginalResult: result };
  // } else if (maxValue !== null && !isNaN(maxValue) && numericResult > maxValue) {
  //   return { label: labelValue, color: "red", fontWeight: "bold", orginalResult: result };
  // } else {
  //   return { label: labelValue, color: "green", fontWeight: "bold", orginalResult: result };
  // }
};


export const isResultInRange = (intervalString, result) => {
  let intervals = intervalString.split("-");

  if (intervals.length === 1) {
    const interval = parseTestResult(result);

    return interval === intervals[0] ? true : false;
  } else {
    let [start, end] = intervals.map(Number).filter(Boolean);

    if (parseFloat(result) >= parseFloat(start) && parseFloat(result) <= parseFloat(end)) {
      return true;
    }

    return false;
  }
};

export const showAlphaResult = (resultData, resRange) => {
  const { refInterval, testResult, units } = resultData;

  const isInRange = isResultInRange(refInterval, testResult);

  if (isInRange) {
    return "Not Detected";
  }

  return `DETECTED :: ${resRange === "high" ? ">=" : "<="} ${testResult} ${units.label}`;

  // let intervals = intervalString.split("-");

  // if (intervals.length === 1) {
  //   const interval = parseTestResult(result);

  //   return interval === intervals[0] ? true : false;
  // } else {
  //   let [start, end] = intervals.map(Number).filter(Boolean);

  //   if (parseFloat(result) >= parseFloat(start) && parseFloat(result) <= parseFloat(end)) {
  //     return true;
  //   }

  //   return false;
  // }
};

export const formatTestForLabReport = (array) => {
  console.log("🚀 ~ formatTestForLabReport ~ array:", array)
  if (!array || array.length === 0) return [];
  const result = [];
  let currentGroup = null;

  (JSON.parse(array) || [])?.forEach((item) => {
    if (item.testName) {
      if (currentGroup) {
        result.push(currentGroup);
      }
      currentGroup = { testName: item.testName, targetRange: [] };
    } else if (currentGroup) {
      currentGroup.targetRange.push(item);
    }
  });

  // Add the last group if exists
  if (currentGroup) {
    result.push(currentGroup);
  }

  return result;
};


export const formatTestNewDiscovery = ({ results, type,code }) => {
  if (type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN || type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN) {
    const { antibioticTests, pathogenisTests } = results.reduce(
      (acc, item) => {
        const parsedResult=parseTestResult(item.testResult)
        if (item?.antibioticResistanceType === "Resistance") {
          acc.antibioticTests.push({...item, parsedResult});
        } else {
          acc.pathogenisTests.push({...item, parsedResult});
        }
        return acc;
      },
      { antibioticTests: [], pathogenisTests: [] }
    );

    return {
      testName: results?.[0].panelName,
      code,
      pathogenisTests,
      antibioticTests,
    };
  }
  return null;
};
