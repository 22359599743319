import CustomButton from "components/Common/CustomButton/CustomButton";
import TopBar from "components/Common/TopBar/TopBar";
import { useNavigate } from "react-router-dom";

import styles from "style/signUp.module.css";

const SignupStatus = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    console.log("hello");
    navigate("/login");
  };

  return (
    <>
      <TopBar IconItemName="New Account Info" LoginBtn="Login" />
      <div className={styles.mainBodyBg}>
        <div className={styles.mainWrapper}>
          <div className={styles.createAccountWrapper}>
            <div className={styles.checkWrapper}>
              <div className={styles.checkContainer}>
                <div className={styles.checkBackground}>
                  <svg
                    viewBox="0 0 65 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 25L27.3077 44L58.5 7"
                      stroke="white"
                      stroke-width="13"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class={styles.checkShadow}></div>
              </div>
            </div>
            <h2 className="mt-2">Your account is created successfully.</h2>
            <p className="my-3">
              Unfortunately your record does not match with our database.
            </p>
            <CustomButton
              label="Back to Login"
              variant="primary"
              handleClick={handleLogin}
              additionalClass={`${styles.backToLogin}`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupStatus;
